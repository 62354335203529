import { Badge } from '@chakra-ui/react'
import React, { JSX } from 'react'
import { useCachedInsurer } from '../../../collections/hooks/cached'

export const CoverageInsurerBadge = ({ insurerId }: {insurerId: string | undefined}) => {
  const { data: insuranceProvider } = useCachedInsurer(
    insurerId || null,
  )

  let headerBadge: JSX.Element | null = null
  if (insuranceProvider) {
    headerBadge = (
      <Badge colorScheme="gray" fontSize="xs">
        {insuranceProvider?.name}
      </Badge>
    )
  } else if (insurerId) {
    headerBadge = (
      <Badge colorScheme="red" fontSize="xs">
        Loading insurer
      </Badge>
    )
  } else {
    headerBadge = (
      <Badge colorScheme="gray" color='gray.500' fontSize="xs">
        None
      </Badge>
    )
  }

  return headerBadge
}
