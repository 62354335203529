import { AssessmentStepKey, FormsViewData } from '@hb/shared'
import {
  useAssessmentForm, useAssessmentFromId, useMyAssessments, usePopulatedUser,
} from '@hb/shared-frontend'
import { useAuth } from '@hb/shared-frontend/store/auth'
import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import { useParams } from 'react-router'

export const useFormsView = (): FormsViewData => {
  const user = useAuth((s) => s.user)

  const { assessments, loading: assessmentsLoading } = useMyAssessments()
  const [selected, setSelected] = useState<{formId: string, stageId: string} | null>(null)

  const { assessmentId: _assessmentId } = useParams() as {
    assessmentId?: string
  }
  const index = useMemo(() => {
    const keys = Object.keys(assessments || {})
    if (_assessmentId) {
      const idx = keys.findIndex((id) => _assessmentId === id)
      return idx
    }
    return 0
  }, [assessments, _assessmentId])

  const assessmentId = useMemo(
    () => _assessmentId || (assessments && Object.keys(assessments)[0]) || undefined,
    [assessments, _assessmentId],
  )

  const populatedUser = usePopulatedUser(user?.id)
  // const { data: assessment } = useDocument<Assessment>('assessments', assessmentId)
  const assessment = useAssessmentFromId(false, assessmentId)
  const [step, selectStep] = useState<AssessmentStepKey>('questions')

  const { form } = useAssessmentForm(assessment?.data, selected?.formId)

  const mountedAssessment = useRef<number | string | null>(assessment?.createdOn || null)
  useEffect(() => {
    if (mountedAssessment.current === assessment?.createdOn) return
    if (assessment?.resultsViewedOn) selectStep('signOn')
    else if (assessment?.results) selectStep('view')
    else {
      selectStep('questions')
    }
    mountedAssessment.current = assessment?.createdOn || null
  }, [assessment])

  return {
    step,
    assessmentId,
    assessment,
    assessments,
    selectStep,
    selected,
    populatedUser,
    assessmentIndex: index,
    open: (formId, stageId) => setSelected({ formId, stageId }),
    close: () => setSelected(null),
    assessmentsLoading,
    form,
  }
}
