import { CPTCode, CPTCodeGroup } from '../../../types/providers'
import {
  getBcbsIcd10, o80, o82, z33_1, z34_80, z39_1, z39_2, z3a_40,
} from '../icdCodes'

const globalMaternityCare: CPTCode = {
  description: 'Global Maternity Care',
  code: '59400',
  icd10: [
    z33_1,
    z34_80,
    o80,
    z39_2,
    z39_1,
    z3a_40,
  ],
}

const vaginalDeliveryPPCare: CPTCode = {
  description: 'Vaginal Delivery + PP Care',
  code: '59410',
  icd10: [
    o80,
    z39_2,
    z39_1,
    getBcbsIcd10(z3a_40),
  ],
}

const vaginalDelivery: CPTCode = {
  description: 'Vaginal Delivery',
  code: '59409',
  icd10: [
    o80,
    getBcbsIcd10(z3a_40),
  ],
}

const ceseareanGlobalMaternityCare: CPTCode = {
  description: 'Cesarean Global Maternity Care',
  code: '59510',
  icd10: [
    z34_80,
    o82,
    z39_2,
    z39_1,
    getBcbsIcd10(z3a_40),
  ],
}

const ceseareanDelivery: CPTCode = {

  description: 'Cesarean Delivery',
  code: '59514',
  icd10: [
    o82,
    getBcbsIcd10(z3a_40),
  ],
}

export const deliveryCodes: CPTCodeGroup = {
  name: 'Delivery',
  codes: [
    globalMaternityCare,
    vaginalDeliveryPPCare,
    vaginalDelivery,
    ceseareanGlobalMaternityCare,
    ceseareanDelivery,
  ],
}
