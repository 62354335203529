import { EditIcon } from '@chakra-ui/icons'
import { BoxProps, Flex, Text } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import { ContactButtons, SolidActionButton } from '@hb/shared-frontend'
import React from 'react'

export const PostSignOnText = ({
  onEditSignOn,
  ...props
}: BoxProps & { onEditSignOn: () => void}) => (
  <Flex flexFlow="column" p={2} {...props}>
    <Text mb={2} fontFamily="Comfortaa" color={colors.green.hex} fontWeight={400} fontSize="18px">
      Thank you!
    </Text>
    <Text mb={2}>
      Thank you for confirming you would like to sign on! If you haven't already, please reach out
      to the midwife in order to schedule an appointment.
    </Text>
    <Text>
      As always, please don't hesitate to schedule a call with us or reach out via email if you have
      any questions about your assessment.
    </Text>
    <Text mt={2}>
      As a friendly reminder, the initial retainer is due by your first visit. Accepted payment
      methods are listed at the bottom of your assessment.
    </Text>
    <Flex mt={2} gap={2} w="100%" flexFlow={['column', 'row']}>
      <SolidActionButton onClick={onEditSignOn} flex={1} size="sm">
        <EditIcon filter="drop-shadow(1px 1px 3px #00000099)" mr={2} />
        <Text>Sign On Info</Text>
      </SolidActionButton>
    </Flex>
    <ContactButtons pt={2} />
  </Flex>
)
