/* eslint-disable no-underscore-dangle */
import {
  Center, CircularProgress, Flex, Text,
} from '@chakra-ui/react'
import {
  DataTableProps,
  DocData,
  getReverseName,
  isListDividerItem,
  ListItem,
  UserCellComponentProps,
  WithId,
} from '@hb/shared'
import React, {
  forwardRef, useCallback, useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { GridChildComponentProps, VariableSizeGrid } from 'react-window'
import { useApp } from '../../contexts/AppContext'
import { DataGridContext } from '../../contexts/UserGridContext'
import { usePopulatedItem } from '../../hooks/backend/usePopulatedItem'
import { useAuth } from '../../store'
import { getRowBackground } from '../DataView/utils'
import { usePreview } from './hooks'
import { DesktopRowHeaders } from './RowHeaders'
import { getFlexColumnWidth, getItemsWithDividers } from './utils'

const UserCell = <Snippet extends WithId, SortKey extends string>({
  columnIndex,
  rowIndex,
  style,
  isScrolling,
  data,
}: GridChildComponentProps<UserCellComponentProps<Snippet, SortKey>>) => {
  const {
    items, columns, preview, auth, app, tabName, tab, onRowClick, listWidth,
  } = data
  const {
    Render: render,
    flexProps,
    width,
  } = columns[Object.keys(columns)[columnIndex]]
  const item = items[rowIndex]
  if (isListDividerItem(item)) {
    if (columnIndex === 0) {
      return (
        <>
          <div style={{ ...style, width: `${listWidth}px` }}>
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <Center
                bg="gray.100"
                position="absolute"
                borderTop='1px solid #cdcdcd'
                borderBottom='1px solid #cdcdcd'
                left={0}
                height='100%'
                width='100%'
                key={`${columnIndex}_${rowIndex}`}
              >
                <Text fontWeight={600} color="#8b8b8b">{item.label}</Text>
              </Center>
            </div>
          </div>
        </>
      )
    }
    return null
  }
  const background = tab.getItemBackgroundColor
    ? tab.getItemBackgroundColor(item, rowIndex)
    : getRowBackground(rowIndex)
  return (
    <Flex
      bg={background}
      px={1}
      flex={width ? undefined : 1}
      overflow="hidden"
      width={`${width || 0}px`}
      fontSize="sm"
      align="center"
      onClick={onRowClick ? () => onRowClick(item.id) : undefined}
      key={`${columnIndex}_${rowIndex}`}
      {...flexProps}
      style={{ ...flexProps?.style, ...style }}
      _hover={
        onRowClick
          ? {
            cursor: 'pointer',
            bg: 'gray.100',
            ...flexProps?._hover,
          }
          : flexProps?._hover
      }
    >
      {render({
        data: item,
        preview,
        tabName,
        auth,
        app,
        isScrolling,
        cell: { columnIndex, rowIndex },
      })}
    </Flex>
  )
}

export const outerElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const { closePreview } = useContext(DataGridContext)
  return <div onWheel={closePreview} ref={ref} {...props} />
})

export const DesktopDataList = forwardRef<
  VariableSizeGrid<UserCellComponentProps<WithId<DocData>, string>>,
  DataTableProps<WithId<any>, string>
>(
  (
    {
      items,
      itemHeight = 36,
      columns,
      onRowClick,
      collection,
      tabName,
      tab,
      width,
      sortKey,
      loading,
      sortAsc,
      height,
    },
    ref,
  ) => {
    const [displayedMessage, setDisplayedMessage] = useState('')
    const [scrollTop, setScrollTop] = useState(0)
    const previewState = usePreview()
    const { preview } = previewState
    const { arr, map } = useMemo(() => {
      const values = Object.entries(items || {}).map(([key, val]) => ({
        ...val,
        id: key,
      }))
      return {
        arr: values as Array<WithId<ListItem>>,
        map: values.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {} as Record<string, WithId<ListItem>>,
        ),
      }
    }, [items])
    const previewItem = useMemo(
      () => (preview?.id ? map[preview.id] : null),
      [preview, map],
    )
    const populated = usePopulatedItem(previewItem, collection)
    const reverseName = useMemo(() => getReverseName(populated), [populated])
    const auth = useAuth()
    const app = useApp()

    const flexColWidth = useMemo(
      () => getFlexColumnWidth({ width, columns }),
      [columns, width],
    )

    const [remounting, setRemounting] = useState(false)
    useEffect(() => {
      setRemounting(true)
      const timeout = setTimeout(() => {
        setTimeout(() => setRemounting(false), 100)
      }, 500)
      return () => clearTimeout(timeout)
    }, [flexColWidth])

    const itemsWithDividers = useMemo(
      () => getItemsWithDividers(arr, columns, sortKey, sortAsc ? 'asc' : 'desc'),
      [arr, columns, sortKey, sortAsc],
    )

    const itemData = useMemo<UserCellComponentProps>(
      () => ({
        columns,
        auth,
        items: itemsWithDividers,
        app,
        tabName,
        listHeight: height,
        listWidth: width,
        onRowClick,
        tab,
        flexColWidth,
        preview: previewState,
      }),
      [
        app,
        auth,
        columns,
        flexColWidth,
        height,
        width,
        previewState,
        tab,
        tabName,
        onRowClick,
        itemsWithDividers,
      ],
    )

    // const keys = Object.keys(columns)
    // const numCols = keys.length
    const { keys, numCols } = useMemo(() => {
      const ks = Object.keys(columns)
      return { keys: ks, numCols: ks.length }
    }, [columns])

    const rowHeight = useCallback((idx: number) => {
      if (isListDividerItem(itemsWithDividers[idx])) return 28
      return itemHeight
    }, [itemsWithDividers, itemHeight])

    return (
      <DataGridContext.Provider
        value={{
          data: map || null,
          columns,
          item: populated,
          collection,
          display: setDisplayedMessage,
          width,
          scrollTop,
          height,
          ...previewState,
          itemHeight,
          // item: populated,
          reverseName,
          clearMessage: () => setDisplayedMessage(''),
        }}
      >
        <Flex pos="relative" direction="column">
          <Flex justify="center" pos="relative">
            <DesktopRowHeaders
              keys={keys}
              columns={columns}
              flexColWidth={flexColWidth}
            />
            <Flex
              opacity={displayedMessage ? 1 : 0}
              pos="absolute"
              right="10px"
              shadow="md"
              p={2}
              zIndex={3}
              bg="white"
              borderRadius="md"
            >
              {displayedMessage}
            </Flex>
          </Flex>
          {remounting || loading ? (
            <Center width={`${width}px`} height={`${height}px`}>
              <CircularProgress isIndeterminate color="#777" size={10} />
            </Center>
          ) : (
            <VariableSizeGrid
              ref={ref}
              outerElementType={outerElementType}
              itemKey={(e) => `${e.rowIndex}_${e.columnIndex}`}
              style={{
                background: 'white',
                // overflow: 'overlay',
                transition: 'height 500ms',
                overflowX: 'hidden',
              }}
              onScroll={(e) => {
                previewState.closePreview()
                setScrollTop(e.scrollTop)
              }}
              itemData={itemData}
              columnCount={numCols}
              useIsScrolling
              rowCount={itemsWithDividers.length}
              rowHeight={rowHeight}
              width={width}
              height={height}
              columnWidth={(index: number) => columns[keys[index]].width || flexColWidth
              }
            >
              {UserCell}
            </VariableSizeGrid>
          )}
        </Flex>
      </DataGridContext.Provider>
    )
  },
)
