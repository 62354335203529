import { Flex, Text } from '@chakra-ui/react'
import { getHasMedicaidCoverage } from '@hb/shared/fields'
import {
  getCurrentlyOnMedicaidPlan,
  getOnlyOnMedicaidPlan,
} from '@hb/shared/utils'
import React, { useContext, useMemo } from 'react'
import { UserContext } from '../../contexts'
import { AdditionalPlansView } from './AdditionalPlansView'
import { CoverageStage } from './Coverage'

export const InsurancePlansView = () => {
  const { selectedAssessment, assessmentId } = useContext(UserContext)
  const { mergedData } = selectedAssessment || {}
  const primaryCoverageData = useMemo(
    () => mergedData?.['insurance-info']?.primaryCoverage,
    [mergedData],
  )

  const medicaidCoverageData = useMemo(
    () => mergedData?.['insurance-info']?.medicaidCoverage,
    [mergedData],
  )

  const hasPrimaryCoverage = useMemo(
    () => !getOnlyOnMedicaidPlan(mergedData),
    [mergedData],
  )

  const hasMedicaidCoverage = useMemo(
    () => getHasMedicaidCoverage(mergedData?.['insurance-info']),
    [mergedData],
  )

  const isCurrentlyOnMedicaid = useMemo(
    () => getCurrentlyOnMedicaidPlan(mergedData),
    [mergedData],
  )

  if (!selectedAssessment || !assessmentId) {
    return (
      <Flex flexFlow="column" p={3}>
        <Text>Select an assessment to see plans</Text>
      </Flex>
    )
  }

  return (
    <Flex
      bg="gray.50"
      boxShadow="1px 1px 4px #00000055"
      borderRadius={6}
      py={2}
      flexFlow="column"
    >
      <Flex gap={2} flexFlow="column" px={3} py={2}>
        {hasPrimaryCoverage ? (
          <CoverageStage
            type="primaryCoverage"
            assessmentId={assessmentId}
            propPath="corrections.insurance-info.primaryCoverage"
            coverage={primaryCoverageData}
            optional={isCurrentlyOnMedicaid}
          />
        ) : null}
        {hasMedicaidCoverage ? (
          <CoverageStage
            type="medicaidCoverage"
            assessmentId={assessmentId}
            optional={!hasMedicaidCoverage}
            propPath="corrections.insurance-info.medicaidCoverage"
            coverage={medicaidCoverageData}
          />
        ) : null}
      </Flex>
      {assessmentId ? (
        <AdditionalPlansView
          adminView
          assessmentId={assessmentId}
          assessment={selectedAssessment}
        />
      ) : null}
    </Flex>
  )
}
