import {
  defaultStageValidate,
  FieldMap,
  InfoStage,
  isField,
  isInfoStage,
  StageStatus,
} from '@hb/shared'
import {
  FieldMapValue,
  Form,
  OnUploadProgress,
} from '@hb/shared/types'
import { FormApi, ValidationErrors } from 'final-form'
import {
  FC,
  useCallback, useMemo, useState,
} from 'react'

import { get as nestedGet, set as nestedSet } from 'nested-property'
import { processFieldMapData } from '../../../backend/db'
import { FormWizardContextData } from './context'

export const useFormWizardData = (
  form: Form,
  data: FieldMapValue | undefined,
  onSubmit: (submitted: FieldMapValue) => Promise<ValidationErrors>,
  stageIdProp?: string | null,
  selectStage?: (selected: string | null) => void,
  readOnly?: boolean,
  ReadOnlyFooter?: FC,
  baseStoragePath?: string,
): FormWizardContextData => {
  const [_stageId, setStageId] = useState<string | null>(null)
  const stageId = useMemo(
    () => (stageIdProp !== undefined ? stageIdProp : _stageId),
    [_stageId, stageIdProp],
  )
  const { nextStage } = useMemo(() => {
    const keys = Object.keys(form?.stages || {})
    let next: string | null = null
    if (stageId) {
      const currIdx = keys.indexOf(stageId)
      next = keys[currIdx + 1] || null
    }
    return { stageKeys: keys, nextStage: next }
  }, [stageId, form])

  const goToStage = useCallback(
    (goToId: string | null) => {
      if (selectStage) selectStage(goToId)
      else setStageId(goToId)
    },
    [selectStage],
  )

  const stage = useMemo(
    () => (form && stageId ? form.stages[stageId] : null),
    [stageId, form],
  )

  const onSubmitStage = useCallback(
    async (
      submitted: FieldMapValue | undefined,
      onUploadProgress: OnUploadProgress,
      formApi?: FormApi,
    ): Promise<ValidationErrors> => {
      if (!stage || isInfoStage(stage)) {
        goToStage(nextStage)
        return { success: 'Info stage' }
      }

      if (!stageId) {
        return { error: 'No selected' }
      }
      if (!submitted) {
        goToStage(nextStage)
        return { error: 'No data' }
      }
      const path = `${form && form.path ? `${form.path}.` : ''}${stageId}`
      const processed = await processFieldMapData(
        `${baseStoragePath}/${stage.storagePath}`,
        stage,
        submitted,
        data,
        onUploadProgress,
      )
      const updated = {}
      nestedSet(updated, path, processed)
      return onSubmit(updated)
        .then(() => {
          if (formApi) formApi.restart()
          goToStage(nextStage)
          return { success: 'Updated successfully!' }
        })
        .catch((err: any) => {
          console.error(err)
          return { error: err.message }
        })
    },
    [form, nextStage, stageId, stage, goToStage, onSubmit, data, baseStoragePath],
  )
  return useMemo(() => ({
    nextStage,
    form,
    open: (openedId: string) => goToStage(openedId),
    close: () => goToStage(null),
    stage,
    stageId,
    onSubmitStage,
    data,
    readOnly,
    ReadOnlyFooter,
  }), [nextStage, form, goToStage, stage, stageId, onSubmitStage, data, readOnly, ReadOnlyFooter])
}

export const getStageStatus = (
  stage: InfoStage | FieldMap | null | undefined,
  value?: FieldMapValue,
  es?: ValidationErrors,
): StageStatus => {
  if (stage && isInfoStage(stage)) return 'info'
  const validate = stage?.validate || defaultStageValidate(stage, true)
  let errors
  if (!es) errors = validate(value)
  else errors = es
  if (!stage || !value) return 'incomplete'
  if (errors) {
    const numErrors = Object.keys(errors).length
    const numRequired = Object.keys(stage.children).filter((childName) => {
      const child = stage.children[childName]
      if (isInfoStage(child)) return false
      if (isField(child)) {
        return !child.optional
      }
      return true
    }).length
    if (numErrors) {
      if (numErrors === numRequired) {
        return 'incomplete'
      }
      return 'in progress'
    }
  }
  return 'complete'
}

export function useStageState(
  form: Form,
  stage: FieldMap | InfoStage | undefined | null,
  value?: FieldMapValue,
  stagePath?: string | null,
) {
  const stageValue = useMemo(
    () => (stagePath || form.path
      ? nestedGet(value, `${form.path ? `${form.path}.` : ''}${stagePath || ''}`)
      : value),
    [value, stagePath, form],
  )

  const errors = useMemo(() => {
    if (!stage) return undefined
    if (isInfoStage(stage)) return undefined
    const validate = stage.validate || defaultStageValidate(stage, true)
    return validate(stageValue)
  }, [stageValue, stage])

  const status = useMemo<StageStatus>(
    () => getStageStatus(stage, stageValue, errors),
    [stage, errors, stageValue],
  )

  return useMemo(
    () => ({
      value: stageValue,
      errors,
      status,
    }),
    [stageValue, errors, status],
  )
}
