import { Assessment, WithId } from '@hb/shared/types'
import { objectToArray } from '@hb/shared/utils'
import React, {
  createContext, PropsWithChildren, useContext, useMemo,
} from 'react'
import { useUserAssessments } from '../hooks/backend/user/useUserAssessments'
import { useAuth } from '../store/auth'

type UserAssessmentContextData = {
  assessments: Record<string, Assessment>
  assessmentsArr: WithId<Assessment>[]
  loading: boolean
  error: string | null
}
export const UserAssessmentsContext = createContext<UserAssessmentContextData>({
  assessments: {},
  assessmentsArr: [],
  loading: false,
  error: null,
})

export const UserAssessmentsProvider = ({ children }: PropsWithChildren) => {
  const authUser = useAuth((s) => s.authUser)
  const { data: assessments, error, loading } = useUserAssessments(authUser?.uid)
  const contextData = useMemo(() => ({
    assessments: assessments || {},
    assessmentsArr: objectToArray(assessments || {}),
    loading,
    error,
  }), [assessments, loading, error])
  return (
    <UserAssessmentsContext.Provider value={contextData}>
      {children}
    </UserAssessmentsContext.Provider>
  )
}

export const useMyAssessments = () => useContext(UserAssessmentsContext)
