import { EditIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import { FieldTypes } from '@hb/shared/constants'
import { notesField } from '@hb/shared/fields'
import {
  CheckboxField,
  FieldMap,
  FieldMapValue,
  InsuranceCoverage,
  TextAreaField,
} from '@hb/shared/types'
import { getCoverageRequiresCallIn, getUniqueRandomId } from '@hb/shared/utils'
import { FORM_ERROR } from 'final-form'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { requestCallIn } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { useAuth } from '../../../store'
import { ActionButton, SolidActionButton } from '../../Buttons'
import { SimpleForm } from '../../forms'
import { DefaultModal } from '../../Modals/DefaultModal'

const requiredNotesField: TextAreaField = {
  ...notesField,
  optional: false,
  placeholder: 'Message to patient',
}

const sendEmailField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Send email to patient',
}

const field: FieldMap = {
  name: 'Request Call In',
  children: {
    notes: requiredNotesField,
    sendEmail: sendEmailField,
  },
}

export const RequestCallIn = ({
  assessmentId,
  coverage,
}: {
  coverage: InsuranceCoverage | undefined
  assessmentId: string
}) => {
  const { callInRequests } = coverage || {}
  const authUser = useAuth((s) => s.authUser)
  const isAdmin = useAuth((s) => s.admin)
  const { showMessage } = useContext(PopUpMessageContext)
  const [isOpen, setIsOpen] = useState(false)
  const callInComplete = useMemo(() => getCoverageRequiresCallIn('additionalPlans', coverage), [coverage])

  const existingId = useMemo(
    () => Object.keys(callInRequests || {})[0],
    [callInRequests],
  )

  const existingRequest = useMemo(
    () => callInRequests?.[existingId] || null,
    [existingId, callInRequests],
  )

  const initData = useMemo(
    () => ({
      message: existingRequest?.message || '',
      sendEmail: !callInComplete,
    }),
    [existingRequest, callInComplete],
  )

  const handleRequestCallIn = useCallback(
    async (data: FieldMapValue) => {
      if (!authUser) {
        showMessage({ text: 'Not signed in', type: 'error' })
        return { [FORM_ERROR]: 'Not signed in' }
      }
      if (!isAdmin) {
        showMessage({ text: 'Only admins can request call in', type: 'error' })
        return { [FORM_ERROR]: 'Only admins can request call in' }
      }
      try {
        const { data: { success } } = await requestCallIn({
          assessmentId,
          coverageId: existingId || getUniqueRandomId([]),
          message: data.message || '',
          sendEmail: data.sendEmail || false,
        })

        showMessage({
          type: 'success',
          text: 'Call in requested',
          subText: success,
        })
      } catch (err: any) {
        showMessage({
          text: 'Error requesting call in',
          subText: err?.message,
          type: 'error',
        })
        return { [FORM_ERROR]: 'Error requesting call in' }
      }
      setIsOpen(false)
      return undefined
    },
    [assessmentId, authUser, isAdmin, showMessage, existingId],
  )
  return isAdmin ? (
    <>
      <Flex w="100%" gap={2} py={2} px={3} align="center">
        {existingRequest ? (
          <Text color="gray.600">{existingRequest?.message || 'Call-in requested'}</Text>
        ) : (
          <Text fontStyle="italic" color="gray.600">
            No call-in request yet
          </Text>
        )}
        {existingId ? (
          <SolidActionButton
            fontWeight={500}
            borderRadius="full"
            ml="auto"
            size="xs"
            gap={1}
            fontFamily="Hero-New"
            onClick={() => setIsOpen(true)}
          >
            <EditIcon filter="drop-shadow(1px 1px 3px #00000066)" />
            <Text>EDIT CALL-IN REQUEST</Text>
          </SolidActionButton>
        ) : (
          <ActionButton
            fontWeight={500}
            borderRadius="full"
            ml="auto"
            size="xs"
            fontFamily="Hero-New"
            onClick={() => setIsOpen(true)}
          >
            REQUEST CALL-IN
          </ActionButton>
        )}
      </Flex>

      <DefaultModal
        overlayHeader
        isOpen={isOpen}
        contentProps={{ bg: 'gray.100' }}
        onClose={() => setIsOpen(false)}
        render={() => (
          <Flex flexFlow="column" pt={3} px={4}>
            <Text fontSize="lg" fontWeight={600} color="gray.600">
              {existingId ? 'Edit Call-In Request' : 'Request Call-In'}
            </Text>
            <SimpleForm
              canSubmitClean
              submitText="Send Request"
              boxProps={{ boxShadow: 'none', bg: 'transparent', p: 0 }}
              field={field}
              // @ts-ignore
              value={initData}
              onSubmit={handleRequestCallIn}
            />
          </Flex>
        )}
      />
    </>
  ) : null
}
