import {
  Box,
  Button,
  Center,
  CircularProgress,
  Collapse,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import {
  ASSESSMENTS,
  CheckboxField,
  colors,
  FieldMap,
  FieldTypes,
  FileDBValue,
  getPatientFileField,
  getPatientFilesField,
  OnUploadProgress,
  TextField,
  ThreadType,
  UploadProgress,
} from '@hb/shared'
import { FORM_ERROR } from 'final-form'
import { writeBatch } from 'firebase/firestore'

import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import {
  combineFilesIntoPdf,
  db,
  processFileFieldData,
} from '../../../../backend'
import {
  PopUpMessageContext, ScreenContext, useApp, UserContext,
} from '../../../../contexts'
import uploadIcon from '../../../../icons/upload.svg'
import { addMetadata } from '../../../../utils'
import { SimpleForm } from '../../../forms'
import { sendThreadMessage } from '../../../Messaging/utils'

const combineIntoPdfCondition = (v: any) => ((v?.files || [])?.length || 0) > 1

const combineIntoPdfField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Combine into one PDF',
  condition: combineIntoPdfCondition,
}

const fileNameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'PDF file name',
  condition: (v) => combineIntoPdfCondition(v) && !!v?.combineIntoPdf,
}

export const UploadFilePopover = () => {
  const { processResponse } = useContext(PopUpMessageContext)
  const { assessmentId, selectedAssessment } = useContext(UserContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { appName } = useApp()

  const { isMobile } = useContext(ScreenContext)
  const [mergingFiles, setMergingFiles] = useState(false)

  const { practiceRef } = selectedAssessment || {}

  const newFileId = useMemo(() => (isOpen ? Date.now() : null), [isOpen])
  const newFileField = useMemo(
    () => (newFileId && assessmentId
      ? getPatientFileField(`${newFileId}`)
      : null),
    [newFileId, assessmentId],
  )

  const newFilesField = useMemo(
    () => (newFileId && assessmentId
      ? getPatientFilesField(`${newFileId}`)
      : null),
    [assessmentId, newFileId],
  )

  const handleSubmit = useCallback(
    async (
      {
        files: data,
        fileName,
        combineIntoPdf,
        sendMessageToHb,
        messageToHb,
      }: { files: any[]; combineIntoPdf: boolean; fileName: string, sendMessageToHb: boolean, messageToHb: string },
      onUploadProgress: OnUploadProgress,
    ) => {
      if (!newFileId || !newFileField || !practiceRef || !assessmentId) return processResponse({ error: 'internal error' })
      const willCombine = combineIntoPdf && data.length > 1
      const fileIds = data.map((_, i) => `${newFileId + i}`)
      const uploads: Record<string, UploadProgress> = {}
      try {
        const processed = (await Promise.all(
          data.map((d, i) => processFileFieldData(
            `${ASSESSMENTS}/${assessmentId}/practice-files/${fileIds[i]}`,
            [],
            newFileField,
            d,
            undefined,
            (p) => {
              uploads[fileIds[i]] = p
              onUploadProgress(uploads)
            },
          )),
        )) as FileDBValue[]
        if (!processed) return processResponse({ error: 'Error uploading files' })
        const batch = writeBatch(db)
        processed.forEach((p, i) => batch.update(
          practiceRef,
          `files.${fileIds[i]}`,
          addMetadata(
            willCombine ? { ...p, combiningIntoPdf: true } : p,
            appName,
            true,
          ),
        ))
        await batch.commit()
        if (sendMessageToHb) {
          await sendThreadMessage(appName, ThreadType.ASSESSMENT, assessmentId, messageToHb || 'Files uploaded', null)
        }
        onUploadProgress({})
        if (willCombine) {
          setMergingFiles(true)
          await combineFilesIntoPdf({
            fileIds: fileIds.map((id) => `practiceFiles.${id}`),
            appName,
            fileName,
            assessmentId,
          })
          setMergingFiles(false)
        }
        onClose()
        return processResponse({ success: 'Files uploaded' })
      } catch (err: any) {
        console.error(err)
        return processResponse({
          error: err?.message || 'Error uploading file',
        })
      }
    },
    [
      practiceRef,
      onClose,
      processResponse,
      newFileId,
      appName,
      assessmentId,
      newFileField,
    ],
  )

  const field = useMemo<FieldMap | null>(
    () => (newFilesField
      ? {
        name: '',
        children: {
          files: newFilesField,
          combineIntoPdf: combineIntoPdfField,
          fileName: fileNameField,
          sendMessageToHb: {
            type: FieldTypes.CHECKBOX,
            placeholder: 'Send message to HB',
            condition: (v) => !!v?.files?.length,
          },
          messageToHb: {
            type: FieldTypes.TEXTAREA,
            placeholder: 'Message to HB',
            condition: (v) => !!v?.sendMessageToHb,
            optional: true,
          },
        },
      }
      : null),
    [newFilesField],
  )
  // const inputRef = useRef<InputRef>(null)
  const initValue = useMemo(
    () => ({
      files: [],
      combineIntoPdf: true,
      fileName: '',
    }),
    [],
  )

  return (
    <Popover
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
      isLazy
      onOpen={onOpen}
      strategy="fixed"
    >
      <PopoverTrigger>
        <Box w="100%">
          <Tooltip hasArrow placement="bottom" label="Upload New File">
            <Button
              size={isMobile ? 'xs' : 'sm'}
              fontWeight={300}
              fontFamily="hero-new"
              bg={colors.green.hex}
              color="gray.800"
              _hover={{ bg: 'green.300' }}
            >
              <HStack spacing={0}>
                <Image
                  width="18px"
                  src={uploadIcon}
                  filter="grayscale(100%) brightness(200%)"
                />
                <Text
                  height="14px"
                  fontFamily="Comfortaa"
                  px={1}
                  fontSize={isMobile ? 'xs' : 'sm'}
                  fontWeight={600}
                  color="gray.50"
                >
                  FILE
                </Text>
              </HStack>
            </Button>
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        bg="gray.50"
        borderRadius={6}
        display="flex"
        flexFlow="column"
        w="450px"
        h="500px"
        maxW="100vw"
      >
        <PopoverCloseButton top={2} borderRadius="full" bg='blackAlpha.300' color="white" />
        <PopoverHeader
          bg={colors.green.hex}
          borderTopRadius={6}
          fontFamily="Comfortaa"
          fontWeight={600}
          color="white"
          textShadow="1px 1px 3px #00000066"
        >
          Upload Files
        </PopoverHeader>
        <Flex w="100%" flex={1} minH="0" maxH="80vh" overflowY="auto">
          <Box w="100%">
            {field && newFileId ? (
              <SimpleForm
                // ref={inputRef}
                field={field}
                value={initValue}
                boxProps={{
                  bg: 'transparent',
                  py: 0,
                  px: 2,
                  boxShadow: 'none',
                }}
                fieldStyle={{
                  background: 'transparent',
                }}
                onSubmit={(val, onUploadProgress) => handleSubmit(val, onUploadProgress)
                  .then((res) => (res.error ? { [FORM_ERROR]: res.error } : undefined))
                  .catch((err) => processResponse({
                    error: err?.message || 'An error occurred',
                  }))
                }
              />
            ) : null}
            <Collapse in={mergingFiles} style={{ width: '100%' }}>
              <Center pt={1} pb={4} gap={2}>
                <CircularProgress size={6} isIndeterminate color="green.300" />
                <Text
                  fontFamily="Comfortaa"
                  fontSize="sm"
                  color="gray.600"
                  fontWeight={600}
                >
                  Merging Files...
                </Text>
              </Center>
            </Collapse>
          </Box>
        </Flex>
        <PopoverArrow bg={colors.green.hex} />
      </PopoverContent>
    </Popover>
  )
}
