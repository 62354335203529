import { medicaidProvidersCollection, providersCollection } from '../../collections'
import { FieldTypes } from '../../constants'
import { IdField } from '../../types'

export const insuranceProviderField: IdField = {
  type: FieldTypes.ID,
  collection: providersCollection,
  placeholder: 'Insurance Provider',
  allowOther: true,
}

export const medicaidInsuranceProviderField: IdField = {
  type: FieldTypes.ID,
  collection: medicaidProvidersCollection,
  placeholder: 'Medicaid Provider',
  allowOther: true,
}
