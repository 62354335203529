import {
  Button, Collapse, Flex, Text,
} from '@chakra-ui/react'
import { ASSESSMENTS } from '@hb/shared/collections/names'
import {
  coverageRequestField,
  getInsuranceCoverageField,
} from '@hb/shared/fields'
import {
  FieldMapValue,
  OnUploadProgress,
  UpdateCallback,
} from '@hb/shared/types'
import { getUniqueRandomId } from '@hb/shared/utils'
import { doc, updateDoc } from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { db, processFieldMapData } from '../../../backend/db'
import { requestCoverage } from '../../../backend/functions'
import { useApp } from '../../../contexts'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { UserContext } from '../../../contexts/UserContext'
import { addMetadata } from '../../../utils'
import { SimpleForm } from '../../forms/FinalForm/SimpleForm'
import { DefaultModal } from '../../Modals/DefaultModal'

const newPlanTypeLabels = {
  medicaid: 'Medicaid',
  'non-medicaid': 'Non-Medicaid',
  request: 'Request from Patient',
}
const NewPlanTypeButton = ({
  type,
  selected,
  onClick,
}: {
  type: 'medicaid' | 'non-medicaid' | 'request'
  selected: boolean
  onClick: () => void
}) => (
  <Button
    onClick={onClick}
    size="sm"
    flex={1}
    boxShadow="1px 1px 3px #00000066"
    bg={selected ? 'green.500' : 'white'}
    border={`1px solid ${selected ? 'green.500' : 'gray.300'}`}
    color={selected ? 'white' : 'gray.600'}
    textShadow={selected ? '1px 1px 3px #00000066' : 'none'}
    variant="unstyled"
  >
    {newPlanTypeLabels[type]}
  </Button>
)

const NewPlanTypeSelect = ({
  type,
  onChange,
}: {
  type: 'medicaid' | 'non-medicaid' | 'request' | null
  onChange: (updated: 'medicaid' | 'non-medicaid' | 'request') => void
}) => (
  <Flex pt={2} gap={1} w="100%">
    <NewPlanTypeButton
      type="non-medicaid"
      selected={type === 'non-medicaid'}
      onClick={() => onChange('non-medicaid')}
    />
    <NewPlanTypeButton
      type="medicaid"
      selected={type === 'medicaid'}
      onClick={() => onChange('medicaid')}
    />
    <NewPlanTypeButton
      type="request"
      selected={type === 'request'}
      onClick={() => onChange('request')}
    />
  </Flex>
)

export const NewPlanModal = ({ onClose }: { onClose: () => void }) => {
  const { assessmentId, selectedAssessment } = useContext(UserContext)
  const { additionalPlans } = selectedAssessment || {}
  const { appName } = useApp()
  const [type, setType] = useState<
    'medicaid' | 'non-medicaid' | 'request' | null
  >(null)
  const field = useMemo(
    () => getInsuranceCoverageField(type === 'medicaid'),
    [type],
  )

  const { processResponse } = useContext(PopUpMessageContext)

  const handleSubmit = useCallback(
    async (
      data: FieldMapValue,
      onUploadProgress: OnUploadProgress,
    ): Promise<UpdateCallback> => {
      const assessmentRef = doc(db, `${ASSESSMENTS}/${assessmentId}`)
      const planId = getUniqueRandomId(Object.keys(additionalPlans || {}))
      try {
        const processed = await processFieldMapData(
          `${ASSESSMENTS}/${assessmentId}/additionalPlans/${planId}`,
          field,
          data,
          undefined,
          onUploadProgress,
        )
        await updateDoc(
          assessmentRef,
          `additionalPlans.${planId}`,
          addMetadata(processed, appName, true),
        )
      } catch (err: any) {
        processResponse({
          error: err?.message || 'Error saving new plan',
        })
        return { error: err?.message || 'Error saving new plan' }
      }
      onClose()
      return { success: 'Plan saved' }
    },
    [assessmentId, field, onClose, processResponse, additionalPlans, appName],
  )

  const handleRequestNewPlan = useCallback(
    async (data: FieldMapValue): Promise<UpdateCallback> => {
      if (!assessmentId) {
        return processResponse({ error: 'No assessment selected' })
      }

      const { message, withCallInForm, sendEmail } = data
      try {
        await requestCoverage({
          assessmentId,
          message,
          withCallInForm,
          appName,
          sendEmail: !!sendEmail,
          requestId: null,
        })
      } catch (err: any) {
        return processResponse({
          error: err?.message || 'Error requesting coverage',
        })
      }
      onClose()
      return processResponse({ success: 'Coverage requested' })
    },
    [assessmentId, processResponse, onClose, appName],
  )
  const requestInitData = useMemo(
    () => ({
      sendEmail: true,
      // withCallInForm: true,
    }),
    [],
  )

  return (
    <DefaultModal
      isOpen
      overlayHeader
      onClose={onClose}
      size="xl"
      contentProps={{ bg: 'gray.100' }}
      render={() => (
        <Flex w="100%" flexFlow="column">
          <Flex
            w="100%"
            p={3}
            borderBottom="1px solid #cdcdcd"
            justify="space-between"
            flexFlow="column"
          >
            <Text
              px={3}
              fontFamily="Hero-New"
              fontSize="lg"
              lineHeight={1}
              color="gray.600"
            >
              New Plan
            </Text>
            <Flex px={2} w="100%" align="center">
              <NewPlanTypeSelect type={type} onChange={setType} />
            </Flex>
          </Flex>
            <Collapse
              style={{ width: '100%' }}
              in={type === 'medicaid' || type === 'non-medicaid'}
            >
              <SimpleForm
                onSubmit={handleSubmit}
                field={field}
                // boxProps={{ }}
              />
            </Collapse>
            <Collapse style={{ width: '100%' }} in={type === 'request'}>
              <SimpleForm
                onSubmit={handleRequestNewPlan}
                field={coverageRequestField}
                value={requestInitData}
                boxProps={{ bg: 'gray.50' }}
              />
            </Collapse>
        </Flex>
      )}
    />
  )
}
