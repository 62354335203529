/*
Delivery    Global Maternity Care    59400    Z34.80    O80    Z39.2    Z39.1    Z3A.40
Delivery    Vaginal Delivery + PP Care    59410    O80    Z39.2    Z39.1        Z3A.40
Delivery    Vaginal Delivery    59409    O80                Z3A.40
Delivery    Cesarean Global Maternity Care    59510    Z34.80    O82    Z39.2    Z39.1    Z3A.40
Delivery    Cesarean Delivery    59514    O82                Z3A.40

Antepartum    Prenatal Visits, 4-6    59425    Z34.80    Z33.1
Antepartum    Prenatal Visits, 7 or more    59426    Z34.80    Z33.1
Antepartum    New Patient Home Visit, 1hr    99344    Z34.80    Z33.1
Antepartum    Established Patient Home 40+ min    99349    Z34.80    Z33.1
Antepartum    New Patient Office Visit, 1 hr    99205    Z34.80    Z33.1
Antepartum    New Patient Office Visit, 45-60 min    99204    Z34.80    Z33.1
Antepartum    Established Patient Office Visit 40-54 min    99215    Z34.80    Z33.1
Antepartum    Consultation / 60 min    99404    Z34.80    Z33.1
Fetal nonstress test        59025        Z33.1

Postpartum    Postpartum Visits    59430    Z39.2    Z39.1
Postpartum    Established Patient Home Visit 40+min    99349    Z39.2    Z39.1
Postpartum    Established Patient Office Visit 40-54min    99215    Z39.2    Z39.1

Fetal non-stress tes
Labor    Home Labor, 1st hour    99350    Z34.80    Z33.1
Labor    Home Labor, 2nd hour    99291    Z34.80    Z33.1
Labor    Home Labor, 30 minutes    99292    Z34.80    Z33.1    O63.9
Labor    Home Labor, 15 minutes    99417    Z34.80    Z33.1    O63.9
Labor    Hospital Admission (1st hr of Labor)    99223    Z34.80    Z33.1
Labor    Hospital Labor, 15 minutes    99418    Z34.80    Z33.1    O63.9
Labor    Discharge, Final exam in Hospital    99217    Z39.2

Newborn
Newborn    Initial Newborn Care    99461    Z38.1    Z00.110    Z37.0
Newborn    New Patient Home Visit, 1hr    99344    Z38.1    Z00.110
Newborn    New Patient Home Visit, 30+ minutes    99342        Z00.110 OR Z00.111
Newborn    Established Patient Home Visit    99349    Z38.1    Z00.110    Z00.111
Newborn    Established patient / newborn    99391        Z00.110 or Z00.111
Newborn    Birth Assistant    99464    Z00.110
Newborn    Newborn Resuscitation    99465    P92.6

Lactation    Lactation Class / Breastfeeding support    S9443    Z39.1

GYN    New Patient GYN Visit, 18-39 yrs    99385    Z01.419    Z30.0
GYN    Established Patient GYN Visit, 18-39 yrs    99395    Z01.419
GYN    New Patient GYN Visit, 40-64 yrs    99386    Z01.419    Z30.0
GYN    Established Patient GYN Visit, 40-64 yrs    99396    Z01.419
GYN    IUD Placement    58300    Z30.430
GYN    Annual GYN Exam, Established Patient    S0612    Z01.419
GYN    Pap Smear    Q0091    Z11.51

*/

import { CPTCode, CPTCodeGroup } from '../../../types/providers'
import { z39_1 } from '../icdCodes'

const lactationClass: CPTCode = {
  description: 'Lactation Class / Breastfeeding support',
  code: 'S9443',
  icd10: [z39_1],
}

export const lactationCodes: CPTCodeGroup = {
  name: 'Lactation',
  codes: [
    lactationClass,
  ],
}
