import {
  CustomEditor,
  FieldTypes,
  FontId,
  PopulatedAssessment,
  Shortcut,
  ShortcutMap,
  Stage,
  TemplateFieldMap,
  TemplateKey,
} from '@hb/shared'
import { ReactEditor } from 'slate-react'
import { generatePDF } from '../../../backend/functions'
import { downloadFromStorage } from '../../../backend/storage'
import { getPaymentsDropdown } from './cost'
import { insuranceItems } from './insurance'
import { midwifeItems, practiceItems } from './midwife'

const dateShortcuts: ShortcutMap = {
  name: 'Date',
  shortcut: true,
  children: {
    shortDate: {
      name: 'Date (short)',
      shortcut: true,
      type: FieldTypes.DATE,
    },
    longDate: {
      name: 'Date (long)',
      shortcut: true,
      type: FieldTypes.DATE,
    },
  },
}

const getAuthShortcuts = (
  assessment: PopulatedAssessment | undefined,
): ShortcutMap => ({
  name: 'Auth',
  shortcut: true,
  children: Object.keys(assessment?.authorizations || { atLeast1: {} }).reduce(
    (acc, _, index) => ({
      ...acc,
      [`${index}`]: {
        name: `Authorization ${index + 1} Reference Number`,
        shortcut: true,
        type: FieldTypes.TEXT,
      },
    }),
    {} as Record<string, Shortcut>,
  ),
})

const patientItems: ShortcutMap = {
  name: 'Patient',
  shortcut: true,
  children: {
    fname: {
      name: 'First Name',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    lname: {
      name: 'Last Name',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    dob: {
      name: 'DOB',
      shortcut: true,
      type: FieldTypes.DATE,
    },
    address: {
      name: 'Address (1 line)',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    address2: {
      name: 'Address (2 line)',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
  },
}

const pregnancyItems: ShortcutMap = {
  name: 'Pregnancy',
  shortcut: true,
  children: {
    edd: {
      name: 'EDD',
      shortcut: true,
      type: FieldTypes.DATE,
    },
    lmp: {
      name: 'LMP',
      shortcut: true,
      type: FieldTypes.DATE,
    },
    firstVisit: {
      name: 'Date of First Visit',
      shortcut: true,
      type: FieldTypes.DATE,
    },
  },
}

const templateFields: TemplateFieldMap = {
  isTemplateFieldMap: true,
  name: 'Input Fields',
  children: {
    initial: {
      isTemplateField: true,
      type: FieldTypes.INITIALS,
      placeholder: 'Initials',
    },
    signature: {
      isTemplateField: true,
      type: FieldTypes.SIGNATURE,
      placeholder: 'Signature',
    },
  },
}

export const getDropdownItems = (
  assessment: PopulatedAssessment | undefined,
  templateType?: TemplateKey,
): Record<string, ShortcutMap | Stage | TemplateFieldMap> => {
  switch (templateType) {
    case 'consentForm':
      return {
        patient: patientItems,
        practice: practiceItems,
        pregnancy: pregnancyItems,
        templateFields,
      }
    default: return {
      patient: patientItems,
      pregnancy: pregnancyItems,
      midwife: midwifeItems,
      insurance: insuranceItems,
      cost: getPaymentsDropdown(assessment),
      authorizations: getAuthShortcuts(assessment),
      // ...nestedAssessmentFields.children,
      // calculations,
      dateShortcuts,
    // insuranceShortcuts,
    // signOn: signOnFields,
    }
  }
}

export const fontFamilyFromFontId = (fontId: FontId) => {
  switch (fontId) {
    case 'Courier':
      return 'Courier New'
    case 'Helvetica':
      return 'Helvetica'
    case 'TimesRoman':
      return 'Times New Roman'
    default:
      throw new Error('Invalid font id')
  }
}

export const downloadFromEditor = async (editor: CustomEditor) => {
  const node = ReactEditor.toDOMNode(editor, editor)
  if (node) {
    try {
      const { data } = await generatePDF({ html: node.innerHTML })
      const { path } = data
      if (path) {
        const res = await downloadFromStorage(path)
        return res
      }
      return { error: 'Error with response - no URL' }
    } catch (err) {
      console.error(err)
      return { error: `Internal error: ${(err as any).message}` }
    }
  } else {
    return { error: 'Internal error - no html' }
  }
}
