import { Box, IconButton, Tooltip } from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import { arrayRemove, arrayUnion, updateDoc } from 'firebase/firestore'
import React, { useContext, useMemo, useState } from 'react'
import { useApp } from '../../../contexts'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import notificationsIcon from '../../../icons/notifications.svg'
import notificationsOffIcon from '../../../icons/notifications_off.svg'
import { SvgIcon } from '../../../icons/SvgIcon'
import { useAuth } from '../../../store'
import { ThreadViewContext } from './contexts'

export const ThreadMuteButton = () => {
  const { appName } = useApp()
  const {
    thread, threadRef,
  } = useContext(ThreadViewContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const { mutedBy } = thread || {}
  const [markingMuted, setMarkingMuted] = useState(false)
  const authUser = useAuth((s) => s.authUser)
  const mutedByMe = useMemo(
    () => authUser && mutedBy?.includes(authUser.uid),
    [authUser, mutedBy],
  )
  return (
    <Box>
      <Tooltip
        placement="top"
        hasArrow
        bg="whitesmoke"
        color="gray.600"
        label={mutedByMe ? 'Unmute this thread' : 'Mute this thread'}
      >
        <IconButton
          ml="auto"
          aria-label="Mark as unread"
          isLoading={markingMuted}
          bg={mutedByMe ? colors.red.hex : 'gray.50'}
          borderRadius={6}
          filter="drop-shadow(1px 1px 3px #00000077)"
          color={mutedByMe ? 'whiteAlpha.800' : 'gray.500'}
          onClick={async () => {
            if (!appName) return
            setMarkingMuted(true)
            try {
              if (!threadRef || !authUser) return
              if (mutedByMe) await updateDoc(threadRef, 'mutedBy', arrayRemove(authUser.uid))
              else await updateDoc(threadRef, 'mutedBy', arrayUnion(authUser.uid))
              setMarkingMuted(false)
            } catch (e: any) {
              console.error(e)
              processResponse({
                error: `Error marking thread ${
                  mutedByMe ? 'unmuted' : 'muted'
                }${e.message ? ` - ${e.message}` : ''}`,
              })
              setMarkingMuted(false)
            }
          }}
          // bg={manuallyMarkedUnread ? 'whiteAlpha.700' : 'whiteAlpha.400'}
          icon={
            <SvgIcon
              opacity={0.65}
              filter={mutedByMe ? 'invert(100%)' : 'invert(0%)'}
              src={mutedByMe ? notificationsOffIcon : notificationsIcon}
            />
          }
        />
      </Tooltip>
    </Box>
  )
}
