import { Badge } from '@chakra-ui/react'
import { coverageSnippetNames } from '@hb/shared/constants'
import { CoverageStageStatus } from '@hb/shared/types'
import React, { useMemo } from 'react'

export const CoverageStatusBadge = ({
  status,
}: {
  status: CoverageStageStatus
}) => {
  const allComplete = useMemo(
    () => (status?.incomplete.length || 0) === 0,
    [status],
  )
  const missingSnippets = useMemo(() => {
    const hasIncomplete = !!status?.incomplete.length
    if (!hasIncomplete) return null

    return `Missing ${(status?.incomplete || []).map((s) => coverageSnippetNames[s]).join(', ')}`
  }, [status])
  if (!status) return null
  return allComplete ? (
    <Badge colorScheme="green">Complete</Badge>
  ) : (
    <Badge colorScheme="orange">{missingSnippets || 'Additional info required'}</Badge>
  )
}
