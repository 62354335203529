import {
  AddIcon, ArrowBackIcon, ArrowForwardIcon, CheckIcon,
} from '@chakra-ui/icons'
import {
  Box,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Stack,
  StackDivider,
  Tab,
  TabProps,
  Text,
} from '@chakra-ui/react'
import {
  Assessment,
  ASSESSMENTS,
  AssessmentStepKey,
  colors,
  Descendant,
  DropdownField,
  FieldMapValue,
  FieldTypes,
  getAssessmentName,
  getAvailableForms,
  getDateTimeString,
  PopulatedAssessment,
  UpdateCallback,
} from '@hb/shared'
import {
  ActionButton,
  AppFormFilesProvider,
  AssessmentProgress,
  BoxHeader,
  ContactButtons,
  createNewAssessment,
  CreateNewAssessmentArgs,
  deleteAssessment,
  DeleteButton,
  FloatingContainer,
  InlineLink,
  Italic,
  LoadingView,
  PageContainer,
  PopUpMessageContext,
  ReadOnlyEditor,
  ScreenContext,
  SolidActionButton,
  StandaloneInput,
  submitAssessmentAnswers,
  updateAssessmentAnswers,
  updateAssessmentViewed,
  useAppModals,
  useAuth,
  usePopulatedAssessment,
  useResizeObserver,
} from '@hb/shared-frontend'
import { FormLabelsContext } from '@hb/shared-frontend/contexts/FormLabelsContext'
import * as React from 'react'
import {
  useCallback, useContext, useMemo, useRef, useState,
} from 'react'
import { useHistory } from 'react-router'
import { HelpButton } from 'src/Help'

import { FormWizard } from '@hb/shared-frontend/components/forms/FormWizard/FormWizard'
import { EditorModalContainer } from '@hb/shared-frontend/components/RichText/EditorModalContainer'
import { AssessmentContext } from '@hb/shared-frontend/contexts/AssessmentContext'
import { FormsViewContext } from '@hb/shared-frontend/contexts/FormsViewContext'
import { NavLink } from 'react-router-dom'
import SignInToView from 'src/User/SignInToView'
import { AssessmentDisclaimers } from './AssessmentDisclaimers'
import { AssessmentFormPreview } from './FormPreview'
import { useFormsView } from './hooks'
import { PatientDataForm } from './PatientDataForm'
import { SignOnPanel } from './SignOnPanel'

const SubmitQuestionnaireButton = ({
  assessmentId,
}: {
  assessment: PopulatedAssessment
  assessmentId: string
}) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = React.useCallback(async () => {
    setSubmitting(true)
    await submitAssessmentAnswers({ id: assessmentId })
      .then(() => {
        processResponse({ success: 'Submitted!' })
        // selectStep('view')
        setSubmitting(false)
      })
      .catch((err: any) => {
        processResponse({
          error: err?.message || 'Error submitting questionnaire - get in touch!',
        })
        setSubmitting(false)
      })
  }, [assessmentId, processResponse])
  return (
    <ActionButton
      mr="auto"
      // size='sm'
      bg={colors.green.hex}
      color="white"
      isLoading={submitting}
      onClick={onSubmit}
      minW="150px">
      Submit Questionnaire
    </ActionButton>
  )
}

const SubmitButtons: React.FC<{
  assessment: PopulatedAssessment
  assessmentId: string
}> = ({ assessment, assessmentId }) => {
  const { submittedOn, sentOn } = assessment
  const { selectStep } = useContext(FormsViewContext)

  return (
    <FloatingContainer mb={4}>
      <BoxHeader>Your questionnaire is complete!</BoxHeader>
      {submittedOn ? (
        <>
          <Italic>Submitted on {getDateTimeString(submittedOn)}</Italic>
          {sentOn ? (
            <ContactButtons mt={4}>
              <SolidActionButton size="sm" onClick={() => selectStep('view')} flex={1}>
                View your assessment
              </SolidActionButton>
            </ContactButtons>
          ) : (
            <Text>
              We'll get back to you soon with an assessment! You can edit your information until the
              assessment is sent. Once you get an assessment, you can get in touch to change any
              info.
              <br />
              <br /> If you don't hear back from us within 3 business days, please check your spam,
              junk, or promotions folder in your email, and make sure your browser is up to date. If
              you still don't see anything, please schedule a call.
            </Text>
          )}
        </>
      ) : (
        <>
          <Italic>Review and submit your answers to receive your assessment</Italic>
          <Flex direction={'column'} mt={1}>
            <SubmitQuestionnaireButton assessmentId={assessmentId} assessment={assessment} />
          </Flex>
        </>
      )}
    </FloatingContainer>
  )
}

const AssessmentResultsModal = ({
  isOpen,
  onClose,
  onProceed,
  editorVersion,
  signedOnDate,
  results,
}: {
  isOpen: boolean
  onClose: () => void
  onProceed: () => void
  editorVersion: 'v1' | 'v2'
  signedOnDate: number | undefined | null
  results: Descendant[]
}) => {
  const { height: screenHeight } = useContext(ScreenContext)
  const buttonsRef = useRef<HTMLDivElement>(null)
  const { height: buttonsHeight } = useResizeObserver(buttonsRef)

  const maxHeight = useMemo(() => screenHeight - 80 - buttonsHeight, [buttonsHeight, screenHeight])
  return (
    <EditorModalContainer
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      height={maxHeight}
      render={(sizeProps, onBack) => (
        <Flex maxH={screenHeight - 70} flexFlow="column" w="100%">
          <Box minH="0" flex={1} w="100%">
            <ReadOnlyEditor {...sizeProps} version={editorVersion} value={results} />
          </Box>
          <Stack
            divider={<StackDivider />}
            gap={[0, 0, 2]}
            bg="gray.50"
            direction={['column', 'column', 'row']}
            zIndex={1}
            p={[2, 2, 3]}
            borderTop="1px solid #cdcdcd"
            w="100%">
            <ActionButton bg="white" size="sm" onClick={onBack} variant="outline">
              <ArrowBackIcon mr={2} />
              <Text>Back</Text>
            </ActionButton>
            <Box flex={1}>
              <ContactButtons height="auto" ref={buttonsRef} w="100%"></ContactButtons>
            </Box>
            <SolidActionButton flex={1} size="sm" w={['100%', '100%', '200px']} gap={2} onClick={onProceed}>
              {signedOnDate ? <CheckIcon /> : null}
              {signedOnDate ? 'Signed on' : 'Sign On'}
              {signedOnDate ? null : (
                <ArrowForwardIcon filter="drop-shadow(1px 1px 3px #00000055)" w={5} h={5} />
              )}
            </SolidActionButton>
          </Stack>
        </Flex>
      )}
    />
  )
}

const AssessmentStatus = ({
  assessment,
  assessmentId,
  setViewSignOnQuestionnaire,
}: {
  assessment: PopulatedAssessment
  assessmentId: string
  setViewSignOnQuestionnaire: (view: boolean) => void
}) => {
  const { selectStep } = useContext(FormsViewContext)
  const {
    results, resultsViewedOn, signedOnDate, editorVersion,
  } = assessment
  const [viewAssessment, setViewAssessment] = useState(false)

  const [resultsViewedOverride, setResultsViewedOverride] = useState<number | undefined>()
  const handleViewClick = useCallback(() => {
    if (!assessment.resultsViewedOn) {
      setResultsViewedOverride(Date.now())
      updateAssessmentViewed({ id: assessmentId })
    }
    setViewAssessment(true)
  }, [assessment, assessmentId])

  const usedVersion = editorVersion || 'v1'

  const onProceed = useCallback(() => {
    selectStep('signOn')
    setViewAssessment(false)
    setViewSignOnQuestionnaire(true)
  }, [setViewSignOnQuestionnaire, selectStep])

  return (
    <FloatingContainer mb={4}>
      <BoxHeader>Your assessment is ready!</BoxHeader>
      <AssessmentDisclaimers
        onSubmit={handleViewClick}
        viewedOn={resultsViewedOn || resultsViewedOverride}
      />
      {results && viewAssessment ? (
        <AssessmentResultsModal
          results={results}
          signedOnDate={signedOnDate}
          editorVersion={usedVersion}
          isOpen={viewAssessment}
          onProceed={onProceed}
          onClose={() => setViewAssessment(false)}
        />
      ) : null}
    </FloatingContainer>
  )
}

const ReadOnlyFooter = () => (
  <Flex bg="blackAlpha.100" py={3} px={6}>
    <Text>
      You've already submitted this assessment,{' '}
      <NavLink to="/contact">
        <InlineLink>get in touch</InlineLink>{' '}
      </NavLink>
      with us if you need to edit any information!
    </Text>
  </Flex>
)

const AssessmentView: React.FC<{
  assessment: Assessment
  assessmentIndex: number | null
  assessmentId: string
  stage: AssessmentStepKey
}> = ({
  assessment, assessmentIndex, stage, assessmentId,
}) => {
  const populatedAssessment = usePopulatedAssessment(assessment)
  const {
    status, sentOn, resultsViewedOn, skippedQuestionnaire,
  } = populatedAssessment || {}
  const {
    open, close, form, selected,
  } = useContext(FormsViewContext)
  const complete = useMemo(
    () => status && !['incomplete', 'awaiting-questionnaire'].includes(status),
    [status],
  )

  const availableForms = useMemo(
    () => getAvailableForms(false, populatedAssessment),
    [populatedAssessment],
  )
  const canSubmit = useMemo(
    () => availableForms?.[availableForms.length - 1]?.status !== 'info',
    [availableForms],
  )

  const { showMessage } = useContext(PopUpMessageContext)

  const onSubmit = useCallback(
    async (data: FieldMapValue | undefined): Promise<UpdateCallback> => {
      try {
        if (!assessmentId) return { error: 'No assessment ID' }
        await updateAssessmentAnswers({ id: assessmentId, data: data || {} })
      } catch (err: any) {
        showMessage({
          text: 'Error saving answers',
          subText: err.message,
          type: 'error',
        })
        return { error: err.message }
      }
      return { success: 'Saved!' }
    },
    [assessmentId, showMessage],
  )

  const [viewSignOnQuestionnaire, setViewSignOnQuestionnaire] = useState(false)
  return (
    <AssessmentContext.Provider value={{ assessment: populatedAssessment }}>
      <Collapse style={{ width: '100%' }} in={!skippedQuestionnaire && stage === 'view'}>
        {sentOn ? (
          <AssessmentStatus
            setViewSignOnQuestionnaire={setViewSignOnQuestionnaire}
            assessment={populatedAssessment!}
            assessmentId={assessmentId}
          />
        ) : null}
      </Collapse>
      <Collapse style={{ width: '100%' }} in={!skippedQuestionnaire && stage === 'questions'}>
        <Stack w="100%" spacing={1} mb={4} direction="column">
          {availableForms
            ? availableForms.map(({ id: formId }, index: number) => (
                <AssessmentFormPreview
                  assessmentIndex={assessmentIndex}
                  key={formId}
                  first={index === 0}
                  formId={formId}
                  assessment={assessment}
                />
            ))
            : null}
        </Stack>
      </Collapse>
      {form ? (
        <FormWizard
          stageId={selected?.stageId}
          ReadOnlyFooter={ReadOnlyFooter}
          readOnly={!!assessment.submittedOn}
          baseStoragePath={`${ASSESSMENTS}/${assessmentId}/data`}
          data={assessment.data}
          selectStage={(stageId) => {
            if (!selected) return
            if (!stageId) close()
            else open(selected.formId, stageId)
          }}
          onSubmit={onSubmit}
          form={form}
        />
      ) : null}
      <Collapse
        style={{ width: '100%' }}
        in={!skippedQuestionnaire && ['questions'].includes(stage)}>
        {canSubmit && complete ? (
          <SubmitButtons assessmentId={assessmentId} assessment={populatedAssessment!} />
        ) : null}
      </Collapse>
      <Collapse style={{ width: '100%' }} in={skippedQuestionnaire || stage === 'signOn'}>
        {skippedQuestionnaire || (sentOn && (resultsViewedOn || viewSignOnQuestionnaire)) ? (
          <SignOnPanel
            viewSignOnQuestionnaire={viewSignOnQuestionnaire}
            setViewSignOnQuestionnaire={setViewSignOnQuestionnaire}
            assessment={populatedAssessment!}
          />
        ) : null}
      </Collapse>
    </AssessmentContext.Provider>
  )
}

export const AssessmentTab = ({ assessment, ...props }: TabProps & { assessment: Assessment }) => (
  <Tab
    fontWeight={500}
    fontSize="sm"
    bg="whiteAlpha.800"
    whiteSpace="nowrap"
    _selected={{ bg: colors.green.hex, color: 'white' }}
    _focus={{ boxShadow: 'none' }}
    {...props}>
    {getAssessmentName(assessment)}
  </Tab>
)
const AssessmentSelect = () => {
  const { assessmentId, assessments } = useContext(FormsViewContext)
  const { admin, user } = useAuth()
  const [onCreateLoading, setOnCreateLoading] = useState(false)
  const history = useHistory()

  const assessmentSelectField = useMemo<DropdownField>(
    () => ({
      placeholder: 'Select an assessment',
      type: FieldTypes.DROPDOWN,
      options:
        Object.entries(assessments || {}).map(([id, assessment]) => ({
          id,
          text: getAssessmentName(assessment),
        })) || [],
    }),
    [assessments],
  )
  return (
    <Flex w="100%" align="center" flex={1} zIndex={2} position="relative">
      <StandaloneInput
        field={assessmentSelectField}
        theme="detailed"
        onChange={(v: string) => {
          history.push(`/assessment/${v}`)
        }}
        value={assessmentId}
      />
      {admin ? (
        <HStack>
          <IconButton
            isLoading={onCreateLoading}
            onClick={async () => {
              if (!user) return
              setOnCreateLoading(true)
              const mostRecentAssessment = Object.values(assessments || {}).sort(
                (a, b) => (b.createdOn || 0) - (a.createdOn || 0),
              )[0]
              const submitted: CreateNewAssessmentArgs = { patientId: user.id }
              if (mostRecentAssessment?.midwifeId) {
                submitted.practiceId = mostRecentAssessment.midwifeId
              }
              const res = await createNewAssessment(submitted)
              const id = res.data as string | undefined
              setOnCreateLoading(false)

              history.push(`/assessment/${id}`)
            }}
            ml={3}
            bg="white"
            size="sm"
            boxShadow="md"
            aria-label="add assessment"
            icon={<AddIcon />}
          />
          {assessmentId ? (
            <Box bg="red.300" borderRadius={6} shadow="sm">
              <DeleteButton
                size="sm"
                itemName="assessment"
                onDelete={() => deleteAssessment(assessmentId).then(({ success }) => {
                  if (success) {
                    history.push('/assessment')
                  }
                })
                }
              />
            </Box>
          ) : null}
        </HStack>
      ) : null}
    </Flex>
  )
}

const FormsViewBody = () => {
  const emailVerification = useAppModals((s) => s.emailVerification)
  const {
    assessmentIndex,
    assessment,
    step,
    selectStep,
    assessments,
    assessmentId,
    assessmentsLoading,
  } = useContext(FormsViewContext)
  const { authUser, user, loading } = useAuth()
  const {
    phone, dob, fname, lname,
  } = user || {}
  const { processResponse } = useContext(PopUpMessageContext)
  // this should come from current assessment
  const [addLoading, setAddLoading] = useState(false)
  const onAddAssessment = useCallback(() => {
    if (!authUser) return processResponse({ error: 'Not signed in' })
    setAddLoading(true)
    const created: CreateNewAssessmentArgs = { patientId: authUser.uid }
    const mostRecentAssessment = Object.values(assessments || {}).sort(
      (a, b) => (b.createdOn || 0) - (a.createdOn || 0),
    )[0]
    const practiceId = mostRecentAssessment?.midwifeId
    if (practiceId) {
      created.practiceId = practiceId
    }
    return createNewAssessment(created)
      .then(() => processResponse({ success: 'Assessment created!' }))
      .catch((err: any) => {
        processResponse({
          error: err?.message || 'Error creating assessment - get in touch!',
        })
      })
  }, [authUser, processResponse, assessments])
  const hasAssessments = useMemo(() => Object.keys(assessments || {}).length, [assessments])
  const patientDataComplete = useMemo(
    () => phone && dob && fname && lname,
    [phone, dob, fname, lname],
  )
  if (assessmentsLoading || loading) {
    return <LoadingView text="Loading assessments" />
  }
  if (!authUser) {
    return <SignInToView message="Sign in to get started" />
  }
  if (!authUser?.emailVerified) {
    return (
      <ActionButton bg="white" onClick={() => emailVerification.open()}>
        Verify your email to get started
      </ActionButton>
    )
  }
  if (!patientDataComplete) {
    return <PatientDataForm />
  }

  if (!hasAssessments) {
    return (
      <ActionButton
        variant="solid"
        onClick={onAddAssessment}
        width="auto"
        height="auto"
        fontWeight={600}
        fontSize="md"
        my={3}
        px={6}
        py={4}
        isLoading={addLoading}>
        Begin your questionnaire
      </ActionButton>
    )
  }
  return (
    <AppFormFilesProvider>
      <Flex px={[0, 1]} h="100%" zIndex={0} pos="relative" direction="column" width="100%">
        <HStack width="100%">
          <AssessmentSelect />
          <HelpButton />
        </HStack>
        <AssessmentProgress
          stage={step || 'questions'}
          selectStage={selectStep}
          assessment={assessment}
        />
        <Flex w="100%" direction="column">
          {assessmentId && assessment ? (
            <AssessmentView
              assessmentId={assessmentId}
              stage={step || 'questions'}
              assessmentIndex={assessmentIndex}
              assessment={assessment as Assessment}
            />
          ) : null}
        </Flex>
      </Flex>
    </AppFormFilesProvider>
  )
}

const FormsView: React.FC = () => {
  const data = useFormsView()
  return (
    <PageContainer>
      <Flex
        px={[0, 1]}
        py={3}
        w="100%"
        maxW="1000px"
        // width={`${width}px`}
        position="relative"
        direction="column"
        align="center">
        <HStack bg={colors.green.hex} borderRadius={6} py={2} px={3} mb={3} boxShadow="md" w="100%">
          <Text
            fontFamily="Comfortaa"
            fontWeight={600}
            fontSize="lg"
            color="white"
            textShadow="1px 1px 2px #00000077">
            Assessment
          </Text>
        </HStack>
        <FormsViewContext.Provider value={data}>
          <FormLabelsContext.Provider value={{ value: data.assessment?.mergedData }}>
            <FormsViewBody />
          </FormLabelsContext.Provider>
        </FormsViewContext.Provider>
      </Flex>
    </PageContainer>
  )
}

export default FormsView
