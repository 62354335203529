import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Collapse, Flex, IconButton, Stack, Text,
} from '@chakra-ui/react'
import {
  Assessment, AssessmentFormKey, colors,
} from '@hb/shared'
import {
  BoxHeader, FloatingContainer, FormPreview, InlineLink, Italic, useAppModals, useAssessmentForm,
} from '@hb/shared-frontend'
import { FormsViewContext } from '@hb/shared-frontend/contexts/FormsViewContext'
import React, { useContext, useMemo } from 'react'

import {
  insurancePlansFaq,
  marketplacePlansFaq,
  medicaidPlansFaq,
} from '../Resources/insurancePlansFaq'
import { FAQListView } from '../Resources/ResourcesView'

const PlanOptionsPanel = () => (
    <FloatingContainer py={6} px={8} pb={0}>
      <BoxHeader style={{ marginBottom: '1rem' }}>
        Information on insurance plans
      </BoxHeader>
      <Stack>
        <FAQListView size='sm' {...insurancePlansFaq} />
        <FAQListView size='sm' {...marketplacePlansFaq} />
        <FAQListView size='sm' {...medicaidPlansFaq} />
      </Stack>
    </FloatingContainer>
)

const ScheduleCallPanel = () => {
  const {
    scheduleCall,
  } = useAppModals()
  return (
    <FloatingContainer>
      <BoxHeader>Schedule a call</BoxHeader>
      <Flex>
        Follow this link to{' '}
        <InlineLink onClick={() => scheduleCall.open()}>
          schedule a call with us
        </InlineLink>
      </Flex>
    </FloatingContainer>
  )
}

export const AssessmentFormPreview: React.FC<{
  formId: AssessmentFormKey
  first?: boolean
  assessment: Assessment
  assessmentIndex: number | null
}> = ({
  formId, first, assessment,
}) => {
  const { open } = useContext(FormsViewContext)
  const { status, form } = useAssessmentForm(assessment.data, formId)
  const stageKeys = useMemo(() => Object.keys(form?.stages || {}), [form])
  if (formId === 'plan-options') return <PlanOptionsPanel />
  if (formId === 'schedule-call') return <ScheduleCallPanel />
  return (
    <Flex w='100%' px={[0, 2]} py={1}>
    <FloatingContainer
      onClick={
        status === 'incomplete' ? () => open(formId, stageKeys[0]) : undefined
      }
      style={{
        borderTop: first ? undefined : '1px solid #cdcdcd',
        height: 'auto',
        minHeight: 40,
        cursor: status === 'incomplete' ? 'pointer' : 'default',
      }}
    >
      <Flex
        w='100%'
        transition='opacity 500ms'
        align='flex-start'
        justify='flex-start'
        flexFlow='column'
      >
        <Flex gap={2} align='center' width='100%'>
          <BoxHeader cursor='pointer'>{form?.name || ''}</BoxHeader>
          {status === 'incomplete' ? (
            <IconButton
              ml='auto'
              onClick={() => open(formId, stageKeys[0])}
              aria-label='begin form'
              borderRadius='full'
              background={colors.green.hex}
              height={8}
              minW={8}
              color={'white'}
              icon={<ArrowForwardIcon width={5} height={5} />}
              size='sm'
            />
          ) : null}
        </Flex>
        {form && form.description ? <Italic>{form.description}</Italic> : null}
        <Collapse style={{ width: '100%' }} in={status !== 'incomplete'}>
          {form ? (
            <FormPreview
              form={form}
              data={assessment.data}
              readOnly={!!assessment.submittedOn}
              onStageEdit={(stageId) => open(formId, stageId)}
            />
          ) : (
            <Text style={{ color: 'orangered' }}>
              {`Error: Can't find form with id ${formId}`}
            </Text>
          )}
        </Collapse>
      </Flex>
    </FloatingContainer>
    </Flex>
  )
}
