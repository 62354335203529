import { FormsViewData } from '@hb/shared'
import { createContext } from 'react'

export const FormsViewContext = createContext<FormsViewData>({
  step: 'questions',
  selectStep: () => {},
  selected: null,
  open: () => {},
  close: () => {},
  assessmentIndex: null,
  assessmentsLoading: false,
  assessments: null,
  form: null,
})
