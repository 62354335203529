import {
  callInField, getBasicInfoField, policyOwnerFieldMap,
} from '@hb/shared/fields'
import { CoverageSnippet, FieldMap } from '@hb/shared/types'

export const coverageSnippetStage: Record<
  CoverageSnippet,
  (medicaid: boolean) => FieldMap
> = {
  'basic-info': getBasicInfoField,
  'call-in': () => callInField,
  'policy-owner': () => policyOwnerFieldMap,
}
