import { FieldTypes } from '../constants'
import {
  BooleanField, DateField, EmailField, Field, FieldMap, PhoneField, TextField,
} from '../types'
import { emailField } from './shared'

export const phoneField: Field = {
  labels: [{ text: 'How can we get in touch with you?' }],
  type: FieldTypes.PHONE,
  placeholder: 'Phone Number',
}

export const dobField: DateField = {
  labels: [{ text: 'What is your date of birth?' }],
  type: FieldTypes.DATE,
  placeholder: 'Date of Birth',
}
const fnameField: TextField = {
  labels: [{ text: 'What is your name?' }],
  type: FieldTypes.TEXT,
  placeholder: 'First Name',
}

const middleNameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Middle Name',
  optional: true,
}

const lnameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Last Name',
}

export const voicemailOkField: BooleanField = {
  type: FieldTypes.BOOLEAN,
  labels: [{ text: 'Is it ok to leave a voicemail?' }],
  placeholder: 'Voicemail OK?',
}

export const optIntoTextField: BooleanField = {
  type: FieldTypes.BOOLEAN,
  labels: [{ text: 'If yes, our team will send you reminders regarding your assessment via SMS' }],
  placeholder: 'Opt into text reminders',
}

export const nicknameField: Field = {
  // labels: [{ text: 'Do you have a nickname that you prefer?' }],
  type: FieldTypes.TEXT,
  placeholder: 'Nickname',
  optional: true,
}
export const nameField: FieldMap = {
  name: 'What is your name?',
  children: {
    fname: fnameField,
    middleName: middleNameField,
    lname: lnameField,
    nickname: nicknameField,
  },
}

export const patientField: FieldMap = {
  name: 'Patient',
  children: {
    fname: fnameField,
    lname: lnameField,
    nickname: nicknameField,
    dob: dobField,
    phone: phoneField,
    voicemailOk: voicemailOkField,
    textOk: optIntoTextField,
  },
}

const simpleFnameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'First Name',
}

const simpleLnameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Last Name',
}

const simplePhoneField: PhoneField = {
  ...phoneField, placeholder: 'Phone (optional)', optional: true, labels: undefined, hints: undefined,
}

const simpleDobField: DateField = {
  ...dobField, optional: true, hints: undefined, labels: undefined,
}

const simpleEmailField: EmailField = {
  ...emailField, placeholder: 'Email (optional)', optional: true, hints: undefined, labels: undefined,
}

export const newPatientField: FieldMap = {
  name: 'New Patient',
  children: {
    fname: simpleFnameField,
    lname: simpleLnameField,
    nickname: nicknameField,
    email: simpleEmailField,
    phone: simplePhoneField,
    dob: simpleDobField,
  },
}

export const newPracticePatientField: FieldMap = {
  name: 'New Patient',
  children: {
    fname: simpleFnameField,
    lname: simpleLnameField,
    nickname: nicknameField,
    email: simpleEmailField,
    phone: simplePhoneField,
    dob: simpleDobField,
    messageToAdmins: {
      type: FieldTypes.TEXTAREA,
      placeholder: 'Message to Admins',
    },
  },
}

export const patientDropdownField: FieldMap = {
  name: 'Patient',
  children: {
    fname: fnameField,
    lname: lnameField,
    dob: dobField,
  },
}
