import { InfoIcon } from '@chakra-ui/icons'
import { Flex, Stack, Text } from '@chakra-ui/react'
import {
  FieldMapValue,
  getAdditionalPlansRequested,
  getCoverageConfirmationRequired,
  getMostRecentAssessment,
  newSignOnFields,
  PopulatedAssessment,
  PopulatedAssessmentInterface,
} from '@hb/shared'
import {
  addMetadata,
  ASSESSMENTS_REF,
  BoxHeader,
  ContactButtons,
  DefaultModal,
  FloatingContainer,
  GenericEditModal,
  SolidActionButton,
  useApp,
  useUpdateDoc,
} from '@hb/shared-frontend'
import { ConfirmCoverageModal } from '@hb/shared-frontend/components/Assessments/Coverage/ConfirmCoverageModal'
import { FormsViewContext } from '@hb/shared-frontend/contexts/FormsViewContext'
import { doc } from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { PostSignOnText } from './PostSignOnText'

export const SignOnPanel: React.FC<{
  assessment: PopulatedAssessmentInterface | PopulatedAssessment
  viewSignOnQuestionnaire: boolean
  setViewSignOnQuestionnaire: (view: boolean) => void
}> = ({ viewSignOnQuestionnaire, setViewSignOnQuestionnaire, assessment }) => {
  const update = useUpdateDoc('sign on data')
  const { appName } = useApp()
  const { signOnData, signedOnDate } = assessment
  const { assessmentId, assessments } = useContext(FormsViewContext)

  const hasPreviousSignOn = useMemo(
    () => Object.keys(assessments || {})
      .filter((aId) => aId !== assessmentId)
      .some((key) => assessments?.[key].signedOnDate),
    [assessments, assessmentId],
  )

  const initialValue = useMemo(
    () => signOnData
      || (assessments
        ? getMostRecentAssessment(
          Object.entries(assessments).reduce((acc, [key, data]) => {
            if (data.signOnData) return { ...acc, [key]: data }
            return acc
          }, {}),
        )?.signOnData
        : null),
    [signOnData, assessments],
  )

  const [postSignOnOpen, setPostSignOnOpen] = useState(false)
  const [coverageModalOpen, setCoverageModalOpen] = useState(false)

  const coverageConfirmRequired = useMemo(
    () => getCoverageConfirmationRequired(assessment),
    [assessment],
  )

  const additionalPlansRequested = useMemo(
    () => getAdditionalPlansRequested(assessment),
    [assessment],
  )

  const onSignOnDataSubmit = useCallback(
    (v: FieldMapValue) => update(doc(ASSESSMENTS_REF, assessmentId), '', {
      signOnData: addMetadata(v, appName, !assessment.signOnData),
      signedOnDate: signedOnDate || Date.now(),
    }).then((res) => {
      if (coverageConfirmRequired) {
        setCoverageModalOpen(true)
        return res
      }
      setPostSignOnOpen(true)
      return res
    }),
    [appName, assessmentId, signedOnDate, assessment, update, coverageConfirmRequired],
  )

  const buttonText = useMemo(() => {
    if (!signedOnDate) return 'Complete Sign-on Process'
    if (coverageConfirmRequired) return 'Confirm Coverage'
    return 'Confirm Additional Plans'
  }, [signedOnDate, coverageConfirmRequired])

  return (
    <FloatingContainer alignItems="flex-start">
      {signedOnDate && !coverageConfirmRequired && !additionalPlansRequested ? (
        <PostSignOnText onEditSignOn={() => setViewSignOnQuestionnaire(true)} />
      ) : (
        <>
          <Stack spacing={2} w="100%">
            <BoxHeader color="gray.600">How would you like to proceed?</BoxHeader>
            {assessment.skippedQuestionnaire ? (
              <Text color='gray.600'>
                You've been signed on by one of our administrators! We just need a little info
                before we officially get started.
              </Text>
            ) : null}
            {additionalPlansRequested ? (
              <Flex pb={2} align='center' gap={2}>
                <InfoIcon color='gray.500' />
                <Text lineHeight={1.2} color="gray.600">
                  We need some additional information about your coverage.
                </Text>
              </Flex>
            ) : null}
            {signedOnDate && !coverageConfirmRequired && !additionalPlansRequested ? (
              <Flex flexFlow="column" borderRadius={6} border="1px solid #cdcdcd" w="100%" gap={2}>
                <SolidActionButton
                  variant="solid"
                  flex={1}
                  w={['100%', 'auto']}
                  onClick={() => setViewSignOnQuestionnaire(true)}
                  mt={0}>
                  Edit Sign-on Info
                </SolidActionButton>
              </Flex>
            ) : (
              <SolidActionButton
                variant="solid"
                flex={1}
                size="lg"
                py={3}
                w={['100%', 'auto']}
                onClick={() => {
                  if (coverageConfirmRequired || additionalPlansRequested) setCoverageModalOpen(true)
                  else setViewSignOnQuestionnaire(true)
                }}
                mt={0}>
                {buttonText}
              </SolidActionButton>
            )}
            <ContactButtons
              mr="auto"
              width="100%"
            />
          </Stack>
        </>
      )}
      {viewSignOnQuestionnaire ? (
        <GenericEditModal
          canSubmitClean={hasPreviousSignOn}
          field={newSignOnFields}
          onSubmit={onSignOnDataSubmit}
          data={initialValue || {}}
          isOpen
          onClose={() => setViewSignOnQuestionnaire(false)}
        />
      ) : null}
      {coverageModalOpen ? (
        <ConfirmCoverageModal onClose={() => setCoverageModalOpen(false)} />
      ) : null}
      {postSignOnOpen ? (
        <DefaultModal
          overlayHeader
          bodyProps={{ p: 4 }}
          render={() => (
            <PostSignOnText
              onEditSignOn={() => {
                setPostSignOnOpen(false)
                setViewSignOnQuestionnaire(true)
              }}
            />
          )}
          isOpen={postSignOnOpen}
          onClose={() => setPostSignOnOpen(false)}
        />
      ) : null}
    </FloatingContainer>
  )
}
