import {
  type DollarAmountField,
  type Field,
  type FieldMap,
} from '../../../types/forms/fields'
import { CPTCode, CPTCodeGroup, isCptCodeGroup } from '../../../types/providers'
import { FieldTypes } from '../../FieldTypes'
import { antepartumCodes } from './antepartum'
import { deliveryCodes } from './delivery'
import { gynCptCodes } from './gyn'
import { laborCodes } from './labor'
import { lactationCodes } from './lactation'
import { newbornCodes } from './newborn'
import { otherCodes } from './other'
import { postpartumCodes } from './postpartum'
import { problemCodes } from './problem'

type CptCodeGroupId =
  | 'delivery'
  | 'antepartum'
  | 'postpartum'
  | 'labor'
  | 'newborn'
  | 'lactation'
  | 'gyn'
  | 'problem'
  | 'other'

export const cptCodes: Record<CptCodeGroupId, CPTCodeGroup> = {
  delivery: deliveryCodes,
  antepartum: antepartumCodes,
  postpartum: postpartumCodes,
  labor: laborCodes,
  newborn: newbornCodes,
  lactation: lactationCodes,
  gyn: gynCptCodes,
  problem: problemCodes,
  other: otherCodes,
}

export const allCptCodes = Object.values(cptCodes || {}).reduce((acc, curr) => {
  if (isCptCodeGroup(curr)) return [...acc, ...curr.codes]
  return [...acc, curr]
}, [] as Array<CPTCode>)

export const flattenedCptCodes = Object.entries(cptCodes).reduce(
  (acc, [_, value]) => ({
    ...acc,
    ...value.codes.reduce(
      (groupAcc, curr) => ({ ...groupAcc, [curr.code]: curr }),
      {} as Record<string, CPTCode>,
    ),
  }),
  {} as Record<string, CPTCode>,
)

export const getCodeChargeField = (codeData: CPTCode): DollarAmountField => {
  const { code, description, notes } = codeData || {}
  const field: Field = {
    placeholder: `${code} - ${description}`,
    type: FieldTypes.DOLLAR_AMOUNT,
    optional: true,
  }
  if (notes) field.hints = [{ text: notes }]
  return field
}

export const getChargeCodeFields = (
  codes: Array<CPTCode>,
): Record<string, DollarAmountField> => codes.reduce(
  (acc, curr) => ({
    ...acc,
    [`_${curr.code}`]: getCodeChargeField(curr),
  }),
  {},
)

export const getCptCodeGroupChargeFields = (group: CPTCodeGroup): FieldMap => {
  const { name, codes } = group
  return {
    name: `${name} Codes`,
    children: getChargeCodeFields(codes),
  }
}

export const groupedCptCodeChargeFields = Object.entries(cptCodes).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: getCptCodeGroupChargeFields(value),
  }),
  {} as Record<CptCodeGroupId, FieldMap>,
)

export const codeGroups: Record<string, CptCodeGroupId> = Object.entries(
  cptCodes,
).reduce(
  (acc, [key, value]) => {
    value.codes.forEach((code) => {
      acc[`_${code.code}`] = key as CptCodeGroupId
    })
    return acc
  },
  {} as Record<string, CptCodeGroupId>,
)

export const groupCptCodeCharges = (values: Record<string, number>) => Object.entries(values).reduce(
  (acc, [key, value]) => {
    const groupId = codeGroups[key]
    if (groupId) {
      if (!acc[groupId]) acc[groupId] = {}
      acc[groupId][key] = value
    }
    return acc
  },
    {} as Record<CptCodeGroupId, Record<string, number>>,
)

export const ungroupCptCodeCharges = (
  values: Record<CptCodeGroupId, Record<string, number>>,
) => Object.entries(values).reduce(
  (acc, [_, value]) => {
    Object.entries(value).forEach(([code, charge]) => {
      acc[`_${code}`] = charge
    })
    return acc
  },
    {} as Record<string, number>,
)
