import { Divider, Text, VStack } from '@chakra-ui/react'
import {
  acceptedPaymentMethodsField,
  billedChargesField,
  Field,
  groupCptCodeCharges,
  hospitalField,
  midwifeFields, midwivesCollection, physicianField, PracticeWithAdmin, providerMedicaidIdField,
  renderingProviderField,
  WithId,
} from '@hb/shared'

import React, { useMemo } from 'react'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import { DataView, DataViewList } from '../DataView'
import { DataHeader } from '../DataView/DataHeader'
import { Expandable } from '../Expandable'
import { Editable, EditableRow } from '../forms/Input'
import { ProviderAddressInfo } from './ProviderAddressInfo'
import { ProviderPrmRetainerInfo } from './ProviderPrmRetainerInfo'
import { ReferringProviderInfo } from './ReferringProviderInfo'

export const ProviderOtherInfo = ({
  midwife,
}: {
  midwife?: WithId<PracticeWithAdmin>
}) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  const { billedCharges } = midwife || {}

  const billedChargesValue = useMemo(() => groupCptCodeCharges(billedCharges || {}), [billedCharges])
  return (
    <VStack
      bg='white'
      borderRadius={4}
      border='1px solid #cdcdcd'
      shadow='md'
      spacing={0}
      align='flex-start'
      w='100%'
    >

        <VStack w='100%' align='flex-start' spacing={0}>
          <ReferringProviderInfo midwife={midwife} />
          <Divider />
          <Expandable
            nested
            headerProps={{ bg: 'rgb(245,245,245)' }}
            header={() => (
              <DataHeader>
                <Text py={1}>Billing</Text>
              </DataHeader>
            )}
          >
            <ProviderPrmRetainerInfo midwife={midwife} />
            <Divider />
            <Expandable
            headerProps={{ background: 'rgb(245,245,245)' }}
              header={() => (
                <DataHeader>
                  <Text>Accepted Payment Methods</Text>
                </DataHeader>
              )}
            >
              <Editable
                style={{ padding: '0.3rem 0.5rem', width: '100%' }}
                onSubmit={(v) => updateItem(midwife?.id || '', 'acceptedPaymentMethods', v)}
                value={midwife?.acceptedPaymentMethods}
                field={acceptedPaymentMethodsField}
              />
            </Expandable>
            <Divider />
            <DataView
              initExpanded={false}
              onSubmit={(v) => updateItem(midwife?.id || '', 'billedCharges', v)}
              updateField={(path, v) => updateItem(midwife?.id || '', `billedCharges.${path}`, v)}
              data={billedChargesValue}
              field={billedChargesField}
            />
          </Expandable>
          <Divider />
          <ProviderAddressInfo midwife={midwife} />
          <Divider />
          <DataViewList
            value={midwife?.renderingProviders}
            itemName='Rendering Provider'
            itemField={renderingProviderField}
            onSubmit={(v) => updateItem(midwife?.id || '', 'renderingProviders', v)}
            stackProps={{ background: 'rgb(245,245,245)' }}
          />
          <Divider />
          <DataViewList
            value={midwife?.collaborativePhysicians}
            itemName='Collaborative Physician'
            itemField={physicianField}
            onSubmit={(v) => updateItem(midwife?.id || '', 'collaborativePhysicians', v)}
          />
          <Divider />
          <DataViewList
            stackProps={{ background: 'rgb(245,245,245)' }}
            value={midwife?.affiliatedHospitals}
            itemName='Affiliated Hospital'
            itemField={hospitalField}
            onSubmit={(v) => updateItem(midwife?.id || '', 'affiliatedHospitals', v)}
          />
          <Divider />
          <DataViewList
            itemName='Group Medicaid ID'
            itemField={providerMedicaidIdField}
            value={midwife?.group?.medicaidIds}
            onSubmit={(v) => updateItem(midwife?.id || '', 'group.medicaidIds', v)}
          />
          {/* <AffiliatedHospitalsInfo midwife={midwife} /> */}
          <Divider />
          <EditableRow
            stackProps={{ bg: 'gray.50' }}
            label='Notes'
            onSubmit={(v) => updateItem(midwife?.id || '', 'notes', v)}
            value={midwife?.notes}
            field={midwifeFields.children.notes as Field}
          />
        </VStack>
    </VStack>
  )
}
