import { MIDWIVES } from '../collections/collections/practice'
import { PracticeVisit } from '../types/appointments'
import { toSearchString } from './data'

export const getVisitSearchString = (
  visit: Omit<PracticeVisit, 'searchString' | 'signedPdfStoragePath'>,
) => {
  const {
    patientFirst, patientLast, startTime, endTime, keywords, type,
  } = visit
  const keywordsSearchString = keywords
    ? keywords
      .split(',')
      .map((k) => k.trim())
      .join('|')
    : ''
  return toSearchString(
    `${type}|${patientFirst}|${patientLast}|${startTime}|${endTime}${keywordsSearchString ? `|${keywordsSearchString}` : ''}`,
  )
}

export const getVisitPdfStoragePath = (
  practiceId: string,
  visitId: string,
  visit: PracticeVisit,
) => `${MIDWIVES}/${practiceId}/visits/${visitId}/signed-${new Date(visit.authentication.authenticatedOn).toISOString()}.pdf`
