import {
  Box, Collapse, Flex, Text, Tooltip, VStack,
} from '@chakra-ui/react'
import { midwivesCollection } from '@hb/shared/collections'
import { colors, FieldTypes } from '@hb/shared/constants'
import { notesField } from '@hb/shared/fields'
import {
  CheckboxField,
  DateField,
  TextField,
  UpdateCallback,
} from '@hb/shared/types'
import { getMostRecentAssessment } from '@hb/shared/utils'
import React, { useContext, useMemo } from 'react'
import { Form } from 'react-final-form'
import { ThemeContext, useApp, UserContext } from '../../../../contexts'
import { useCollectionItem } from '../../../../hooks/backend/useCollectionItem'
import { FormElement, SimpleFormFooter } from '../../../forms'
import { DefaultModal } from '../../../Modals/DefaultModal'

const getTitleField = (appName: string): TextField => {
  if (appName === 'app') {
    return {
      type: FieldTypes.TEXT,
      placeholder: 'Assessment name',
    }
  }
  return {
    type: FieldTypes.TEXT,
    placeholder: 'Pregnancy name',
  }
}

const getCopyPreviousDataField = (appName: string): CheckboxField => {
  if (appName === 'app') {
    return {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Copy previous data (Pregnancy and sign-on)',
    }
  }
  return {
    type: FieldTypes.CHECKBOX,
    placeholder: 'Copy previous pregnancy data (update as needed)',
  }
}

const sendInviteField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Email patient invite with link to Hamilton Billing',
}

const defaultValue = {
  copyPreviousData: false,
  sendInvite: false,
  title: '',
}

const newPregnancyEddField: DateField = {
  type: FieldTypes.DATE,
  minDate: 'now',
  placeholder: 'Expected Date of Delivery',
}

type CreateAssessmentArgs = {
  name: string
  sendInvite: boolean
  copyPreviousData: boolean
  notes: string
  edd: string
}

export const AddAssessmentAlert = ({
  isOpen,
  onConfirm,
  onCancel,
}: {
  isOpen: boolean
  onConfirm: (data: CreateAssessmentArgs) => Promise<UpdateCallback>
  onCancel: () => void
}) => {
  const { appName } = useApp()
  const { assessments, user } = useContext(UserContext)
  const { email } = user || {}
  const mostRecentAssessment = useMemo(
    () => getMostRecentAssessment(assessments || {}),
    [assessments],
  )

  const titleField = useMemo(() => getTitleField(appName), [appName])
  const copyPreviousDataField = useMemo(
    () => getCopyPreviousDataField(appName),
    [appName],
  )

  const { item: mostRecentMidwife } = useCollectionItem(
    midwivesCollection,
    mostRecentAssessment?.midwifeId || undefined,
  )

  return (
    <DefaultModal
      isOpen={isOpen}
      size="md"
      overlayHeader
      onClose={onCancel}
      render={() => (
        <VStack w="100%" spacing={0} align="flex-start">
          <Text
            fontFamily="hero-new"
            // fontSize='lg'
            color={colors.green.hex}
            px={4}
            fontWeight={500}
            pt={4}
          >
            NEW PREGNANCY
          </Text>
          <ThemeContext.Provider value={{ theme: 'detailed' }}>
            <Form<CreateAssessmentArgs>
              initialValues={defaultValue}
              onSubmit={(data) => onConfirm(data)}
            >
              {({ values }) => (
                <VStack w="100%" px={4}>
                  <VStack align="flex-start" w="100%" spacing={1}>
                    {appName === 'app' ? (
                      <FormElement name="name" field={titleField} />
                    ) : null}
                    <FormElement name="edd" field={newPregnancyEddField} />
                    <Tooltip
                      bg="orange.600"
                      label={
                        !email ? 'Patient email is required to send an invite' : ''
                      }
                    >
                      <Box>
                        <FormElement
                          disabled={!email}
                          name="sendInvite"
                          field={sendInviteField}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      bg="orange.600"
                      label={
                        !mostRecentAssessment
                          ? 'No previous assessments to copy data from'
                          : ''
                      }
                    >
                      <Box>
                        <FormElement
                          disabled={!mostRecentAssessment}
                          name="copyPreviousData"
                          field={copyPreviousDataField}
                        />
                      </Box>
                    </Tooltip>
                    <FormElement name="notes" field={notesField} />
                    <Collapse
                      in={!!values?.copyPreviousData}
                      style={{
                        display: 'flex',
                        flexFlow: 'column',
                        width: '100%',
                      }}
                    >
                      <Flex gap={1} w="100%" px={2} flexFlow="column">
                        <Text fontFamily="Hero-New" fontSize="sm">
                          The following will be copied from the previous{' '}
                          {appName === 'app' ? 'assessment' : 'pregnancy'}:
                        </Text>
                        <Flex flexFlow="column">
                          {mostRecentMidwife && appName === 'app' ? (
                            <Text
                              fontWeight={500}
                              color="gray.600"
                              fontSize="sm"
                              fontFamily="Open Sans"
                            >
                              • Practice - {mostRecentMidwife?.name || ''}
                            </Text>
                          ) : null}
                          <Text
                            fontWeight={500}
                            color="gray.600"
                            fontSize="sm"
                            fontFamily="Open Sans"
                          >
                            • Insurance Info
                          </Text>
                          <Text
                            fontWeight={500}
                            color="gray.600"
                            fontSize="sm"
                            fontFamily="Open Sans"
                          >
                            {appName === 'app'
                              ? '• Sign On Info (excluding birth-specific info)'
                              : '• Address, Phone, Partner / Policy Info'}
                          </Text>
                        </Flex>
                        <Text fontFamily="Hero-New" fontSize="sm">
                          Please reverify benefits and sign on info
                        </Text>
                      </Flex>
                    </Collapse>
                  </VStack>
                  <SimpleFormFooter hasResetButton submitText="Create Assessment" />
                </VStack>
              )}
            </Form>
          </ThemeContext.Provider>
        </VStack>
      )}
    />
  )
}
