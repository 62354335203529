import {
  Badge, Collapse, Flex, Text,
} from '@chakra-ui/react'
import {
  medicaidProvidersCollection,
  providersCollection,
} from '@hb/shared/collections'
import { FieldTypes } from '@hb/shared/constants'
import {
  DropdownField,
  DropdownOptionItem,
  InsuranceCoverage,
  InsuranceProvider,
  isInsuranceCoverageRequest,
  PopulatedAssessment,
  WithId,
} from '@hb/shared/types'
import {
  capitalizeFirstLetter,
  getCoverageById,
  getMedicaidCoverage,
  getPrimaryCoverage,
  objectToArray,
} from '@hb/shared/utils'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { useCollection } from '../../../../../../collections/hooks/useCollection'
import { useCollectionItem } from '../../../../../../hooks/backend/useCollectionItem'
import { FormElement } from '../../../../../forms'
import { OtherCoverageDetails } from './OtherCoverageDetails'
import { SubmitClaimMainCoverageDetails } from './PrimaryCoverage'

const RenderPlanOption = ({
  option,
  insurancePlans,
  allInsuranceProviders,
}: {
  option: DropdownOptionItem
  insurancePlans: Array<WithId<InsuranceCoverage>>
  allInsuranceProviders: WithId<InsuranceProvider>[]
}) => {
  const plan = insurancePlans.find((p) => p.id === option.id)
  const provider = allInsuranceProviders.find(
    (p) => p.id
      === insurancePlans.find((i) => i.id === option.id)?.insuranceProviderId,
  )
  let badge: string | null = null
  if (option.id === 'primaryCoverage') {
    badge = 'Primary'
  }
  if (option.id === 'medicaidCoverage') {
    badge = 'Medicaid'
  }
  if (plan?.label === 'secondary') {
    badge = 'Secondary'
  }
  return (
    <Flex w="100%" gap={2} pr={2} align="center">
      {provider && (
        <Text fontWeight={600} color="gray.600">
          {provider.name}
        </Text>
      )}
      <Text lineHeight={1} color="gray.500" fontSize="sm">
        {plan?.memberId || 'Missing member ID'}
      </Text>
      {badge ? (
        <Badge ml="auto" colorScheme="green" variant="solid" fontSize="0.7em">
          {badge}
        </Badge>
      ) : null}
    </Flex>
  )
}
export const SelectInsurer = ({
  assessment,
}: {
  assessment: PopulatedAssessment | null
}) => {
  const { mergedData, additionalPlans, authorizations } = assessment || {}
  const { items: allInsuranceProviders } = useCollection(providersCollection)

  const {
    input: { value: mainCoverageId },
  } = useField('mainCoverageId', { subscription: { value: true } })
  const {
    input: { value: otherCoverageId },
  } = useField('otherCoverageId', { subscription: { value: true } })

  const primaryCoverage = useMemo(() => {
    const coverage = getPrimaryCoverage(mergedData)
    if (!coverage) return null
    return {
      ...coverage,
      id: 'primaryCoverage',
    }
  }, [mergedData])

  const medicaidCoverage = useMemo(() => {
    const coverage = getMedicaidCoverage(mergedData)
    if (!coverage) return null
    return {
      ...coverage,
      id: 'medicaidCoverage',
    }
  }, [mergedData])

  const secondaryCoverage = useMemo<WithId<InsuranceCoverage> | null>(() => {
    const match = Object.entries(additionalPlans || {}).find(([, plan]) => {
      if (isInsuranceCoverageRequest(plan)) return false
      if (plan.label === 'secondary') {
        return true
      }
      return false
    })
    return match ? { ...(match[1] as InsuranceCoverage), id: match[0] } : null
  }, [additionalPlans])

  const additionalPlansArr = useMemo(
    () => objectToArray(assessment?.additionalPlans || {}),
    [assessment],
  )

  const insurancePlans = useMemo<Array<WithId<InsuranceCoverage>>>(() => {
    const [, ...otherAdditionalPlans] = additionalPlansArr.filter(
      (s) => !isInsuranceCoverageRequest(s) && s.label !== 'secondary',
    )
    return [
      ...(primaryCoverage
        ? [
            {
              ...primaryCoverage,
              label: 'primary',
              id: 'primaryCoverage',
            } as WithId<InsuranceCoverage>,
        ]
        : []),
      ...(medicaidCoverage
        ? [{ ...medicaidCoverage, id: 'medicaidCoverage' }]
        : []),
      ...(secondaryCoverage ? [secondaryCoverage] : []),
      ...otherAdditionalPlans as WithId<InsuranceCoverage>[],
    ]
  }, [additionalPlansArr, primaryCoverage, secondaryCoverage, medicaidCoverage])

  const mainCoverage = useMemo(
    () => getCoverageById(assessment, mainCoverageId),
    [mainCoverageId, assessment],
  )

  const otherCoverage = useMemo(
    () => getCoverageById(assessment, otherCoverageId),
    [otherCoverageId, assessment],
  )

  const { item: mainInsurer } = useCollectionItem(
    providersCollection,
    mainCoverage?.insuranceProviderId,
  )
  const { item: otherInsurer } = useCollectionItem(
    secondaryCoverage?.isMedicaid
      ? medicaidProvidersCollection
      : providersCollection,
    otherCoverage?.insuranceProviderId,
  )

  const hasMultiplePlans = useMemo(
    () => insurancePlans.length > 1,
    [insurancePlans],
  )

  const field = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      placeholder: 'Using primary insurer',
      options: insurancePlans.map((plan) => ({
        id: plan.id,
        text: `${plan.insuranceProviderId}-${plan.memberId}`,
      })),
      renderOption: (option) => (
        <RenderPlanOption
          option={option}
          insurancePlans={insurancePlans}
          allInsuranceProviders={allInsuranceProviders}
        />
      ),
    }),
    [insurancePlans, allInsuranceProviders],
  )

  const otherPlanField = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      placeholder: secondaryCoverage
        ? 'Using secondary insurer'
        : 'Select plan',
      options: insurancePlans
        .filter((p) => p.id !== mainCoverageId)
        .map((plan) => ({
          id: plan.id,
          text: `${plan.insuranceProviderId}-${plan.memberId}`,
        })),
      optional: true,
      renderOption: (option) => (
        <RenderPlanOption
          option={option}
          insurancePlans={insurancePlans}
          allInsuranceProviders={allInsuranceProviders}
        />
      ),
    }),
    [mainCoverageId, allInsuranceProviders, insurancePlans, secondaryCoverage],
  )

  const authorizationsField = useMemo<DropdownField>(
    () => ({
      placeholder: 'Authorization',
      type: FieldTypes.DROPDOWN,
      optional: true,
      options:
        Object.values(authorizations || {}).map((a) => ({
          text: `${a.referenceNumber} - ${capitalizeFirstLetter(
            a.serviceType,
          )}`,
          id: a.referenceNumber,
        })) || [],
    }),
    [authorizations],
  )

  return (
    <Flex
      border="1px solid #cdcdcd"
      borderRadius={4}
      align="flex-start"
      bg="white"
      boxShadow="md"
      w="100%"
      px={3}
      my={2}
      flexFlow="column"
      py={2}
    >
      <Text py={1} fontWeight={600} color="gray.500" fontSize="md">
        Insurance Plan
      </Text>
      <FormElement field={field} name="mainCoverageId" />
      <Collapse style={{ width: '100%' }} in={!!mainCoverageId}>
        {!mainInsurer?.abilityId ? (
          <Text px={2} color="red.600" fontSize="md">
            Insurance provider is missing Payer ID (Insurers Page)
          </Text>
        ) : (
          <Text px={2}>Payer ID: {mainInsurer?.abilityId || 'None'}</Text>
        )}
      </Collapse>
      <SubmitClaimMainCoverageDetails
        insuranceProvider={mainInsurer}
        coverage={mainCoverage}
        authorizationsField={authorizationsField}
      />
      <Text py={1} fontWeight={600} color="gray.500" fontSize="md">
        Additional Insurance Plan
      </Text>
      {hasMultiplePlans ? (
        <>
          <FormElement name="otherCoverageId" field={otherPlanField} />
          <Collapse style={{ width: '100%' }} in={!!otherCoverageId}>
            {!otherInsurer?.abilityId ? (
              <Text px={2} color="red.600" fontSize="md">
                Insurance provider is missing Payer ID (Insurers Page)
              </Text>
            ) : (
              <Text px={2}>Payer ID: {otherInsurer?.abilityId || 'None'}</Text>
            )}
          </Collapse>
          <OtherCoverageDetails
            insuranceProvider={otherInsurer}
            coverage={otherCoverage}
            authorizationsField={authorizationsField}
          />
        </>
      ) : null}
    </Flex>
  )
}
