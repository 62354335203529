import { Select } from '@chakra-ui/react'
import {
  DropdownField, DropdownOptionItem, InputElement, InputProps, isDropdownOptionItem,
} from '@hb/shared'
import React, {
  ChangeEvent, forwardRef, useCallback, useImperativeHandle, useMemo, useRef,
} from 'react'
import { InputRef } from '../types'

const flattenOptions = (options: DropdownField['options']) => {
  const flatOptions:Array<DropdownOptionItem> = []
  options.forEach((option) => {
    if (isDropdownOptionItem(option)) {
      flatOptions.push(option)
    } else {
      flatOptions.push(...flattenOptions(option.options))
    }
  })
  return flatOptions
}
export const SelectInput: InputElement<DropdownField> = forwardRef<
InputRef,
InputProps<DropdownField>
  >(({
    input, field, meta, style, disabled,
  }, ref) => {
    const {
      placeholder, options,
    } = field
    const { onChange, value, onBlur } = input
    const { touched, error } = meta

    const handleSelect = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
      onChange(e.target.value)
      onBlur()
    }, [onChange, onBlur])

    const inputRef = useRef<HTMLSelectElement>(null)

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) inputRef.current.focus()
      },
      blur: () => {
        if (inputRef.current) inputRef.current.blur()
      },
    }))

    const flattenedOptions = useMemo(() => flattenOptions(options), [options])
    const selectedOption = useMemo(() => flattenedOptions.find((option) => option.text === value)?.id || '', [flattenedOptions, value])

    return (

        <Select
          ref={inputRef}
          placeholder={placeholder}
          bg='white'
          onChange={handleSelect}
          value={selectedOption}
          style={style}
          isDisabled={disabled}
          isInvalid={touched && error}
        >
          {flattenedOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.text}
            </option>
          ))}
        </Select>

    )
  })
