import {
  Button, Flex, Stack, Text, VStack,
} from '@chakra-ui/react'
import {
  CallInRequest,
  colors,
  InsuranceCoverage,
  InsuranceCoverageRequest,
  InsuranceProvider, isInsuranceCoverageRequest,
  LogEntry,
  PopulatedAssessment,
  providersCollection,
  User,
  WithId,
} from '@hb/shared'
import { updateDoc } from 'firebase/firestore'
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { useCollection } from '../../../../collections/hooks/useCollection'
import { useApp } from '../../../../contexts/AppContext'
import { UserContext } from '../../../../contexts/UserContext'
import { Expandable } from '../../../Expandable'
import { ClaimNextAction, NextActionView } from '../NextAction'
import { LogEntryModal, LogEntryView } from './LogEntry'

const getRequestLogItem = (request: InsuranceCoverageRequest): LogEntry => ({
  text: 'Insurance Coverage Request',
  updatedBy: request.requestedBy,
  updatedByGroup: 'admin',
  updatedOn: request.requestedOn,
  color: colors.indigo.hex,
  isInjected: true,
})

const getCallInRequestsLogItem = (request: CallInRequest): LogEntry => ({
  text: 'Call-In Request',
  updatedBy: request.by,
  updatedOn: request.on,
  createdBy: request.by,
  createdOn: request.on,
  updatedByGroup: 'admin',
  color: colors.indigo.hex,
  isInjected: true,
})

const injectLogItems = (
  user: User | null,
  assessment: PopulatedAssessment | null,
  insurers: WithId<InsuranceProvider>[],
) => {
  const now = Date.now()
  const {
    log,
    signedOnDate,
    patientId,
    createdByGroup,
    createdOn,
    createdBy,
    additionalPlans,
    mergedData,
  } = assessment || {}
  const { joinedOn } = user || {}
  const injected = { ...log }
  if (signedOnDate) {
    injected[signedOnDate] = {
      text: 'Patient signed on',
      createdBy: patientId!,
      color: colors.green.hex,
      updatedBy: patientId,
      updatedOn: signedOnDate,
      createdOn: signedOnDate,
      createdByGroup: 'patient',
      isInjected: true,
    }
  }

  const primaryCoverage = mergedData?.['insurance-info']?.primaryCoverage as Partial<InsuranceCoverage> | undefined
  const medicaidCoverage = mergedData?.['insurance-info']?.medicaidCoverage as Partial<InsuranceCoverage> | undefined

  const primaryTerminationDate = primaryCoverage?.terminationDate
  const medicaidTerminationDate = medicaidCoverage?.terminationDate
  if (primaryTerminationDate && primaryTerminationDate < now) {
    injected[primaryTerminationDate] = {
      text: 'Primary insurance coverage terminated',
      updatedBy: patientId!,
      updatedByGroup: 'patient',
      updatedOn: primaryTerminationDate,
      color: colors.red.hex,
      isInjected: true,
    }
  }
  const setAsPrimaryHistory = primaryCoverage?.history?.filter((h) => h.type === 'setAsPrimary') || []
  const lastSetAsPrimary = setAsPrimaryHistory[setAsPrimaryHistory.length - 1]
  if (lastSetAsPrimary) {
    injected[lastSetAsPrimary.on] = {
      text: 'Primary insurance set',
      updatedBy: lastSetAsPrimary.by,
      updatedByGroup: 'admin',
      updatedOn: lastSetAsPrimary.on,
      color: colors.green.hex,
      isInjected: true,
    }
  }

  const medicaidSetAsPrimaryHistory = medicaidCoverage?.history?.filter((h) => h.type === 'setAsPrimary') || []
  const medicaidLastSetAsPrimary = medicaidSetAsPrimaryHistory[medicaidSetAsPrimaryHistory.length - 1]
  if (medicaidLastSetAsPrimary) {
    injected[medicaidLastSetAsPrimary.on] = {
      text: 'Primary insurance set (medicaid)',
      updatedBy: medicaidLastSetAsPrimary.by,
      updatedByGroup: 'admin',
      updatedOn: medicaidLastSetAsPrimary.on,
      color: colors.green.hex,
      isInjected: true,
    }
  }

  if (medicaidTerminationDate && medicaidTerminationDate < now) {
    injected[medicaidTerminationDate] = {
      text: 'Medicaid coverage terminated',
      updatedBy: patientId!,
      updatedByGroup: 'patient',
      updatedOn: medicaidTerminationDate,
      color: colors.red.hex,
      isInjected: true,
    }
  }

  Object.values(additionalPlans || {}).forEach((plan) => {
    if (isInsuranceCoverageRequest(plan)) {
      injected[plan.requestedOn] = getRequestLogItem(plan)
    } else {
      Object.values(plan.callInRequests || {}).forEach((req) => {
        injected[req.on] = getCallInRequestsLogItem(req)
      })
      if (plan.fromRequest) {
        injected[plan.fromRequest.requestedOn] = getRequestLogItem(
          plan.fromRequest,
        )
      }
      if (plan.createdOn) {
        const insurer = insurers.find((i) => i.id === plan.insuranceProviderId)
        injected[plan.createdOn] = {
          text: `Additional Plan Created${insurer ? `\n${insurer.name} | ${plan.memberId || 'No member ID'}` : ''}`,
          createdBy: plan.createdBy,
          updatedByGroup: 'admin',
          updatedOn: plan.createdOn,
          createdOn: plan.createdOn,
          color: colors.green.hex,
          isInjected: true,
        }
      }
      if (plan.terminationDate && plan.terminationDate < now) {
        injected[plan.terminationDate] = {
          text: 'Additional plan terminated',
          updatedBy: patientId!,
          updatedByGroup: 'patient',
          updatedOn: plan.terminationDate,
          color: colors.red.hex,
          isInjected: true,
        }
      }
    }
  })

  if (joinedOn) {
    injected[joinedOn] = {
      text: 'User joined (first sign in)',
      updatedBy: patientId!,
      updatedByGroup: 'patient',
      updatedOn: joinedOn,
      color: colors.green.hex,
      isInjected: true,
    }
  }
  if (createdOn && createdOn !== signedOnDate) {
    injected[createdOn] = {
      text: 'Assessment created',
      updatedBy: createdBy,
      createdBy,
      updatedByGroup: createdByGroup || 'patient',
      updatedOn: createdOn,
      color: colors.green.hex,
      isInjected: true,
    }
  }
  if (assessment?.submittedOn) {
    injected[assessment.submittedOn] = {
      text: 'Questionnaire submitted',
      updatedByGroup: assessment.submittedByGroup || 'patient',
      updatedBy: assessment.submittedBy || 'none',
      updatedOn: assessment.submittedOn,
      color: colors.indigo.hex,
      isInjected: true,
    }
  }
  if (assessment?.correctionsUpdatedBy && assessment.correctionsUpdatedOn) {
    injected[assessment.correctionsUpdatedOn] = {
      text: 'Answers corrected',
      updatedBy: assessment.correctionsUpdatedBy,
      updatedByGroup: 'admin',
      updatedOn: assessment.correctionsUpdatedOn,
      color: colors.indigo.hex,
      isInjected: true,
    }
  }
  if (assessment?.draftSavedOn && assessment.draftSavedBy) {
    injected[assessment.draftSavedOn] = {
      text: 'Assessment draft saved',
      updatedBy: assessment.draftSavedBy,
      updatedByGroup: 'admin',
      updatedOn: assessment.draftSavedOn,
      color: colors.indigo.hex,
      isInjected: true,
    }
  }

  if (assessment?.previousResults && assessment.previousResults.length > 0) {
    assessment.previousResults.forEach((res) => {
      if (res.sentOn && res.sentBy) {
        injected[res.sentOn] = {
          text: 'Previous assessment sent',
          updatedBy: res.sentBy,
          updatedByGroup: 'admin',
          updatedOn: res.sentOn,
          color: colors.indigo.hex,
          isInjected: true,
        }
      }
    })
  }

  if (assessment?.sentBy && assessment.sentOn) {
    injected[assessment.sentOn] = {
      text: 'Assessment sent',
      updatedBy: assessment.sentBy,
      updatedByGroup: 'admin',
      updatedOn: assessment.sentOn,
      color: colors.indigo.hex,
      isInjected: true,
    }
  }
  if (assessment?.resultsViewedOn) {
    injected[assessment.resultsViewedOn] = {
      text: 'Assessment viewed',
      updatedBy: assessment.patientId || '',
      updatedByGroup: 'patient',
      updatedOn: assessment.resultsViewedOn,
      color: colors.green.hex,
      isInjected: true,
    }
  }
  return injected
}

export const AssessmentLog: React.FC<{
  maxHeight: number
  width: number
  // setWidth: (w: number) => void
}> = ({ maxHeight, width }) => {
  const data = useContext(UserContext)
  const {
    user,
    selectedAssessment,
    assessmentId,
    claims: { data: claims },
  } = data
  const { appName } = useApp()
  // const [width, setWidth] = useState(defaultWidth)
  const [selectedLogId, setSelectedLogId] = useState<number | undefined>(
    undefined,
  )

  const { items: insurers } = useCollection(providersCollection)
  const { adminRef } = selectedAssessment || {}
  const injectedLog = useMemo(
    () => injectLogItems(user, selectedAssessment || null, insurers),
    [user, selectedAssessment, insurers],
  )

  const sortedKeys = useMemo(
    () => (injectedLog
      ? Object.keys(injectedLog)
        .map((k) => parseInt(k, 10))
        .sort((a, b) => b - a)
      : []),
    [injectedLog],
  )
  const hasClaims = useMemo(
    () => Object.keys(claims || {}).length > 0,
    [claims],
  )

  const handleUpdateLog = useCallback(
    async (id: string, newEntry: LogEntry) => (adminRef
      ? updateDoc(adminRef, `log.${id}`, newEntry)
        .then(() => ({ success: 'Updated!' }))
        .catch((err: any) => {
          console.error(err)
          return { error: err.message }
        })
      : { error: 'No adminRef' }),
    [adminRef],
  )

  return (
    <Flex
      background="white"
      flexGrow={1}
      overflow="hidden"
      width={`${width}px`}
      maxH={maxHeight}
      direction="column"
    >
      {appName === 'app' ? (
        <Flex
          // px={2}
          width="100%"
          align="center"
          direction="column"
          borderBottom="1px solid #dedede"
        >
          <NextActionView
            collection="assessments-admin"
            id={assessmentId}
            py={1}
            borderTop="1px solid #cdcdcd"
            item={selectedAssessment}
          />
          {hasClaims ? (
            <Expandable
              style={{ width: '100%' }}
              initExpanded
              header={() => (
                <Text fontWeight={600} color="#777" w="100%" px={2} py={1}>
                  Claim Next Actions{hasClaims ? '' : ' (No claims)'}
                </Text>
              )}
            >
              <VStack spacing={0} w="100%">
                {Object.entries(claims || {}).map(([claimId, claim]) => (
                  <ClaimNextAction
                    key={claimId}
                    claim={claim}
                    claimId={claimId}
                    w="100%"
                    py={1}
                    px={1}
                    borderTop="1px solid #cdcdcd"
                  />
                ))}
              </VStack>
            </Expandable>
          ) : null}
        </Flex>
      ) : null}
      <Flex borderBottom="1px solid #cdcdcd" px={1} w="100%" align="center">
        <Text py={1} pl={2} color="#777" fontWeight="600">
          Assessment Log
        </Text>
        {appName === 'app' ? (
          <Flex ml="auto">
            <Button
              size="xs"
              variant="outline"
              onClick={() => setSelectedLogId(-1)}
            >
              + New Entry
            </Button>
            <LogEntryModal
              isOpen={selectedLogId === -1}
              onClose={() => setSelectedLogId(undefined)}
              placement="left"
              createdOn={selectedLogId}
              onSubmit={(newEntry) => handleUpdateLog(`${Date.now()}`, newEntry)
              }
            />
          </Flex>
        ) : null}
      </Flex>
      <Flex
        direction="column"
        overflowY="auto"
        flexGrow={1}
        width="100%"
        // maxH='100%'
      >
        <Stack spacing={0} w="100%">
          {injectedLog ? (
            sortedKeys.map((e, i) => (
              <LogEntryView
                placement="left"
                update={
                  injectedLog[e]?.isInjected
                    ? undefined
                    : (updated) => handleUpdateLog(`${e}`, updated)
                }
                index={i}
                key={e}
                select={() => setSelectedLogId(e)}
                deselect={() => setSelectedLogId(undefined)}
                selected={selectedLogId === e}
                {...injectedLog[e]}
                createdOn={e}
              />
            ))
          ) : (
            <Text p={2} pl={4} pr={4} color="#777">
              <i>No log entries yet</i>
            </Text>
          )}
        </Stack>
      </Flex>
    </Flex>
  )
}
