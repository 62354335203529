import {
  DocData,
  GetCollectionArgs,
  toSearchString,
  UseCollectionArgs,
  UserRoleItem,
  UserRowItem,
  WithId,
} from '@hb/shared'

import {
  collection as getCollection,
  CollectionReference,
  doc,
  DocumentSnapshot,
  getDocs,
  limit as getLimit,
  onSnapshot,
  orderBy,
  query as getQuery,
  Query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react'
import { db } from '../../backend/db'
import { USERS_ADMIN_REF, USERS_REF, USER_INVITES_REF } from '../../collections/collections'
import { PopUpMessageContext } from '../../contexts'

import { getQueryCount } from './data'
import { useUpdateDoc } from './useUpdateDoc'

export const useAdminRef = (id: string) => useMemo(() => doc(USERS_ADMIN_REF, id), [id])
export const useUserRef = (id: string) => useMemo(() => doc(USERS_REF, id), [id])
export const useInviteRef = (id: string) => useMemo(() => doc(USER_INVITES_REF, id), [id])

export const useUserRowItem = (user: UserRoleItem): UserRowItem => {
  const { isInvite, id } = user
  const adminRef = useAdminRef(id)
  const inviteRef = useInviteRef(id)
  const userRef = useUserRef(id)
  const update = useUpdateDoc()

  return {
    user,
    ref: isInvite ? userRef : inviteRef,
    update,
    adminRef,
  }
}

const toSearchSet = (query?: string) => new Set((query || '').split(/\|| /g).map(toSearchString))
const isInSearch = <T extends { stringified?: string }>(
  item: T,
  searchSet: Set<string>,
) => {
  const itemSet = toSearchSet(item.stringified)
  return Array.from(searchSet).reduce(
    (acc, curr) => acc && itemSet.has(curr),
    true,
  )
}

const matchesQuery = <T extends DocData = DocData>(
  item: T,
  searchStringPath: keyof T,
  query: string | undefined,
  searchSet: Set<string>,
) => {
  if (
    toSearchString(item[searchStringPath] || '').includes(toSearchString(query || ''))
  ) {
    return true
  }
  return isInSearch(item, searchSet)
}

function chunkMaxLength<T>(
  arr: Array<T>,
  chunkSize: number,
  maxLength: number,
) {
  return Array.from({ length: maxLength }, () => arr.splice(0, chunkSize))
}

export const useSortedCollection = <
  T extends WithId,
  SortKey extends string
>(
    args: UseCollectionArgs<T, SortKey>,
    limit: number = 100,
  ): {
  items: Record<string, T> | null
  goNext: () => void
  loading: boolean
  goPrev: () => void
  goToPage: (index: number) => Promise<void>
  canGoNext: boolean
  queryCount: number | null
  totalPages: number
  searchStringPath?: keyof T,
  pageNum: number
  canGoPrev: boolean
} => {
  const [fetched, setFetched] = useState<Array<
    T & {
      doc: DocumentSnapshot
    }
  > | null>(null)

  const {
    sortKey,
    sortFunc,
    sortDesc,
    stage,
    pause,
    query,
    transformData,
    collection,
    role,
    archived,
    searchStringPath,
    filters,
    secondarySortKey,
  } = args

  const [cursors, setCursors] = useState<Array<DocumentSnapshot | null>>([])
  const [listItemCount, setListItemCount] = useState<number | null>(null)
  useEffect(() => {
    if (query) {
      setCursors([])
      return
    }
    const countArgs: GetCollectionArgs<string> = {
      sortKey,
      role,
      collection,
      sortDesc,
      filters,
      stage,
    }
    if (archived !== undefined) countArgs.archived = archived
    setListItemCount(null)
    getQueryCount(countArgs)
      .then((res) => {
        setListItemCount(res.data || null)
      })
      .catch((err) => {
        console.error(err)
      })
    setCursors([])
  }, [role, sortKey, sortDesc, stage, query, archived, collection, filters])
  const [lastFetched, setLastFetched] = useState<Array<QueryDocumentSnapshot>>(
    [],
  )
  const [loading, setLoading] = useState(false)

  const { processResponse } = useContext(PopUpMessageContext)
  const unpaginatedQueryRef = useMemo(() => {
    if (limit < 1) return null
    const ref = getCollection(db, collection) as CollectionReference<T>
    let q: Query<T> = getQuery(ref)
    if (archived !== undefined) { q = getQuery(q, where('archived', '==', archived)) }
    filters?.forEach((filter) => {
      q = getQuery(q, where(...filter))
      if (filter[1] === '!=' && sortKey !== filter[0]) q = getQuery(q, orderBy(filter[0], 'desc'))
    })
    if (!sortFunc) {
      q = getQuery(q, orderBy(sortKey, sortDesc ? 'desc' : 'asc'))
    }

    if (
      !sortFunc
      && secondarySortKey
      && sortKey !== secondarySortKey
    ) {
      q = getQuery(q, orderBy(secondarySortKey, 'asc'))
    }
    if (stage) q = getQuery(q, where('stages', 'array-contains', stage))
    if (role && !archived) {
      if (typeof role === 'string') q = getQuery(q, where('role', '==', role))
      else q = getQuery(q, where('role', 'in', role))
    }
    return q
  }, [
    limit,
    collection,
    secondarySortKey,
    archived,
    sortKey,
    sortDesc,
    stage,
    role,
    sortFunc,
    filters,
  ])
  const queryRef = useMemo(() => {
    if (!unpaginatedQueryRef) return null
    let q = getQuery(unpaginatedQueryRef)
    const lastCursor = cursors[cursors.length - 1]
    if (!query && lastCursor) q = getQuery(q, startAfter(lastCursor), getLimit(limit))
    if (!query) q = getQuery(q, getLimit(limit))

    return q
  }, [unpaginatedQueryRef, cursors, query, limit])

  const subscribe = useCallback(() => {
    if (!queryRef) return () => {}
    setLoading(true)
    setFetched(null)
    return onSnapshot(
      queryRef,
      (s) => {
        setLoading(false)
        setLastFetched(s.docs)
        setFetched(
          s.docs.map((fetchedDoc) => ({
            ...fetchedDoc.data(),
            id: fetchedDoc.id,
            doc: fetchedDoc,
          })),
        )
      },
      (e) => {
        const containsUrl = e?.message?.includes('http')
        const parsedUrl = containsUrl ? e?.message?.split(' ').find((s) => s.includes('http')) : ''
        if (parsedUrl && e.message?.startsWith('The query requires an index. You can create it here:')) {
          window.open(parsedUrl, '_blank')
          window.focus()
        }
        processResponse({ error: e?.message || 'Error fetching data' })
        console.error({ collection, filters })
        console.error(e)
      },
    )
  }, [queryRef, processResponse, collection, filters])

  useEffect(() => {
    // set(null)
    if (pause) {
      return () => {}
    }
    return subscribe()
  }, [pause, subscribe])

  const goNext = useCallback(() => {
    if (!fetched) return
    const lastRef = fetched?.[fetched.length - 1]?.doc
    if (lastRef) setCursors((s) => [...s, lastRef])
  }, [fetched])

  const goPrev = () => setCursors((c) => c.slice(0, c.length - 1))

  const searchSet = useMemo(
    () => (query ? new Set(query.split(' ').map(toSearchString)) : null),
    [query],
  )
  const canGoPrev = useMemo(() => !!cursors.length, [cursors])
  const pageNum = useMemo(() => cursors.length + 1, [cursors])
  const { items, queryCount } = useMemo(() => {
    if (!fetched) {
      return { items: null, queryCount: null }
    }
    let qCount = listItemCount
    let sorted = transformData ? transformData(fetched) : [...fetched]
    if (sortFunc) {
      sorted = sorted.sort((a, b) => sortFunc(sortDesc ? b : a, sortDesc ? a : b))
    }
    if (searchSet && searchStringPath) {
      sorted = sorted.filter((item) => matchesQuery<T>(item, searchStringPath, query, searchSet))
    }
    if (query) {
      qCount = sorted.length - 1
      sorted = sorted.slice(
        cursors.length * limit,
        (cursors.length + 1) * limit,
      )
    }
    return {
      items: sorted.reduce(
        (acc, currItem) => ({
          ...acc,
          [currItem.id]: currItem,
        }),
        {},
      ),
      queryCount: qCount,
    }
  }, [
    fetched,
    searchSet,
    searchStringPath,
    sortFunc,
    query,
    transformData,
    sortDesc,
    cursors,
    limit,
    listItemCount,
  ])

  const totalPages = useMemo(
    () => Math.max(1, Math.ceil((queryCount || 0) / limit)),
    [limit, queryCount],
  )
  const canGoNext = useMemo(() => pageNum < totalPages, [pageNum, totalPages])
  const goToPage = useCallback(
    async (index: number): Promise<void> => {
      if (
        !unpaginatedQueryRef
        || index < 0
        || index >= totalPages
        || index === cursors.length
      ) {
        return
      }
      if (index === 0) setCursors([])
      else if (index > cursors.length) {
        if (query) {
          setCursors((c) => [...c, null])
        } else {
          // get new cursors
          const lastFetchedDoc = lastFetched[lastFetched.length - 1]
          const limitTo = limit * (index - cursors.length + (lastFetchedDoc ? -1 : 0))
          if (limitTo < 1) {
            if (lastFetchedDoc) setCursors((c) => [...c, lastFetchedDoc])
            return
          }
          let q = getQuery(unpaginatedQueryRef)
          if (lastFetchedDoc) { q = getQuery(q, startAfter(lastFetchedDoc), getLimit(limitTo)) }
          getDocs(q).then((res) => {
            const grouped = chunkMaxLength(
              res.docs,
              limit,
              Math.ceil(res.docs.length / limit),
            )
            const lastOfEachGroup = grouped
              .map((g) => g[g.length - 1])
              .filter((g) => !!g)
            setCursors((c) => [...c, lastFetchedDoc, ...lastOfEachGroup])
          })
        }
      } else {
        setCursors((c) => c.slice(0, index))
      }
    },
    [totalPages, cursors, unpaginatedQueryRef, limit, lastFetched, query],
  )
  return {
    items,
    loading,
    goPrev,
    queryCount,
    totalPages,
    goNext,
    canGoNext,
    canGoPrev,
    goToPage,
    pageNum,
  }
}
