import {
  assessmentFields, FieldMapValue, FormData, FormStatus,
} from '@hb/shared'
import { get as nestedGet } from 'nested-property'
import { useMemo } from 'react'
import { getFormStatus } from './utils'

export const useAssessmentForm = (formData: FieldMapValue | undefined, id: string | null | undefined): FormData => {
  const form = useMemo(() => {
    const f = id ? assessmentFields[id] : null
    if (typeof f === 'string') return null
    return f
  }, [id])
  const data = useMemo(
    () => {
      if (!form) return undefined
      return form.path
        ? (nestedGet(formData, form.path) as FieldMapValue | undefined)
        : formData
    },
    [form, formData],
  )

  const status = useMemo<FormStatus>(
    () => getFormStatus(form, data),
    [data, form],
  )

  return { form, status: status as FormStatus }
}
