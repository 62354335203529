import { FieldTypes } from '../../constants/FieldTypes'
import { FieldMap } from '../../types/forms/fields'
import { createFileField } from '../../utils/fields'

export const insuranceCardField: FieldMap = {
  name: 'Insurance Card',
  initExpanded: true,
  condition: (v) => !v?.noMemberId,
  // labels: [{text: 'Please upload images of your insurance card'}],
  children: {
    front: createFileField({
      placeholder: 'Front of Card',
      // fileType: 'image',
      name: 'insurance-card-front',
    }),
    frontImageIncludesBack: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Back of card included in previous image',
    },
    back: createFileField({
      placeholder: 'Back of Card',
      // fileType: 'image',
      name: 'insurance-card-back',
      condition: (v) => !v?.frontImageIncludesBack,
    }),
  },
}
