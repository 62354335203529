import { FieldMapValue, Form } from '@hb/shared/types'
import { ValidationErrors } from 'final-form'
import React from 'react'
import { FormWizardContext } from './context'
import { FormPreview } from './FormPreview'
import { useFormWizardData } from './hooks'
import { StageModal } from './StageModal'

export const FormWizard = ({
  form,
  data,
  onSubmit,
  stageId,
  selectStage,
  baseStoragePath,
  withPreview,
  readOnly,
  ReadOnlyFooter,
}: {
  form: Form
  data: FieldMapValue | undefined
  onSubmit: (submitted: FieldMapValue) => Promise<ValidationErrors>
  stageId?: string | null
  selectStage?: (selected: string | null) => void
  baseStoragePath?: string
  withPreview?: boolean
  readOnly?: boolean
  ReadOnlyFooter?: React.FC
}) => {
  const formWizardData = useFormWizardData(form, data, onSubmit, stageId, selectStage, readOnly, ReadOnlyFooter, baseStoragePath)
  const { stage } = formWizardData
  return (
    <FormWizardContext.Provider value={formWizardData}>
      {stage ? <StageModal /> : null}
      {withPreview ? <FormPreview onStageEdit={formWizardData.open} form={form} data={data} /> : null}
    </FormWizardContext.Provider>
  )
}
