import {
  Box,
  Flex, IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared/constants'
import { ThreadMessage } from '@hb/shared/types'
import { getDateTimeString } from '@hb/shared/utils'
import React, { useContext, useMemo } from 'react'
import read1Icon from '../../../icons/read-1.svg'
import read2Icon from '../../../icons/read-2.svg'
import { SvgIcon } from '../../../icons/SvgIcon'
import { UserPreview } from '../../Users/UserPreview'
import { ThreadViewContext } from './contexts'

const ReadByUserPreview = ({
  userId,
  readOn,
}: {
  userId: string
  readOn?: number
}) => (
  <Flex align='center' pr={3} w="100%">
    <Box position="relative" minW="0" flex={1}>
      <UserPreview
        noEmail
        noWrap
        withProfilePicture
        size="sm"
        key={userId}
        userId={userId}
      />
    </Box>
    {readOn ? (
      <Text ml='auto' fontSize="xs" fontWeight={600} color="gray.600">
        {getDateTimeString(readOn, 'short')}
      </Text>
    ) : null}
  </Flex>
)

export const ReadByPopover = ({ message }: { message: ThreadMessage }) => {
  const {
    readBy, editedOn, editedReadBy, createdBy, readOn,
  } = message || {}

  const { thread } = useContext(ThreadViewContext)
  const { subscribers = [] } = thread || {}
  // const relevantReadBy = useMemo(
  //   () => (readBy || []).filter((s) => s !== senderId),
  //   [readBy, senderId],
  // )
  // const readByAll = useMemo(
  //   () => readBy?.length === subscribers?.length,
  //   [readBy, subscribers],
  // )

  const readByAll = useMemo(
    () => !!readBy && readBy.length === subscribers.length - 1,
    [readBy, subscribers],
  )

  const usedReadBy = useMemo(
    () => (editedOn ? editedReadBy : readBy),
    [editedOn, editedReadBy, readBy],
  )

  const editsReadByAll = useMemo(
    () => !!editedReadBy && editedReadBy.length === subscribers.length - 1,
    [editedReadBy, subscribers],
  )

  const unreadBy = useMemo(
    () => subscribers.filter((s) => !(readBy || []).includes(s) && s !== createdBy),
    [readBy, subscribers, createdBy],
  )

  const editsUnreadBy = useMemo(
    () => subscribers.filter(
      (s) => !unreadBy.includes(s)
          && !(editedReadBy || []).includes(s)
          && s !== createdBy,
    ),
    [subscribers, createdBy, editedReadBy, unreadBy],
  )

  return (
    <Popover trigger="hover" placement="bottom" strategy="fixed" isLazy>
      <PopoverTrigger>
        <IconButton
          aria-label="Read By"
          variant="unstyled"
          w="18px"
          h="auto"
          minW="0"
          minH="0"
          color="gray.500"
          p={0}
          _hover={{
            color: 'gray.600',
          }}
          icon={
            <div
              style={{
                overflow: 'hidden',
                position: 'relative',
                width: '20px',
                minWidth: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SvgIcon
                filter={`grayscale(${readByAll ? 0 : 1})`}
                src={read1Icon}
                w="20px"
                h="auto"
                color="gray.500"
              />
              {editedOn ? (
                <SvgIcon
                  src={read2Icon}
                  filter={`grayscale(${editsReadByAll ? 0 : 1})`}
                  position="absolute"
                  w="20px"
                  left={0}
                  h="auto"
                  color="gray.500"
                />
              ) : null}
            </div>
          }
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          bg="gray.50"
          borderRadius="12px"
          overflow="hidden"
          py={2}
          px={2}
          border="none"
          filter="drop-shadow(1px 1px 4px #00000066)"
          w="300px"
        >
          <VStack spacing={0} w="100%">
            {usedReadBy && usedReadBy.length ? (
              <>
                <Text
                  textAlign="center"
                  pt={1}
                  w="100%"
                  lineHeight={1}
                  fontSize="xs"
                  fontWeight={600}
                  color={colors.blue.hex}
                >
                  READ BY
                </Text>
                {usedReadBy.map((userId) => (
                  <ReadByUserPreview
                    readOn={readOn?.[userId]}
                    key={userId}
                    userId={userId}
                  />
                ))}
              </>
            ) : null}
            {/* {editedOn && editedReadBy && editedReadBy.length ? (
              <>
                <Text
                  textAlign="center"
                  pt={1}
                  w="100%"
                  lineHeight={1}
                  fontSize="xs"
                  fontWeight={600}
                  color={colors.blue.hex}
                >
                  EDITS READ BY
                </Text>
                {editedReadBy.map((userId) => (
                  <ReadByUserPreview key={userId} userId={userId} />
                ))}
              </>
            ) : null} */}
            {readByAll ? null : (
              <>
                <Text
                  lineHeight={1}
                  textAlign="center"
                  w="100%"
                  pt={2}
                  mt={2}
                  borderTop="1px solid #cdcdcd"
                  fontSize="xs"
                  fontWeight={600}
                  color="gray.600"
                >
                  DELIVERED TO
                </Text>
                {unreadBy ? (
                  unreadBy.map((userId) => (
                    <ReadByUserPreview key={userId} userId={userId} />
                  ))
                ) : (
                  <Text fontSize="sm" color="gray.500">
                    Unavailable for older messages
                  </Text>
                )}
              </>
            )}
            {!editedOn || !editsUnreadBy.length ? null : (
              <>
                <Text
                  lineHeight={1}
                  textAlign="center"
                  w="100%"
                  borderTop="1px solid #cdcdcd"
                  pt={2}
                  mt={2}
                  fontSize="xs"
                  fontWeight={600}
                  color="gray.600"
                >
                  EDITS UNREAD BY
                </Text>
                {editsUnreadBy ? (
                  editsUnreadBy.map((userId) => (
                    <ReadByUserPreview key={userId} userId={userId} />
                  ))
                ) : (
                  <Text fontSize="sm" color="gray.500">
                    Unavailable for older messages
                  </Text>
                )}
              </>
            )}
          </VStack>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
