import { USER_ROLES } from '@hb/shared/collections'
import { UserRoleItem } from '@hb/shared/types'
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
} from 'firebase/firestore'
import { deleteToken, getToken } from 'firebase/messaging'
import { UAParser } from 'ua-parser-js'
import {
  db, messaging, registerWebPushNotificationToken, unregisterWebPushNotificationToken, VAPID_KEY,
} from '../backend'
import { useAuth } from '../store'
import { getHBDeviceId } from './localStorage'

const TOKEN_EXPIRATION = 1000 * 60 * 60 * 24 * 30 // 30 days

export const getPushNotificationToken = async (): Promise<string | null> => {
  const { authUser } = useAuth.getState()
  const uid = authUser?.uid
  if (!uid) {
    throw new Error('User not authenticated')
  }

  const roleItemDoc = await getDoc(
    doc(collection(db, USER_ROLES), uid) as DocumentReference<UserRoleItem>,
  )
  const { webPushNotificationsToken } = roleItemDoc.data() || {}
  if (
    webPushNotificationsToken
    && webPushNotificationsToken.timestamp + TOKEN_EXPIRATION < Date.now()
  ) {
    return createNewPushNotificationToken()
  }
  return webPushNotificationsToken?.token || null
}

export const createNewPushNotificationToken = async () => {
  const parser = new UAParser()
  const result = parser.getResult()
  const newToken = await getToken(messaging, { vapidKey: VAPID_KEY })
  await registerWebPushNotificationToken({
    token: newToken,
    device: {
      browser: result.browser.name || '',
      model: result.device.model || '',
      type: result.device.type || '',
      hbDeviceId: getHBDeviceId(),
      vendor: result.device.vendor || '',
      os: result.os.name || '',
    },
  })
  return newToken
}

export const requestPushNotificationPermission = async () => Notification.requestPermission()

export const assertPushNotificationToken = async () => {
  const notificationToken = await getPushNotificationToken()
  if (!notificationToken) {
    const permission = await requestPushNotificationPermission()
    if (permission === 'granted') {
      await createNewPushNotificationToken()
      return true
    }
    return false
  }
  return true
}

export const updatePushNotificationDevice = async () => {
  try {
    await deleteToken(messaging)
  } catch (e) {
    console.error('Failed to delete token:', e)
  }
  return createNewPushNotificationToken()
}

export const enableWebPushNotifications = async () => {
  await createNewPushNotificationToken()
}

export const unregisterPushNotifications = async () => {
  const { authUser } = useAuth.getState()
  const uid = authUser?.uid
  if (!uid) {
    throw new Error('User not authenticated')
  }
  try {
    await deleteToken(messaging)
  } catch (err: any) {
    console.error(err)
  }
  await unregisterWebPushNotificationToken()
}
