import { TimeIcon } from '@chakra-ui/icons'
import {
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import {
  BaseInsuranceCoverage,
  InsuranceCoverageHistory,
  InsuranceCoverageUpdateType,
} from '@hb/shared/types'
import { getDateString, sortByUnixDate } from '@hb/shared/utils'
import React, { useMemo } from 'react'
import { ActionLog } from '../../ActionLog'

const historyTypeLabels: Record<InsuranceCoverageUpdateType | 'termination', string> = {
  basicInfo: 'Basic Info Updated',
  callIn: 'Call-in info Updated',
  policyOwner: 'Policy owner updated',
  setAsPrimary: 'Set as primary',
  unsetAsPrimary: 'Replaced as primary',
  termination: 'Terminated',
}

type InsuranceCoverageHistoryWithTermination = Omit<
  InsuranceCoverageHistory,
  'type'
> & {
  type: InsuranceCoverageUpdateType | 'termination'
}
export const CoverageHistory = ({
  coverage,
}: {
  coverage: BaseInsuranceCoverage
}) => {
  const { history, terminationDate } = coverage

  // include termination if past termination date
  const withTermination = useMemo<InsuranceCoverageHistoryWithTermination[]>(() => {
    if (!terminationDate) return history || []
    if (terminationDate > Date.now()) return history || []
    const termination = {
      on: terminationDate,
      type: 'termination' as const,
      by: 'System',
    }
    return [...(history || []), termination]
  }, [terminationDate, history])

  const uniqueMinuteHistory = useMemo(() => {
    const uniqueMinutes = new Set<string>()
    return sortByUnixDate(withTermination || [], 'on')
      .reverse()
      .filter(({ on, type }) => {
        const dateString = getDateString(on, 'short')
        const key = `${type}-${dateString}`
        if (uniqueMinutes.has(key)) return false
        uniqueMinutes.add(key)
        return true
      })
  }, [withTermination])

  return (
    <Popover strategy="fixed" trigger="hover">
      <PopoverTrigger>
        <IconButton
          borderRadius="full"
          _hover={{ bg: 'whiteAlpha.300' }}
          aria-label="Coverage History"
          icon={
            <TimeIcon
              filter="drop-shadow(1px 1px 2px #00000066)"
              width={4}
              height={4}
            />
          }
          size="xs"
          color="white"
          variant="ghost"
        />
      </PopoverTrigger>
      <PopoverContent w="auto" p={3}>
        {uniqueMinuteHistory.length ? (
          <Flex flexFlow="column" gap={1}>
            {uniqueMinuteHistory.map(({ by, on, type }, i) => (
              <ActionLog
                key={i}
                style={{ fontSize: 'md', gap: 5 }}
                by={by}
                on={on}
                action={historyTypeLabels[type]}
              />
            ))}
          </Flex>
        ) : (
          <Text fontStyle="italic">No history available</Text>
        )}
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  )
}
