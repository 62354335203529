import {
  FieldMap, FieldMapValue, Form, InfoStage, OnUploadProgress,
} from '@hb/shared/types'
import { FormApi, ValidationErrors } from 'final-form'
import { createContext, FC } from 'react'

export type FormWizardContextData = {
  stage: FieldMap | InfoStage | null
  stageId: string | null
  open: (stageId: string) => void
  form: Form,
  close: () => void
  onSubmitStage: (
    data: FieldMapValue | undefined,
    onUploadProgress: OnUploadProgress,
    form?: FormApi
  ) => Promise<ValidationErrors>
  data?: FieldMapValue
  readOnly?: boolean
  ReadOnlyFooter?: FC
  nextStage: string | null
}

export const FormWizardContext = createContext<FormWizardContextData>({
  open: () => {},
  close: () => {},
  form: {
    name: 'ERROR: Outside of form wizard context',
    description: 'ERROR: Outside of form wizard context',
    stages: {},
  },
  stage: null,
  stageId: null,
  onSubmitStage: async () => ({ error: 'No id selected' }),
  nextStage: null,
  data: undefined,
})
