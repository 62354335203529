import {
  Collection, CollectionId,
  CollectionItem, DocData, getBaseCollection,
  getCollectionId,
  LocalCollectionState,
} from '@hb/shared/collections'
import { UpdateCallback } from '@hb/shared/types'
import {
  doc,
  DocumentData, writeBatch,
} from 'firebase/firestore'
import cloneDeep from 'lodash.clonedeep'
import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { db } from '../../backend/db'
import { reorder } from '../../components/utils/reorder'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { useAuth } from '../../store'
import { useCollections } from '../../store/collections'
import { getCollectionRef } from '../utils'
import { subscribeToCollection } from '../utils/read'

export const useCollection = <Data extends DocData>(
  collection: Collection<Data> | undefined | null,
  paused?: boolean,
): LocalCollectionState<Data> => {
  const { showError } = useContext(PopUpMessageContext)
  const [items, setItems] = useState<CollectionItem<Data>[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const accessLevel = useAuth((s) => s.accessLevel)
  const collectionId = useMemo(() => (collection ? getCollectionId(collection) : null), [collection])

  const subscribedCollectionId = useRef<string | null>(null)
  const unsub = useRef<(() => void) | undefined>(undefined)

  useEffect(() => {
    const unsubAndReturn = () => {
      if (unsub.current) unsub.current()
      return () => {}
    }

    // console.log('checking collection', collectionId, collection)
    if (!collectionId || !collection) return unsubAndReturn()
    // console.log('checking access', accessLevel)
    if (!accessLevel) return unsubAndReturn()
    // console.log('checking paused', paused)
    if (paused) return unsubAndReturn()
    const { access } = collection || {}

    // console.log('checking permissions', access, accessLevel)
    if (access && (!accessLevel || !access.includes(accessLevel))) {
      return unsubAndReturn()
    }
    subscribedCollectionId.current = collectionId
    unsub.current = subscribeToCollection(collection, accessLevel, {
      onData: (data) => {
        setItems(data)
      },
      onError: (newError) => {
        showError(newError)
        setError(newError)
      },
      onLoading: setLoading,
    })
    return unsub.current || (() => {})
  }, [collection, paused, showError, accessLevel, collectionId])
  useEffect(() => () => {
    if (unsub.current) unsub.current()
  }, [])
  return useMemo(() => ({
    items,
    loading,
    error,
  }), [items, loading, error])
}

export const getCollectionItems = <T extends DocumentData>(
  id: CollectionId,
): CollectionItem<T>[] => {
  const { [id]: collection } = useCollections.getState()
  return (collection?.items || []) as CollectionItem<T>[]
}

export const reorderCollection = async <Data extends DocData>(
  collection: Collection<Data>,
  dropResult: DropResult,
): Promise<UpdateCallback> => {
  const baseCollection = getBaseCollection(
    collection,
  )
  const collectionId = getCollectionId(collection)
  if (!baseCollection) throw new Error('No collection')
  const items = useCollections.getState()[collectionId]
    ?.items as CollectionItem<Data>[]

  if (items && dropResult.source && dropResult.destination) {
    const old = cloneDeep(items)
    const result = reorder<CollectionItem<Data>>(
      old,
      dropResult,
    )
    const batch = writeBatch(db)
    result.forEach((res, i) => {
      const ref = getCollectionRef(collection)
      batch.update(doc(ref, res.id), { rank: i })
    })
    return batch
      .commit()
      .catch((err) => {
        console.error(err)
        throw new Error(`Error reordering items: ${err.message}`)
      })
      .then(() => ({ success: 'reordered successfully!' }))
  }
  return { error: 'no need to reorder' }
}
