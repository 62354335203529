import { medicaidProvidersCollection } from '../collections/collections/insurer'
import { FieldTypes } from '../constants'
import {
  CheckboxField,
  DateField, EmailField,
  FieldMap,
  IdField,
  PhoneField,
  TextField,
} from '../types'
import { insuranceProviderField, medicaidInsuranceProviderField } from './insurance'
import {
  partnerPolicyOwnerDetails, policyOwnerField, policyOwnerFields, policyOwnerRelationshipField, selfPolicyOwnerDetails,
} from './insurance/insuranceCoverage'
import { addressField } from './shared'

export const patientAddressField = {
  ...addressField,
  name: 'Patient address',
  labels: [
    {
      text: 'What is your address (as listed with your insurer)?',
    },
  ],
}

export const patientPhoneField: PhoneField = {
  type: FieldTypes.PHONE,
  labels: [
    { text: 'What is your phone number (as listed with your insurer)?' },
  ],
  placeholder: 'Phone Number',
}

export const partnerNameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: "Partner's Name",
}
export const partnerEmailField: EmailField = {
  optional: true,
  type: FieldTypes.EMAIL,
  placeholder: "Partner's Email",
}
export const partnerPhoneField: PhoneField = {
  type: FieldTypes.PHONE,
  placeholder: "Partner's Phone",
  optional: true,
}
export const partnerContactField: FieldMap = {
  condition: (v) => v?.hasPartner,
  name: 'Partner Contact',
  children: {
    name: partnerNameField,
    phone: partnerPhoneField,
    email: partnerEmailField,
  },
}

const initialServiceDateField: DateField = {
  type: FieldTypes.DATE,
  optional: true,
  labels: [{ text: 'When did you first consult with your midwife?' }],
  placeholder: 'Initial date of service (consultation)',
}

const initialPrenatalVisitDateField: DateField = {
  type: FieldTypes.DATE,
  optional: true,
  labels: [{ text: 'When was your first prenatal visit with your midwife? (Could be in the future)' }],
  placeholder: 'Date of first prenatal visit',
}

const homeAddressIsSameAsPatientAddressField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Home address same as above address',
}

const homeAddressField: FieldMap = {
  name: 'Home Address',
  condition: (v) => !v?.homeAddressSameAsPatientAddress,
  children: addressField.children,
}

export const medicaidInsurerIdField: IdField = {
  labels: [
    {
      text: 'Who is your secondary insurer? (Medicaid)',
    },
  ],
  type: FieldTypes.ID,
  condition: (v) => !!v?.isMedicaid,
  collection: medicaidProvidersCollection,
  placeholder: 'Insurance Provider (Medicaid)',
  allowOther: true,
}

export const newSignOnFields: FieldMap = {
  name: 'Sign-on Form',
  children: {
    birthType: {
      type: FieldTypes.DROPDOWN,
      placeholder: 'Birth Location',
      labels: [
        { text: 'What type of birth are you planning/leaning towards?' },
      ],
      options: [
        { id: 'home', text: 'Home birth' },
        { id: 'hospital', text: 'Hospital birth' },
        { id: 'birthing-center', text: 'Birthing center birth' },
      ],
    },
    address: patientAddressField,
    homeAddressSameAsPatientAddress: homeAddressIsSameAsPatientAddressField,
    homeAddress: homeAddressField,
    phone: patientPhoneField,
    initialServiceDate: initialServiceDateField,
    initialPrenatalVisitDate: {
      ...initialPrenatalVisitDateField,
      placeholder: 'Date of first prenatal visit',
    },
    hasPartner: {
      type: FieldTypes.BOOLEAN,
      placeholder: 'Patient has Partner',
      labels: [{ text: 'Do you have a partner?' }],
    },
    partnerContact: partnerContactField,

  },
}

export const signOnFields: FieldMap = {
  ...newSignOnFields,
  children: {
    ...newSignOnFields.children,
    policyOwnerRelationship: policyOwnerRelationshipField,
    selfPolicyOwnerDetails,
    partnerPolicyOwnerDetails,
    policyOwnerInfo: policyOwnerField,
    hasSecondaryCoverage: {
      type: FieldTypes.BOOLEAN,
      labels: [{ text: 'Do you have secondary coverage?' }],
      placeholder: 'Secondary Coverage',
    },
    secondaryCoverageDetails: {
      name: 'Secondary Coverage Details',
      condition: (v) => v?.hasSecondaryCoverage,
      children: {
        policyOwnerRelationship: policyOwnerRelationshipField,
        selfPolicyOwnerDetails,
        partnerPolicyOwnerDetails,
        isMedicaid: {
          type: FieldTypes.BOOLEAN,
          placeholder: 'Is your secondary coverage through Medicaid?',
        },
        insurerId: insuranceProviderField,
        medicaidInsurerId: medicaidInsuranceProviderField,
        memberId: {
          type: FieldTypes.TEXT,
          placeholder: 'Member ID for Secondary Coverage',
        },
        initialServiceDate: {
          ...initialServiceDateField,
          placeholder: 'Initial Date of Service (Secondary Coverage)',
        },
        initialPrenatalVisitDate: {
          ...initialPrenatalVisitDateField,
          placeholder: 'Date of First Prenatal Visit (Secondary Coverage)',
        },
        ...policyOwnerFields,
      },
    } as FieldMap,
  },
}
