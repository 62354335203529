import { Timestamp } from 'firebase/firestore'
import { padZeros } from './data'

export const getDateTimeString = (
  date: Date | number | string | Timestamp,
  dateStyle: 'long' | 'short' = 'short',
  noYear = false,
) => {
  if (dateStyle === 'long') {
    return castDate(date).toLocaleString('en-US', {
      minute: '2-digit',
      hour: 'numeric',
      year: noYear ? undefined : 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      timeZoneName: 'short',
    })
  }
  return castDate(date).toLocaleString('en-US', {
    minute: '2-digit',
    hour: 'numeric',
    year: noYear ? undefined : '2-digit',
    month: '2-digit',
    day: '2-digit',
    timeZoneName: 'short',
  })
}

export const getDateTimeRangeString = (
  start: Date | number | string | Timestamp,
  end: Date | number | string | Timestamp,
  noYear = false,
) => {
  const startDate = castDate(start)
  const endDate = castDate(end)
  const startString = getDateTimeString(startDate, 'short', noYear)
  const endString = getDateTimeString(endDate, 'short', noYear)
  if (startString.split(',')[0] === endString.split(',')[0]) {
    const [startStringDate, startTime, startAmpm, startTimezone] = startString.split(' ')
    const [,,, endTimezone] = endString.split(' ')
    const startPart = startTimezone === endTimezone ? [startStringDate, startTime, startAmpm].join(' ') : startString
    return `${startPart} - ${endString.split(',')[1]}`
  }
  return `${startString} - ${endString}`
}

export const getTimeString = (date: Date | number | string) => castDate(date).toLocaleString('en-US', {
  minute: '2-digit',
  hour: 'numeric',
})

export const castDate = (date: Date | number | string | Timestamp) => {
  if (date instanceof Timestamp) {
    return date.toDate()
  }
  return (date instanceof Date ? date : new Date(date))
}

export const getMonthString = (date: Date | number | string | Timestamp, utc = true) => {
  const asDate = castDate(date)
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    year: 'numeric',
  }
  if (utc) options.timeZone = 'UTC'
  return asDate.toLocaleString('en-US', options)
}

export const getDateString = (
  date: Date | Timestamp | number | string,
  dateStyle: 'long' | 'short' = 'long',
  utc = true,
) => {
  let options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }
  const asDate = castDate(date)
  // if (utc) asDate.setTime(asDate.getTime() - asDate.getTimezoneOffset() * 60 * 1000)
  if (utc) options.timeZone = 'UTC'
  // options.timeZone = 'UTC'
  // if (utc) options.timeZone = 'America/New_York'
  if (dateStyle === 'long') {
    options = {
      ...options,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    }
  }

  return asDate.toLocaleDateString('en-US', options)
}
export const addNDaysTo = (to: number | Date, n: number) => {
  const d = castDate(to)
  d.setDate(d.getDate() + n)
  return d.getTime()
}
export const getNDaysFromNow = (n: number) => addNDaysTo(new Date(), n)
export const getYearsSince = (date: Date | number | string) => {
  const d = castDate(date)
  const now = Date.now()
  return (now - d.getTime()) / (1000 * 60 * 60 * 24 * 365)
}

export const getYearsSinceString = (date: Date | number | string) => {
  const years = getYearsSince(date)
  return Math.floor(years)
}

export const getLocalDateString = (date: Date) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()
  return `${padZeros(month, 2)}/${padZeros(day, 2)}/${padZeros(year, 4)}`
}

export const getDurationString = (start: Date | number | string | Timestamp, end: Date | number | string | Timestamp) => {
  const startDate = castDate(start)
  const endDate = castDate(end)
  const duration = endDate.getTime() - startDate.getTime()
  const seconds = Math.floor(duration / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)

  const parts: string[] = []
  if (weeks) parts.push(`${weeks} week${weeks === 1 ? '' : 's'}`)
  if (days % 7) parts.push(`${days % 7} day${days % 7 === 1 ? '' : 's'}`)
  if (hours % 24) parts.push(`${hours % 24} hour${hours % 24 === 1 ? '' : 's'}`)
  if (minutes % 60) parts.push(`${minutes % 60} minute${minutes % 60 === 1 ? '' : 's'}`)
  if (seconds % 60) parts.push(`${seconds % 60} second${seconds % 60 === 1 ? '' : 's'}`)
  return parts.join(', ')
}

export const dateToDateString = (date: Date) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()
  return `${year}-${padZeros(month, 2)}-${padZeros(day, 2)}`
}

export const dateToTimeString = (date: Date) => {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const hour = hours % 12 || 12
  return `${hour}:${minutes.toString().padStart(2, '0')} ${ampm}`
}

// time is in the format "HH:MM AM/PM"
// returns a string in the format "HH:MM:SS"
export const timeStringTo24Hour = (time: string) => {
  const [timeStr, ampm] = time.split(' ')
  const [hours, minutes] = timeStr.split(':').map((v) => parseInt(v, 10))
  const hour = ampm === 'PM' && hours < 12 ? hours + 12 : hours
  return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`
}

export const timeStringFrom24Hour = (time: string) => {
  const [hours, minutes] = time.split(':').map((v) => parseInt(v, 10))
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const hour = hours % 12 || 12
  return `${hour}:${minutes.toString().padStart(2, '0')} ${ampm}`
}

export const addMinutesToTimeString = (time: string, minutes: number) => {
  const [hours, minutesStr] = time.split(':').map((v) => parseInt(v, 10))
  const totalMinutes = hours * 60 + minutesStr + minutes
  const newHours = Math.floor(totalMinutes / 60) % 24
  const newMinutes = totalMinutes % 60
  return `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`
}

export const getTimezoneOffset = () => new Date().toLocaleTimeString('en-us', { timeZoneName: 'longOffset' }).split(' ')[2].substring(3)
export const getTimezoneOffsetMinutes = () => new Date().getTimezoneOffset()

// start (unix timestamp) and end (unix timestamp) are in milliseconds
// returns local date string, start time string, and end time string in the format "MM/DD/YYYY", "HH:MM:SSS", "HH:MM AM/PM"
export const localizeDateRange = (start: Date | number | string, end: Date | number | string): {
  date: string
  startTime: string
  endTime: string
} => {
  const startDate = castDate(start)
  const endDate = castDate(end)
  const offset = getTimezoneOffset()
  const startTime = `${timeStringTo24Hour(getTimeString(startDate))}${offset}`
  const endTime = `${timeStringTo24Hour(getTimeString(endDate))}${offset}`
  const date = (castDate(startDate.getTime() - getTimezoneOffsetMinutes() * 60 * 1000)).toISOString().split('T')[0]
  return { date, startTime, endTime }
}
