import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as BaseNumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import { InputElement, InputProps, NumberField } from '@hb/shared'
import React, { forwardRef } from 'react'

export const NumberInput: InputElement<NumberField> = forwardRef<
  HTMLInputElement,
  InputProps<NumberField>
>(({ input, field }, ref) => {
  const {
    min, max, optional, defaultValue, step, precision, placeholder,
  } = field as NumberField

  const { onChange, value } = input

  return (
    <BaseNumberInput
      {...input}
      onChange={(asString, asNumber) => {
        if (field.precision) {
          onChange({ target: { value: asString } })
        } else {
          onChange({ target: { value: asString ? asNumber : undefined } })
        }
        // onChange({ target: { value: field.precision ? asString : asNumber } })
      }}
      value={value}
      onFocus={(e) => {
        input.onFocus(e)
        e.target.select()
      }}
      onBlur={input.onBlur}
      onMouseUp={(e) => {
        e.preventDefault()
        // e.currentTarget.focus()
      }}
      min={min}
      ref={ref}
      max={max}
      isRequired={!optional}
      defaultValue={defaultValue}
      step={step}
      precision={precision || 0}
      placeholder={placeholder}
    >
      <NumberInputField
        _focus={{
          boxShadow: 'none',
        }}
        fontSize="sm"
        placeholder={placeholder}
        borderColor="#cdcdcd"
      />
      {field.withStepper ? (
        <NumberInputStepper>
          <NumberIncrementStepper
            borderColor="#cdcdcd"
            opacity={0.5}
            _hover={{ opacity: 0.8 }}
          />
          <NumberDecrementStepper
            borderColor="#cdcdcd"
            opacity={0.5}
            _hover={{ opacity: 0.8 }}
          />
        </NumberInputStepper>
      ) : null}
    </BaseNumberInput>
  )
})
