import { ArrowUpIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react'
import {
  Assessment,
  ASSESSMENTS,
  BaseInsuranceCoverage,
  callIn,
  capitalizeFirstLetterOfEachWord,
  colors,
  CoverageSnippet,
  defaultStageValidate,
  FieldMapValue,
  Form,
  getCoverageRequiresCallIn,
  getCoverageStatus,
  InsuranceCoverage,
  InsuranceCoverageUpdateType,
  notesField,
  OnUploadProgress,
  TextAreaField,
  UpdateCallback,
  WithId,
} from '@hb/shared'
import {
  deleteField,
  doc,
  DocumentReference,
  updateDoc,
} from 'firebase/firestore'
import { set as nestedSet } from 'nested-property'
import React, {
  JSX, useCallback, useContext, useMemo, useState,
} from 'react'
import { db, makeCoveragePrimary, processFieldMapData } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { useAuth } from '../../../store/auth'
import { xor } from '../../../utils'
import { ActionConfirm } from '../../Alerts'
import { ActionButton, DeleteButton } from '../../Buttons'
import { DataView, GenericEditModal } from '../../DataView'
import { Expandable } from '../../Expandable'
import { FormWizard, StandaloneInput } from '../../forms'
import { SimpleForm } from '../../forms/FinalForm/SimpleForm'
import { DefaultModal } from '../../Modals/DefaultModal'
import { CoverageViewContext, CoverageViewProvider } from './context'
import { CoverageHistory } from './CoverageHistory'
import { CoverageInsurerBadge } from './CoverageInsurerBadge'
import { CoverageStatusBadge } from './CoverageStatusBadge'
import { RequestCallIn } from './RequestCallIn'
import {
  CoverageTerminationStatus,
  useTerminationStatus,
} from './TerminationStatus'
import { CoverageStageProps } from './types'
import { coverageSnippetStage } from './utils'

const callInForm: Form = {
  ...callIn,
  path: '',
}

const coverageSnippetUpdateType: Record<
  CoverageSnippet,
  InsuranceCoverageUpdateType
> = {
  'basic-info': 'basicInfo',
  'call-in': 'callIn',
  'policy-owner': 'policyOwner',
}

const CoverageSnippetView = ({
  id,
  isMedicaid,
  inModal,
}: {
  id: CoverageSnippet
  isMedicaid: boolean
  inModal?: boolean
}) => {
  const authUser = useAuth((s) => s.authUser)
  const {
    coverage, handleSubmit, adminView, assessmentId, baseStoragePath,
  } = useContext(CoverageViewContext)
  const [isEditing, setIsEditing] = useState(false)
  const { callInRequests } = coverage || {}
  const hasCallInRequest = useMemo(
    () => !!Object.keys(callInRequests || {}).length,
    [callInRequests],
  )
  const field = useMemo(
    () => coverageSnippetStage[id](isMedicaid),
    [id, isMedicaid],
  )
  const isComplete = useMemo(
    () => !defaultStageValidate(field, true)(coverage),
    [field, coverage],
  )

  const updateField = useCallback(
    async (path: string, data: FieldMapValue): Promise<UpdateCallback> => {
      const uid = authUser?.uid
      if (!uid) return { error: 'Not signed in' }
      if (!coverage) return { error: 'No coverage to update' }
      let submitted: Partial<InsuranceCoverage> = {}
      if (path) {
        submitted = { ...coverage }
        nestedSet(submitted, path, data)
      } else submitted = { ...coverage, ...data }
      submitted.history = [
        ...(coverage.history || []),
        { by: uid, on: Date.now(), type: coverageSnippetUpdateType[id] },
      ]
      return handleSubmit(submitted).then((errors) => {
        if (errors) return { error: 'An error occurred' }
        return { success: 'Updated!' }
      })
    },
    [coverage, handleSubmit, authUser, id],
  )

  const onSubmit = useCallback(
    async (data: FieldMapValue, onUploadProgress: OnUploadProgress) => {
      const processed = await processFieldMapData(
        baseStoragePath,
        field,
        data,
        coverage,
        onUploadProgress,
      )
      return handleSubmit(processed)
    },
    [handleSubmit, baseStoragePath, field, coverage],
  )

  let body: JSX.Element | null = null
  if (id === 'call-in') {
    if (!adminView) {
      body = (
        <FormWizard
          withPreview
          data={coverage}
          onSubmit={handleSubmit}
          baseStoragePath={baseStoragePath}
          form={callInForm}
        />
      )
    } else if (hasCallInRequest) {
      body = (
        <DataView
          noHeader
          boxProps={{
            p: 0,
            boxShadow: 'none',
            borderBottom: '1px solid #cdcdcd',
          }}
          updateField={updateField}
          baseStoragePath={baseStoragePath}
          onSubmit={onSubmit}
          data={coverage}
          field={field}
        />
      )
    } else {
      body = <RequestCallIn assessmentId={assessmentId} coverage={coverage} />
    }
  } else if (adminView || isComplete) {
    body = (
      <DataView
        boxProps={{
          p: 0,
          boxShadow: 'none',
          borderBottom: '1px solid #cdcdcd',
        }}
        onSubmit={onSubmit}
        noHeader
        baseStoragePath={baseStoragePath}
        updateField={updateField}
        data={coverage}
        field={field}
      />
    )
  } else {
    body = (
      <SimpleForm
        boxProps={{
          p: 0,
          boxShadow: 'none',
        }}
        onSubmit={onSubmit}
        value={coverage}
        field={field}
      />
    )
  }
  return (
    <>
      <Expandable
        borderBottom="1px solid #cdcdcd"
        initExpanded={!isComplete}
        headerProps={{ bg: '#e4f6ef' }}
        bg="white"
        header={() => (
          <HStack flex={1} pl={2} py={1} minW="0" spacing={0}>
            <Flex
              color="#565656"
              position="relative"
              fontWeight={600}
              fontFamily="Open Sans"
            >
              {field.name}
            </Flex>
            {adminView || id !== 'call-in' ? (
              <IconButton
                ml="auto"
                aria-label="edit"
                color="gray.600"
                icon={<EditIcon />}
                h={6}
                w={6}
                borderRadius="full"
                size="sm"
                variant="ghost"
                onClick={() => setIsEditing(true)}
              />
            ) : null}
          </HStack>
        )}
      >
        <Flex bg="white" px={inModal ? 2 : 0} w="100%">
          {body}
        </Flex>
      </Expandable>
      {isEditing ? (
        <GenericEditModal
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSubmit={(data) => updateField('', data)}
          field={field}
          data={coverage}
        />
      ) : null}
    </>
  )
}

const messageField: TextAreaField = {
  ...notesField,
  optional: false,
  placeholder: 'Message to patient',
}

const ConfirmSetPrimaryAlert = ({
  coverage,
  coverageId,
  assessmentId,
  onBack,
}: {
  coverage: InsuranceCoverage
  onBack: () => void
  assessmentId: string
  coverageId: string
}) => {
  const { showMessage } = useContext(PopUpMessageContext)
  const callInComplete = useMemo(() => {
    const newStatus = getCoverageStatus(
      coverage.isMedicaid ? 'medicaidCoverage' : 'primaryCoverage',
      coverage,
    )
    return !newStatus?.incomplete.includes('call-in')
  }, [coverage])
  const [sendEmail, setSendEmail] = useState(!callInComplete)
  const [message, setMessage] = useState('')

  const handleSetPrimary = useCallback(async () => {
    try {
      if (!message) {
        showMessage({
          type: 'error',
          text: 'Error setting coverage as primary',
          subText: 'Please enter a message',
        })
        return
      }
      const {
        data: { success },
      } = await makeCoveragePrimary({
        assessmentId,
        coverageId,
        sendEmail,
        message,
      })
      showMessage({
        type: 'success',
        text: 'Coverage updated',
        subText: success,
      })
    } catch (err: any) {
      showMessage({
        type: 'error',
        text: 'Error setting coverage as primary',
        subText: err.message,
      })
    }
  }, [assessmentId, coverageId, showMessage, sendEmail, message])
  return (
    <ActionConfirm
      body={
        <Flex pt={2} w="100%" flexFlow="column">
          <Text fontWeight={600} fontSize="lg" color="gray.600">
            Update primary coverage?
          </Text>
          <Text pt={1} pb={2} lineHeight={1}>
            Are you sure you want to set this coverage as primary?
          </Text>
          <StandaloneInput
            value={message}
            theme="detailed"
            onChange={(e) => setMessage(e.target ? e.target.value : e)}
            field={messageField}
          />
          {callInComplete ? null : (
            <Flex
              border="1px solid #cdcdcd"
              px={3}
              mt={2}
              bg="gray.50"
              pb={3}
              borderRadius={4}
              flexFlow="column"
              w="100%"
            >
              <Text fontSize="sm" mt={2}>
                This coverage will require a call-in request to be completed.
                Should we send an email to the patient with a link to the
                call-in request?
              </Text>
              <Flex
                mt={2}
                bg="white"
                p={3}
                align="center"
                borderRadius={4}
                boxShadow="1px 1px 4px #00000044"
              >
                <Checkbox
                  isChecked={sendEmail || false}
                  value=""
                  onChange={(e) => setSendEmail(e.target.checked)}
                  colorScheme="green"
                >
                  <Text
                    color={sendEmail ? colors.green.hex : 'gray.500'}
                    fontSize="sm"
                    fontFamily="Hero-New"
                    lineHeight={1}
                  >
                    Send call-in request email
                  </Text>
                </Checkbox>
              </Flex>
            </Flex>
          )}
        </Flex>
      }
      confirmText="Set as primary"
      isOpen
      onClose={onBack}
      onConfirm={handleSetPrimary}
    />
  )
}

const CoverageActions = ({
  coverage,
  assessmentId,
}: {
  coverage: WithId<InsuranceCoverage> | WithId<BaseInsuranceCoverage>
  assessmentId: string
}) => {
  const handleDelete = useCallback(() => {
    const ref = doc(
      db,
      `${ASSESSMENTS}/${assessmentId}`,
    ) as DocumentReference<Assessment>
    return updateDoc(ref, `additionalPlans.${coverage.id}`, deleteField())
  }, [assessmentId, coverage])
  const [confirmSettingPrimary, setConfirmSettingPrimary] = useState(false)

  return (
    <Flex align="center" w="100%" px={2} py={1}>
      <ActionButton
        onClick={() => setConfirmSettingPrimary(true)}
        pl={3}
        gap={1}
        size="xs"
      >
        <ArrowUpIcon w={4} h={4} />
        <Text>SET AS PRIMARY</Text>
      </ActionButton>
      <Box ml="auto">
        <DeleteButton
          text="Delete coverage"
          size="sm"
          itemName="coverage"
          onDelete={() => handleDelete()}
        />
      </Box>
      {confirmSettingPrimary ? (
        <ConfirmSetPrimaryAlert
          coverage={coverage as InsuranceCoverage}
          coverageId={coverage.id}
          assessmentId={assessmentId}
          onBack={() => setConfirmSettingPrimary(false)}
        />
      ) : null}
    </Flex>
  )
}

export const CoverageStage = (props: CoverageStageProps) => {
  const {
    isOpen,
    onOpenToggle,
    adminView,
    coverage,
    type,
    optional,
    inModal,
    alwaysOpen,
    request,
  } = props
  const status = useMemo(
    () => getCoverageStatus(type, coverage, optional, request),
    [type, coverage, optional, request],
  )
  const complete = useMemo(() => !status?.incomplete?.length, [status])
  const isMedicaid = useMemo(
    () => type === 'medicaidCoverage' || coverage?.isMedicaid,
    [type, coverage],
  )
  const requiresCallIn = useMemo(
    () => getCoverageRequiresCallIn(type, coverage, optional, request),
    [coverage, optional, type, request],
  )

  const showRequestCallIn = useMemo(
    () => type === 'additionalPlans' && !!adminView && !isMedicaid,
    [adminView, type, isMedicaid],
  )

  const label = useMemo(() => {
    if (coverage?.label) return capitalizeFirstLetterOfEachWord(coverage.label)
    if (type === 'primaryCoverage') return 'Primary'
    if (type === 'medicaidCoverage') return 'Medicaid'
    if (type === 'additionalPlans') return 'Additional'
    return 'Insurance'
  }, [coverage, type])

  const terminationStatus = useTerminationStatus(coverage || null)

  return (
    <CoverageViewProvider {...props}>
      <Expandable
        isOpen={isOpen}
        alwaysExpanded={inModal || alwaysOpen}
        onClose={onOpenToggle}
        onOpen={onOpenToggle}
        bg={inModal ? 'transparent' : 'gray.50'}
        initExpanded={xor(!complete, adminView)}
        overflow="hidden"
        border="1px solid #cdcdcd"
        borderRadius={inModal ? 0 : 6}
        iconColor="whiteAlpha.900"
        headerProps={{ bg: colors.green.hex }}
        header={() => (
          <Flex
            flexFlow={['column', 'row']}
            gap={[0, 2]}
            align={['flex-start', 'center']}
            px={2}
            py={inModal ? 3 : 1}
            w="100%"
          >
            <Text
              color="white"
              textShadow="1px 1px 3px #00000066"
              fontWeight={600}
            >
              {label} Coverage
            </Text>
            <Flex gap={2} w={['100%', 'unset']} flex={['unset', 1]} flexFlow="row">
              <Box>
                <CoverageInsurerBadge
                  insurerId={coverage?.insuranceProviderId}
                />
              </Box>
              {terminationStatus?.status === 'terminated' ? (
                <Box>
                  <CoverageTerminationStatus
                    coverage={coverage || null}
                    simple
                    terminationStatus={terminationStatus}
                  />
                </Box>
              ) : null}
              <Center gap={2} h="auto" ml="auto">
                {coverage ? <CoverageHistory coverage={coverage} /> : null}
                <CoverageStatusBadge status={status} />
              </Center>
            </Flex>
          </Flex>
        )}
      >
        <Flex w="100%" bg="gray.50">
          <CoverageSnippetView
            id="basic-info"
            isMedicaid={!!isMedicaid}
            inModal={inModal}
          />
        </Flex>
        {requiresCallIn || showRequestCallIn ? (
          <CoverageSnippetView id="call-in" isMedicaid={!!isMedicaid} />
        ) : null}
        <CoverageSnippetView id="policy-owner" isMedicaid={!!isMedicaid} />
        {adminView && coverage ? (
          <CoverageActions
            assessmentId={props.assessmentId}
            coverage={coverage}
          />
        ) : null}
      </Expandable>
    </CoverageViewProvider>
  )
}

export const CoverageStageModal = ({
  isOpen,
  onClose,
  ...props
}: CoverageStageProps & { isOpen: boolean; onClose: () => void }) => (
  <DefaultModal
    size="xl"
    isOpen={isOpen}
    onClose={onClose}
    overlayHeader
    render={() => <CoverageStage inModal isOpen {...props} />}
  />
)
