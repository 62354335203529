import { DeleteIcon } from '@chakra-ui/icons'
import { Box, Flex, Text } from '@chakra-ui/react'
import {
  SubmittedAuthentication,
  SubmittedVisitData,
  User,
  WithId,
} from '@hb/shared/types'
import { getPracticeVisitDraftsCollectionPath } from '@hb/shared/utils'
import { addDoc, collection } from 'firebase/firestore'
import React, { useCallback, useContext, useState } from 'react'
import { addPracticeVisit, db } from '../../../../backend'
import { useApp } from '../../../../contexts/AppContext'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import saveIcon from '../../../../icons/save_old_green.svg'
import { SvgIcon } from '../../../../icons/SvgIcon'
import { ActionButton } from '../../../Buttons'
import { AuthenticateAndSubmit } from './AuthenticateCheckbox'
import { populateVisitDraft } from './utils'
import { VisitDraftView } from './VisitView'

export const SignVisitOrSaveDraft = ({
  draft,
  practiceId,
  assessmentId,
  onClose,
  patient,
}: {
  draft: SubmittedVisitData
  patient: WithId<User>
  practiceId: string
  assessmentId: string
  onClose: () => void
}) => {
  const [authChecked, setAuthChecked] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const { appName } = useApp()
  const [savingDraft, setSavingDraft] = useState(false)
  const onSaveDraft = useCallback(async () => {
    const collectionPath = getPracticeVisitDraftsCollectionPath(practiceId)

    setSavingDraft(true)
    try {
      await addDoc(
        collection(db, collectionPath),
        populateVisitDraft(draft, assessmentId, patient),
      )
      onClose()
      processResponse({ success: 'Draft saved' })
    } catch (e: any) {
      console.error({ error: e?.message || 'error' })
      processResponse({ error: e.message || 'error' })
    }
    setSavingDraft(false)
  }, [draft, practiceId, processResponse, onClose, assessmentId, patient])

  const [submitting, setSubmitting] = useState(false)
  const onSubmit = useCallback(
    (authentication: SubmittedAuthentication): Promise<void> => {
      setSubmitting(true)
      return addPracticeVisit({
        appName,
        practiceId,
        authentication,
        visitId: null,
        ...draft,
      })
        .then(() => {
          onClose()
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
    [appName, practiceId, draft, onClose],
  )

  return (
    <Flex justify="center" p={3} w="100%">
      <Flex w="100%" flexFlow="column" maxW="800px">
        <Box
          mb={3}
          w="100%"
          p={1}
          boxShadow="1px 1px 4px #00000055"
          bg="white"
          borderRadius={6}
        >
          <VisitDraftView draft={draft} />
        </Box>
        <Flex w="100%" align="center" gap={2}>
          <ActionButton
            onClick={onSaveDraft}
            isDisabled={submitting}
            isLoading={savingDraft}
            fontSize="sm"
            size="sm"
            bg='white'
            flex={1}
            gap={1}
          >
            <SvgIcon src={saveIcon} />
            <Text>Save Draft</Text>
          </ActionButton>
          <ActionButton bg='white' isDisabled={submitting} size='sm' gap={1} onClick={onClose} colorScheme="red">
            <DeleteIcon />
            <Text>Delete Draft</Text>
          </ActionButton>
        </Flex>
        <AuthenticateAndSubmit
          practiceId={practiceId}
          onSubmit={onSubmit}
          onChange={setAuthChecked}
          authChecked={authChecked}
          itemName="visit"
        />
      </Flex>
    </Flex>
  )
}
