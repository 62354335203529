import {
  Checkbox as ChakraCheckbox,
  HStack,
  Switch,
  Text,
} from '@chakra-ui/react'
import { CheckboxField, InputProps } from '@hb/shared'
import * as React from 'react'
import { useContext } from 'react'
import { ThemeContext } from '../../../../contexts'

const Checkbox: React.FC<
  InputProps<CheckboxField> & { readOnly?: boolean }
> = ({
  field, input, readOnly, disabled,
}) => {
  const { placeholder, inputType } = field || {}
  const { theme } = useContext(ThemeContext)
  return inputType === 'switch' ? (
    <HStack px={1} align='center'>
      <Switch
        // px={1}
        my={1}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        size={theme === 'detailed' ? 'md' : 'sm'}
        colorScheme='green'
        onChange={({ target: { checked } }) => input.onChange(!!checked)
        }
        isChecked={input.value}
      />
      <Text fontFamily='Hero-New' fontSize='sm' color={input.value ? 'gray.600' : 'gray.400'}>
        {input.value
          ? field.yesText || placeholder
          : field.noText || placeholder}
      </Text>
    </HStack>
  ) : (
    <ChakraCheckbox
      // px={1}
      // my={1}
      readOnly={readOnly}
      isDisabled={disabled}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      size={theme === 'detailed' ? 'md' : 'sm'}
      colorScheme='green'
      onChange={({ target: { checked } }) => {
        input.onChange(!!checked)
        input.onBlur()
      }}
      isChecked={input.value}
    >
      <Text color='gray.600' fontWeight={600} fontSize='sm'>
        {field.placeholder}
      </Text>
    </ChakraCheckbox>
  )
}

export default Checkbox
