import {
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  DragHandleIcon,
  EditIcon,
  QuestionOutlineIcon,
} from '@chakra-ui/icons'
import {
  Box,
  Center,
  Checkbox as ChakraCheckbox,
  Collapse,
  Divider,
  Flex,
  FlexProps,
  Grid,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  StackProps,
  Text,
  TextProps,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  ALPHABET,
  AlternateField,
  colors,
  defaultValidation,
  EditableProps,
  Field as IField,
  fieldFormat,
  FieldFormatter,
  FieldMap,
  FieldMapValue,
  fieldParse,
  FieldTypes,
  FileDBValue,
  FileField,
  FormElement as IFormElement,
  FormElementProps,
  getDateTimeString,
  getFieldIsRedFlagged,
  InputElement as IInputElement,
  InputProps,
  isAlphaNumeric,
  isField,
  isInfoStage,
  isListField,
  ListField,
  ListInputProps,
  SignatureField,
  UnuploadedFileDBValue,
  UpdateCallback,
  UploadProgress,
} from '@hb/shared'
import { ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  PropsWithChildren,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import {
  Field, Form, FormRenderProps, useForm,
} from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { processFileFieldData } from '../../../backend'
import { deleteFile } from '../../../backend/storage'
import { EditableStateContext } from '../../../contexts/EditableStateContext'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { ScreenContext } from '../../../contexts/ScreenContext'
import { ThemeContext } from '../../../contexts/ThemeContext'
import {
  useFile,
  UseFileData,
} from '../../../hooks/backend/storage/downloadFile'
import { useFilePath } from '../../../hooks/backend/storage/useFilePath'
import { useCollectionItem } from '../../../hooks/backend/useCollectionItem'

import { useFormattedValue } from '../../../hooks/useFormattedValue'
import { SvgIcon } from '../../../icons/SvgIcon'
import uploadIcon from '../../../icons/upload.svg'
import { useCollections } from '../../../store/collections'
import { ActionButton, SolidActionButton } from '../../Buttons/ActionButton'
import { DeleteButton } from '../../Buttons/DeleteButton'
import { HeaderButton } from '../../Buttons/HeaderButton'
import { ViewButton } from '../../Buttons/ViewButton'
import { CollapseError } from '../../CollapseError'
import { Container } from '../../Container'
import { DataViewContext } from '../../DataView/context'
import { DataCell } from '../../DataView/DataCell'
import { ProfileRedFlagPopover } from '../../DataView/RedFlagPopover'
import { ExpandOnMount } from '../../ExpandOnMount'
import { Header } from '../../Header'
import { DBTextStage } from '../DBTextStage'
import { Condition } from '../FinalForm/Condition'
import usePopulatedHints from '../FinalForm/hooks/usePopulatedHints'
import usePopulatedLabels from '../FinalForm/hooks/usePopulatedLabels'
import { UploadProgressView } from '../FinalForm/UploadProgressView'
import Checkbox from './Checkbox/Checkbox'
import { DataLabel } from './DataLabel'
import { DateInput } from './Date/DateInput'
import { DateTimeInput } from './Date/DateTimeInput'
import { TimeInput } from './Date/TimeInput'
import BooleanDropdown from './Dropdown/BooleanDropdown'
import DatabaseDropdown from './Dropdown/DatabaseDropdown'
import DynamicDropdown from './Dropdown/DynamicDropdown'
import { EditableCheckbox } from './EditableCheckbox'
import { BaseFileView } from './File/BaseFileView'
import {
  ChangedFile,
  FileUploadingState,
  useUploadDbFileValue,
} from './File/hooks'
import Hints from './Hints'
import Labels from './Labels'
import { MultipleSelectInput } from './MultipleSelect'
import { NumberInput } from './Number/NumberInput'
import { Presets } from './Presets'
import { DropdownInput } from './Select/DropdownInput'
import InitialsInput from './Signatures/InitialsInput'
import { SignatureInputBody } from './Signatures/SignatureInputBody'
import DollarAmountInput from './Text/DollarAmount/DollarAmountInput'
import EmailInput from './Text/Email/EmailInput'
import PercentageInput from './Text/Percentage/PercentageInput'
import PhoneInput from './Text/Phone/PhoneInput'
import TextArea from './Text/TextArea/TextArea'
import TextInput from './Text/TextInput'
import { InputRef } from './types'

type FileViewProps = {
  field: IField
  small?: boolean
  noDelete?: boolean
  onFileUpload?: (value: React.ChangeEvent<HTMLInputElement>) => (Promise<void> |void)
  onUploadMultiple?: (value: React.ChangeEvent<HTMLInputElement>) => (Promise<void> |void)
  onChange?: (value: FileDBValue | null) => Promise<UpdateCallback> | void
  onBlur?: () => void
  onFocus?: () => void
  data?: FileDBValue | UnuploadedFileDBValue
  flexProps?: FlexProps
  children?: React.ReactNode
}
// checks if [index] is in string, e.g. [0] or [1]
const containsIndexRegex = /\[\d+\]/
const isInList = (name: string) => name.match(containsIndexRegex)
// with new system, we should not need to pass field to FileView as storagePath is in value
const PreFileView = (
  {
    field,
    data,
    flexProps,
    small,
    noDelete,
    onUploadMultiple,
    onFileUpload,
    onChange,
    children,
  }: FileViewProps,
  ref: Ref<InputRef>,
) => {
  const { placeholder, fileType } = field as FileField
  const { showError, showSuccess } = useContext(PopUpMessageContext)
  const { theme } = useContext(ThemeContext)
  const [updatedUrl, setUpdatedUrl] = useState<string | null>(null)
  const [updatedMetadata, setUpdatedMetadata] = useState<any | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const { downloadPath, recentlyUploaded } = useFilePath(data)

  const fileArgs = useMemo<UseFileData>(
    () => ({
      path: downloadPath,
      value: data,
    }),
    [downloadPath, data],
  )

  const {
    metadata, loading, error, url, blob, blobErr, blobLoading,
  } = useFile(fileArgs)

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) inputRef.current.click()
    },
    blur: () => {
      if (inputRef.current) inputRef.current.blur()
    },
  }))

  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = useCallback(async () => {
    if (!onChange) return
    const onFileDeleted = async () => {
      setUpdatedUrl('')
      await onChange(null)
      setUpdatedMetadata({})
      showSuccess('Successfully deleted file!')
    }
    setIsDeleting(true)
    if (downloadPath) {
      const downloadRes = await deleteFile(downloadPath)

      if (downloadRes.error) showError(downloadRes.error)
      if (downloadRes.success) {
        await onFileDeleted()
      }
    }
    await onFileDeleted()
  }, [onChange, downloadPath, showError, showSuccess])

  const displayedUrl = useMemo(
    () => (updatedUrl !== null ? updatedUrl : url),
    [url, updatedUrl],
  )

  const accept = useMemo(() => {
    switch (fileType) {
      case 'image':
        return 'image/png, image/jpeg'
      case 'pdf':
        return 'application/pdf'
      default:
        return 'image/png, image/jpeg, application/pdf'
    }
  }, [fileType])

  const { isMobile } = useContext(ScreenContext)

  const displayedMetadata = updatedMetadata || metadata
  return (
    <Flex
      p={small ? 0 : 1}
      direction={['column', 'row']}
      w="100%"
      {...flexProps}
    >
      <Flex
        m={1}
        mr={1}
        w={isMobile ? '100%' : 'auto'}
        justify={isMobile ? 'center' : 'flex-start'}
        align="center"
      >
        <BaseFileView
          blob={blob}
          small={small}
          error={error || blobErr}
          loading={loading || blobLoading || recentlyUploaded}
          header={data?.name || placeholder}
        >
          {onChange ? null : children}
        </BaseFileView>
      </Flex>
      {small ? null : (
        <Flex
          ml={isMobile ? 0 : 'auto'}
          px={2}
          pt={0}
          pb={2}
          flex={1}
          direction="column"
          minW="0"
          align={isMobile ? 'flex-start' : 'flex-end'}
        >
          {onChange && data ? (
            <Editable
              onSubmit={(d) => new Promise((resolve, reject) => {
                onChange({
                  ...(data as FileDBValue),
                  name: d,
                })
                  ?.then(resolve)
                  .catch(reject)
              })
              }
              theme={theme}
              value={data?.name || placeholder}
              style={{ width: '100%' }}
              dataCellProps={{
                justifyContent: 'flex-end',
                textOverflow: 'ellipsis',
                minW: 0,
                display: 'block',
              }}
              field={{ type: FieldTypes.TEXT, placeholder, optional: true }}
            />
          ) : (
            <DataCell>{data?.name || placeholder}</DataCell>
          )}
          {/* <Text>{placeholder}</Text> */}
          <Divider mt={1} mb={0} />
          {displayedMetadata?.updated ? (
            <Text textAlign={isMobile ? 'left' : 'right'} fontSize="sm">
              <i>
                Uploaded{' '}
                {getDateTimeString(new Date(displayedMetadata.updated))}
              </i>
            </Text>
          ) : null}
          {onChange && onFileUpload ? (
            <Flex gap={2} mt={2} w={isMobile ? '100%' : 'auto'} align="center">
              {children}
              <Box position="relative">
                <ActionButton
                  size="sm"
                  pl={2}
                  pr={3}
                  bg="white"
                  gap={1}
                  onClick={() => {
                    if (inputRef.current) inputRef.current.click()
                  }}
                >
                  <SvgIcon w="16px" src={uploadIcon} color={colors.green.hex} />
                  <Text>{displayedUrl ? 'Update' : 'Upload'}</Text>
                </ActionButton>
                <input
                  ref={inputRef}
                  style={{
                    marginLeft: 'auto',
                    opacity: 0,
                    width: 10,
                    position: 'absolute',
                  }}
                  onChange={async (e) => {
                    const newFiles = Array.from(e.target.files || [])
                    if (!newFiles.length) return
                    if (newFiles.length > 1 && onUploadMultiple) {
                      await onUploadMultiple(e)
                    } else {
                      await onFileUpload(e)
                    }
                  }}
                  multiple={!!onUploadMultiple}
                  type="file"
                  accept={accept}
                />
              </Box>
              {(displayedUrl || data) && !noDelete ? (
                <IconButton
                  ml={isMobile ? 'auto' : 2}
                  colorScheme="red"
                  isLoading={isDeleting}
                  aria-label="delete"
                  icon={<DeleteIcon />}
                  onClick={handleDelete}
                  size="sm"
                />
              ) : null}
            </Flex>
          ) : null}
        </Flex>
      )}
    </Flex>
  )
}

export const FileView = forwardRef<InputRef, FileViewProps>(PreFileView)

// value is URL returned from firebase
const PreFileInput = (
  { field, input, disabled }: InputProps<FileField>,
  ref: Ref<InputRef>,
) => {
  const { change } = useForm()
  const [, setLoadingState] = useState<'uploading' | 'deleting' | null>(null)
  const { parentPath, fieldIndex } = useMemo(() => {
    if (!input?.name) return { parentPath: null, fieldIndex: null }
    const matches = isInList(input.name)
    if (!matches?.length) {
      return {
        parentPath: input.name.split('.').slice(0, -1).join('.'),
        fieldIndex: null,
      }
    }
    const asInt = parseInt(matches[0].slice(1, -1), 10)
    return {
      fieldIndex: Number.isNaN(asInt) ? null : asInt,
      parentPath: input.name.split('[')[0],
    }
  }, [input])
  const onSingleFileChange = useCallback(
    ([uploaded]: Array<ChangedFile>) => {
      if (uploaded) {
        input?.onChange(uploaded.file)
        input?.onBlur()
      }
    },
    [input],
  )
  const onFileUpload = useUploadDbFileValue(
    onSingleFileChange,
    setLoadingState,
    input?.onFocus,
  )

  const onMultipleFileChange = useCallback(
    (files: Array<ChangedFile>) => {
      if (!parentPath || fieldIndex === null) return
      if (files.length) {
        files.forEach((file, i) => {
          change(`${parentPath}[${fieldIndex + i}]`, file.file)
        })
      }
      input?.onBlur()
    },
    [parentPath, fieldIndex, change, input],
  )

  const handleUploadMultiple = useUploadDbFileValue(
    onMultipleFileChange,
    setLoadingState,
    input?.onFocus,
  )

  return (
    <FileView
      ref={ref}
      noDelete={fieldIndex !== undefined}
      onUploadMultiple={disabled ? undefined : handleUploadMultiple}
      onFileUpload={disabled ? undefined : onFileUpload}
      onChange={disabled ? undefined : (v) => input.onChange(v)}
      field={field}
      data={input.value}
    />
  )
}
export const FileInput = forwardRef<InputRef, InputProps<FileField>>(
  PreFileInput,
)

export const EditableFileView = ({
  value,
  onSubmit,
  field,
  baseStoragePath,
  fieldPathSegments,
  stackProps,
  selectedIndex,
  onSelect,
  focusOnMount,
}: {
  onSubmit?: (data: FileDBValue | null) => Promise<UpdateCallback>
  value?: FileDBValue
  field: FileField
  selectedIndex?: number
  onSelect?: (checked: boolean) => void
  baseStoragePath: string
  fieldPathSegments: Array<string>
  stackProps?: StackProps
  focusOnMount?: boolean
}) => {
  const [uploads, setUploads] = useState<Record<string, UploadProgress>>({})
  const [, setSaving] = useState(false)

  const handleChange = useCallback(
    async (updated: Array<ChangedFile>) => {
      if (!onSubmit) return { error: 'No submit function' }
      if (updated.length) {
        try {
          const processed = await processFileFieldData(
            baseStoragePath,
            fieldPathSegments,
            field,
            updated[0].file,
            value,
            (u) => {
              setUploads({ value: u })
            },
          )
          setUploads({})
          const res = await onSubmit(processed)
          return res
        } catch (err) {
          console.error(err)
          return { error: 'Error saving' }
        }
      } else {
        if (value?.storagePath) {
          await deleteFile(value.storagePath)
        }
        const res = await onSubmit(null)
        return res
      }
    },
    [baseStoragePath, field, fieldPathSegments, onSubmit, value],
  )

  const onUploadStateChange = useCallback((state: FileUploadingState) => {
    switch (state) {
      case 'deleting':
      case 'uploading':
        setSaving(true)
        break
      default:
        setSaving(false)
    }
  }, [])
  const onFileUpload = useUploadDbFileValue(handleChange, onUploadStateChange)
  const inputRef = useRef<InputRef>(null)
  useEffect(() => {
    if (focusOnMount && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focusOnMount])

  return (
    <VStack width="100%" spacing={0} {...stackProps}>
      <FileView
        data={value}
        ref={inputRef}
        onChange={onSubmit}
        field={field}
        onFileUpload={onFileUpload}
      >
        {onSelect ? (
          <Flex gap={2} align="center" pr={1}>
            {selectedIndex !== undefined && selectedIndex !== -1 ? (
              <Center
                w={5}
                h={5}
                bg="white"
                border={`1px solid ${colors.green.hex}`}
                fontSize="xs"
                fontFamily="Hero-New"
                fontWeight={700}
                color={colors.green.hex}
                borderRadius="50%"
              >
                <span>{selectedIndex + 1}</span>
              </Center>
            ) : null}
            <ChakraCheckbox
              colorScheme="green"
              size="lg"
              checked={selectedIndex !== undefined && selectedIndex !== -1}
              onChange={(e) => onSelect(e.target.checked)}
            />
          </Flex>
        ) : null}
      </FileView>
      <UploadProgressView uploads={uploads} />
    </VStack>
  )
}

export const AlternateInput: React.FC<InputProps<AlternateField>> = forwardRef<
  InputRef,
  InputProps<AlternateField>
>(({ field, input, disabled }, ref) => {
  const { placeholder, fieldType, optional } = field as AlternateField
  const { value, onChange } = input
  useEffect(() => {
    if (typeof value !== 'object') onChange({ main: value })
  }, [value, onChange])

  return (
    <Grid width="100%" templateColumns="1fr 1fr" columnGap={3}>
      <Field name={`${input.name}.main`}>
        {(props) => (
          <Input
            disabled={disabled}
            ref={ref}
            field={{ type: fieldType, placeholder, optional } as IField}
            {...props}
          />
        )}
      </Field>
      <Field name={`${input.name}.alternate`}>
        {(props) => (
          <Input
            disabled={disabled}
            field={
              {
                type: fieldType,
                placeholder: `Alternate ${placeholder}`,
                optional: true,
              } as IField
            }
            {...props}
          />
        )}
      </Field>
    </Grid>
  )
})

const SignatureInput: IInputElement<SignatureField> = forwardRef<
  HTMLInputElement,
  InputProps<SignatureField>
>((props) => {
  const {
    input, disabled, style, readOnly,
  } = props
  return (
    <SignatureInputBody
      style={style}
      value={input.value}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      onChange={input.onChange}
      disabled={disabled}
      readOnly={readOnly}
    />
  )
})

export const InputElements: Record<FieldTypes, IInputElement<any>> = {
  [FieldTypes.TEXT]: TextInput,
  [FieldTypes.TEXTAREA]: TextArea,
  [FieldTypes.DROPDOWN]: DropdownInput,
  [FieldTypes.BOOLEAN]: BooleanDropdown,
  [FieldTypes.DATE]: DateInput,
  [FieldTypes.TIME]: TimeInput,
  [FieldTypes.DATETIME]: DateTimeInput,
  [FieldTypes.DOLLAR_AMOUNT]: DollarAmountInput,
  [FieldTypes.PERCENTAGE]: PercentageInput,
  [FieldTypes.ID]: DatabaseDropdown,
  [FieldTypes.PHONE]: PhoneInput,
  [FieldTypes.EMAIL]: EmailInput,
  [FieldTypes.DYNAMIC_DROPDOWN]: DynamicDropdown,
  [FieldTypes.CHECKBOX]: Checkbox,
  [FieldTypes.FILE]: FileInput,
  [FieldTypes.ALTERNATE]: AlternateInput,
  [FieldTypes.NUMBER]: NumberInput,
  [FieldTypes.MULTIPLE_SELECT]: MultipleSelectInput,
  [FieldTypes.SIGNATURE]: SignatureInput,
  [FieldTypes.INITIALS]: InitialsInput,
  // [FieldTypes.NESTED]: NestedDropdown,
}

const makeOnDragEndFunction = (fields: any) => (result: DropResult) => {
  // dropped outside the list
  if (!result.destination) {
    return
  }
  fields.move(result.source.index, result.destination.index)
}

const FormHeader = ({
  children,
  id,
  style,
}: PropsWithChildren<{
  id?: string
  style?: CSSProperties
}>) => (
  <Header
    id={`stage-${id}`}
    style={{
      textAlign: 'left',
      width: '100%',
      fontSize: '15px',
      // borderTop: '1px solid #cdcdcd',
      padding: '5px',
      // paddingTop: '5px',
      fontWeight: 700,
      opacity: 1,
      ...style,
    }}
  >
    {children}
  </Header>
)

const useListItemLabel = (
  index: number,
  itemName: string,
  displayedFieldName: ListField['displayedField'],
) => useMemo(() => {
  if (displayedFieldName === 'alpha') return `${itemName} ${ALPHABET[index]}`
  if (displayedFieldName === 'alpha-index') {
    return `${itemName} ${ALPHABET[index]} (${index + 1})`
  }
  return `${itemName} ${index + 1}`
}, [displayedFieldName, index, itemName])

type ListItemInputProps<
  FieldType = IField | FieldMap,
  Value = FieldMapValue | any,
> = {
  field: FieldType
  active?: boolean
  index: number
  displayedField?: string
  width: string
  readOnly?: boolean
  itemName?: string
  value: Value
  onDelete: () => Promise<any>
  name: string
}
const ListItemMapInput = ({
  field,
  active,
  displayedField = 'name',
  index,
  value,
  itemName = 'Item',
  onDelete,
  name,
  width,
}: ListItemInputProps<FieldMap, FieldMapValue>) => {
  const [expanded, setExpanded] = useState(
    isField(field)
      ? true
      : field.initExpanded || Object.keys(value).length === 0,
  )
  const displayed = useListItemLabel(index, itemName, displayedField)

  return (
    <Draggable key={name} index={index} draggableId={name}>
      {(provided, snapshot) => (
        <Box
          width={width}
          ref={provided.innerRef}
          overflow="hidden"
          bg="white"
          border="1px solid #cdcdcd"
          borderRadius="4px"
          boxShadow={
            snapshot.isDragging
              ? '0 0 4px rgba(0,0,0,0.25)'
              : '0 0 4px rgba(0,0,0,0.1)'
          }
          background={snapshot.isDragging ? 'white' : undefined}
          {...provided.draggableProps}
        >
          <Flex
            align="center"
            width="100%"
            bg="gray.50"
            borderTopRadius="4px"
            p={1}
            justify="space-between"
            // borderBottom='1px solid #eee'
          >
            <Flex align="center">
              <Flex px={2} {...provided.dragHandleProps}>
                <DragHandleIcon color="gray.600" w={3} h={3} />
              </Flex>
              {/* <img
                {...provided.dragHandleProps}
                src={dragIcon}
                style={{ height: '85%', padding: '5px' }}
                alt='drag'
              /> */}
              <Text fontWeight={500} color="green.600">
                {displayed === 'None' ? `${itemName} ${index + 1}` : displayed}
              </Text>
            </Flex>
            <ViewButton
              isOpen={expanded}
              onClick={() => setExpanded(!expanded)}
            />
            <HeaderButton label="Delete" size="medium" active>
              <DeleteButton itemName={itemName} onDelete={onDelete} />
              {/* <img
            src={deleteIcon}
            onClick={onDelete}
            style={{ height: '85%', padding: '5px' }}
            alt='delete'
          /> */}
            </HeaderButton>
          </Flex>
          <Collapse style={{ width: '100%' }} in={expanded}>
            <Flex w="100%" borderTop="1px solid #cdcdcd" pb={1} px={2}>
              <FormElement
                name={name}
                index={index}
                active={active}
                field={field}
              />
            </Flex>
          </Collapse>
        </Box>
      )}
    </Draggable>
  )
}

const ListItemFieldInput = forwardRef<
  InputRef,
  ListItemInputProps<IField, any>
>(
  (
    {
      field,
      active,
      displayedField = 'name',
      index,
      itemName = 'Item',
      onDelete,
      readOnly,
      width,
      name,
    },
    ref,
  ) => {
    const displayed = useListItemLabel(index, itemName, displayedField)

    const isFileField = field.type === FieldTypes.FILE
    return (
      <HStack
        bg="white"
        boxShadow="md"
        w={width}
        py={isFileField ? 0 : 1}
        pl={isFileField ? 0 : 2}
        pr={2}
        spacing={0}
        borderRadius={4}
      >
        <Box minW="0" flex={1}>
          <FormElement
            name={name}
            readOnly={readOnly}
            ref={ref}
            index={index}
            active={active}
            field={{ ...field, placeholder: displayed }}
            style={{ padding: 0 }}
          />
        </Box>
        <DeleteButton noConfirm itemName={itemName} onDelete={onDelete} />
      </HStack>
    )
  },
)

const ListItemInput = forwardRef<
  InputRef,
  ListItemInputProps<IField | FieldMap, any>
>(({ field, ...props }, ref) => {
  if (isField(field)) {
    return <ListItemFieldInput ref={ref} field={field} {...props} />
  }
  return <ListItemMapInput field={field} {...props} />
})

const ListInput: React.FC<ListInputProps> = (props) => {
  const {
    field, fields, active, name, readOnly,
  } = props as ListInputProps
  const { itemFields, itemName, horizontalConfig } = field
  const { value } = fields
  const listItemField = useMemo(() => {
    if (isField(itemFields)) return itemFields
    return { ...itemFields, initExpanded: true }
  }, [itemFields])

  const fieldRefs = useRef<Array<InputRef | null>>([])

  const itemWidth = typeof horizontalConfig?.itemWidth === 'number'
    ? `${horizontalConfig.itemWidth - 10}px`
    : '100%'
  return (
    <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
      <Droppable droppableId={name}>
        {(provided) => (
          <Flex
            w="100%"
            ref={provided.innerRef}
            {...provided.droppableProps}
            flexFlow="column"
            style={{
              alignItems: 'flex-start',
            }}
          >
            <Flex
              align={horizontalConfig ? 'space-between' : 'flex-start'}
              w="100%"
              flexFlow={horizontalConfig ? 'row wrap' : 'column'}
            >
              {fields.length ? (
                fields.map((fieldName, index) => (
                  <ExpandOnMount
                    style={{
                      width: horizontalConfig?.itemWidth || '100%',
                      padding: '0.25rem',
                    }}
                    key={fieldName}
                  >
                    <ListItemInput
                      ref={(el) => {
                        fieldRefs.current[index] = el
                      }}
                      displayedField={field.displayedField}
                      index={index}
                      name={fieldName}
                      readOnly={readOnly}
                      itemName={itemName}
                      width={itemWidth}
                      value={value?.[index]}
                      active={active}
                      field={listItemField}
                      onDelete={async () => {
                        fields.remove(index)
                        return true
                      }}
                    />
                  </ExpandOnMount>
                ))
              ) : (
                <Text fontStyle="italic" color="gray.600" fontSize="sm">
                  No {itemName}s added
                </Text>
              )}
            </Flex>
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>
      <SolidActionButton
        mt={2}
        size="sm"
        onClick={() => {
          const oldLength = fields.length || 0
          fields.push(isField(itemFields) ? '' : { createdOn: Date.now() })
          setTimeout(() => {
            fieldRefs.current[oldLength]?.focus()
          }, 10)
        }}
      >
        + Add {itemName}
      </SolidActionButton>
    </DragDropContext>
  )
}

interface FormFieldMapProps extends FormElementProps {
  field: FieldMap
}

interface FormFieldProps {
  field: IField | ListField
  id?: string
  basePath?: string
  active?: boolean
  disabled?: boolean
  style?: CSSProperties
  readOnly?: boolean
}

interface FormDefaultFieldProps extends FormFieldProps {
  field: IField
}

interface FormListFieldProps extends FormFieldProps {
  field: ListField
}

const FormListField: React.FC<FormListFieldProps> = ({
  field,
  id,
  basePath,
  active,
  readOnly,
}) => {
  const { labels, hints, name } = field

  const populatedLabels = usePopulatedLabels(labels)
  const populatedHints = usePopulatedHints(hints)
  const {
    theme, hideHints, hideLabels, placeholderAbove,
  } = useContext(ThemeContext)
  return (
    <Box mb={2} width="100%">
      <FieldArray name={`${basePath}${id}`}>
        {(props) => {
          const arrayError = props.meta.error || props.meta.submitError
          return (
            <Container
              style={{
                height: 'auto',
                marginBottom: theme === 'detailed' ? 2 : 0,
                alignItems: 'flex-start',
              }}
            >
              {placeholderAbove ? (
                <Text fontSize="xs" color="gray.600" fontWeight={600}>
                  {name}
                </Text>
              ) : null}
              {!hideLabels ? <Labels labels={populatedLabels} /> : null}
              {!hideHints ? <Hints hints={populatedHints} /> : null}
              <ListInput
                readOnly={readOnly}
                basePath={basePath || ''}
                name={id || ''}
                active={active}
                field={field}
                {...props}
              />
              <CollapseError
                error={
                  props.meta.touched && typeof arrayError === 'string'
                    ? arrayError
                    : undefined
                }
                style={{ borderRadius: '6px' }}
              />
            </Container>
          )
        }}
      </FieldArray>
    </Box>
  )
}

const FormDefaultField = forwardRef<InputRef, FormDefaultFieldProps>(
  ({
    field, id, basePath, style, disabled, readOnly,
  }, ref) => {
    const { theme } = useContext(ThemeContext)

    const collectionItems = useCollections()
    const formatFieldValue: FieldFormatter = field.format || fieldFormat[field.type] || ((v) => v)
    const parseValue = field.parse || fieldParse[field.type] || ((v) => v)
    const format = field.format || fieldFormat[field.type]
      ? (val?: string) => formatFieldValue(val, field, collectionItems)
      : undefined
    const parse = field.parse || fieldParse[field.type]
      ? (val?: string) => parseValue(val)
      : undefined

    return (
      <Field
        format={format}
        parse={parse}
        // validate={validate}
        name={`${basePath}${id}`}
        render={({ input, meta }) => {
          const C = InputElements[field.type]
          if (!C) {
            console.error(`Cant find component for field type: ${field.type}`)
            return undefined
          }
          return (
            <Container
              px={theme === 'detailed' ? 1 : 0}
              style={{
                height: 'auto',
                opacity: disabled ? 0.7 : 1,
                alignItems: 'flex-start',
              }}
            >
              <Input
                ref={ref}
                style={style}
                meta={meta}
                disabled={disabled}
                readOnly={readOnly}
                field={field}
                input={input}
              />
            </Container>
          )
        }}
      />
    )
  },
)

const FormField = forwardRef<InputRef, FormFieldProps>(
  ({ field, id = '', ...props }, ref) => {
    if (isListField(field)) {
      return <FormListField id={id} {...props} field={field as ListField} />
    }
    return (
      <FormDefaultField ref={ref} id={id} {...props} field={field as IField} />
    )
  },
)

const FormFieldMap: React.FC<FormFieldMapProps> = ({
  active,
  name: id = '',
  field,
  index,
  basePath = '',
  readOnly,
  disabled,
  root,
  style,
}) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Container
      alignItems="flex-start"
      style={{
        height: 'auto',
        width: '100%',
        // padding: index === undefined ? '5px' : undefined,
        // paddingBottom: 0,
        // borderBottom: !index ? '1px solid #cdcdcd' : undefined,
        // opacity: active ? 1 : 0.7,
        transition: 'opacity 500ms',
        display: 'block',
      }}
    >
      {index === undefined && theme !== 'basic' ? (
        <FormHeader
          style={{
            fontSize: root ? '18px' : '16px',
            // color: root ? '#111' : '#333',
            fontWeight: 400,
            paddingTop: root ? '10px' : '5px',
            color: root ? '#777' : colors.green.hex,
          }}
          id={`stage-${id}`}
        >
          {field.name}
        </FormHeader>
      ) : null}
      <Box p={2}>
        {Object.keys(field.children).map((fieldId) => (
          <FormElement
            disabled={disabled}
            readOnly={readOnly}
            active={active}
            key={fieldId}
            style={style}
            field={field.children[fieldId]}
            basePath={`${basePath ? `${basePath}` : ''}${id ? `${id}.` : ''}`}
            name={fieldId}
          />
        ))}
      </Box>
    </Container>
  )
}

export const FormElement = forwardRef<InputRef, FormElementProps>(
  (props, ref) => {
    const {
      field,
      name,
      basePath = '',
      active,
      disabled,
      index,
      root,
      style,
      readOnly,
    } = props
    const { hideInfo } = useContext(ThemeContext)
    let C: React.ReactElement = <></>
    if (isInfoStage(field)) {
      if (!hideInfo) {
        C = (
          <DBTextStage
            version={field.editorVersion || 'v1'}
            content={{ text: field.data }}
          />
        )
      }
    } else if (isListField(field) || isField(field)) {
      C = (
        <FormField
          ref={ref}
          disabled={disabled}
          active={active}
          readOnly={readOnly}
          style={style}
          field={field}
          basePath={basePath}
          id={name}
        />
      )
    } else {
      C = (
        <FormFieldMap
          root={root}
          index={index}
          readOnly={readOnly}
          style={style}
          disabled={disabled}
          {...props}
          field={field as FieldMap}
        />
      )
    }

    const { condition } = field || {}
    return condition ? (
      <Condition condition={condition} basePath={basePath}>
        {C}
      </Condition>
    ) : (
      C
    )
  },
)
const Input = forwardRef<InputRef, InputProps<IField>>(
  ({
    field, input, meta, style, disabled, readOnly,
  }, ref) => {
    const {
      type, labels, hints, placeholder, presets,
    } = field
    const isAlternateField = field.type === FieldTypes.ALTERNATE
    const isCheckboxField = field.type === FieldTypes.CHECKBOX
    const populatedLabels = usePopulatedLabels(
      !isAlternateField ? labels : undefined,
    )
    const populatedHints = usePopulatedHints(
      !isAlternateField ? hints : undefined,
    )
    const InputElement = useMemo(() => {
      if (InputElements[type]) {
        return InputElements[type]
      }
      return undefined
    }, [type])

    const {
      theme, hideLabels, hideHints, placeholderAbove, tooltipError,
    } = useContext(ThemeContext)

    const hintLabel = useMemo(
      () => populatedHints?.map(({ text }) => text).join(' | '),
      [populatedHints],
    )

    // TODO: make error a badge
    const body = InputElement ? (
      <InputElement
        // basic={basic}
        style={style}
        disabled={disabled}
        readOnly={readOnly}
        ref={ref}
        meta={meta}
        field={field}
        input={input}
      />
    ) : null

    const fullBody = (
      <Box pb={1} width="100%">
        {tooltipError ? (
          <Tooltip
            placement="top"
            hasArrow
            color="white"
            bg="red.500"
            label={tooltipError ? meta?.error : undefined}
            isOpen={tooltipError && meta.touched && !!meta?.error}
          >
            <Box width="100%">{body}</Box>
          </Tooltip>
        ) : (
          body
        )}
      </Box>
    )
    return (
      <Container
        style={{
          height: 'auto',
          width: '100%',
          alignItems: 'flex-start',
        }}
      >
        {!isAlternateField
        && !isCheckboxField
        && !labels
        && placeholderAbove !== false
        && (theme !== 'basic' || placeholderAbove) ? (
          <span
            style={{
              marginBottom: '2px',
              fontSize: '13px',
              color: '#666666',
              fontWeight: 600,
              whiteSpace: 'pre-wrap',
            }}
          >
            {placeholder}
          </span>
          ) : null}
        {!hideLabels && labels ? <Labels labels={populatedLabels} /> : null}
        {!hideHints && hints ? <Hints hints={populatedHints} /> : null}
        {presets ? (
          <Presets field={field} onClickPreset={(p) => input.onChange(p)} />
        ) : null}
        {hideHints ? (
          <Tooltip
            placement="top-start"
            bg="#808080"
            isOpen={!!hintLabel && meta.active}
            label={hintLabel}
          >
            {fullBody}
          </Tooltip>
        ) : (
          fullBody
        )}

        {!tooltipError ? (
          <CollapseError
            style={{ borderRadius: '6px' }}
            error={meta.touched && (meta.submitError || meta.error)}
          />
        ) : null}
      </Container>
    )
  },
)

const EditableResetter = ({
  isEditing,
  value,
  field,
}: {
  isEditing: boolean
  value: any
  field: IFormElement
}) => {
  const { initialize, focus } = useForm()
  useEffect(() => {
    if (isField(field)) {
      if (!isEditing) {
        if (isField(field)) {
          initialize({ value })
        } else initialize(value)
      }
    }
  }, [isEditing, value, initialize, field, focus])

  return null
}

type EditableBodyProps = {
  id: string
  field: IFormElement
  onSubmit: FormRenderProps['handleSubmit']
  onClose: () => void
  stackProps?: StackProps
}

const EditableBody = forwardRef<InputRef, EditableBodyProps>(
  ({
    id, field, onSubmit, onClose, stackProps,
  }, ref) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const handleSubmit = useCallback(
      async (data: FieldMapValue) => {
        setIsSubmitting(true)
        const res = await onSubmit(data)
        setIsSubmitting(false)
        return res
      },
      [onSubmit],
    )

    // const inputRef = useRef<InputRef>(null)
    useEffect(() => {
      if (!ref) return;
      (ref as MutableRefObject<InputRef>).current?.focus()
    }, [ref])

    return (
      <HStack
        flex={1}
        zIndex={2}
        px={1}
        // pt={1}
        borderRadius={4}
        boxShadow="md"
        minW="220px"
        w="100%"
        // bg='white'
        bg="white"
        align="center"
        onClick={(e) => e.stopPropagation()}
        {...stackProps}
      >
        <Box w="100%" minW="0" flex={1}>
          <FormElement ref={ref} active name={id} field={field} />
        </Box>
        <Stack
          direction={field.type === FieldTypes.TEXTAREA ? 'column' : 'row'}
          py={1}
          minW="auto"
          align="center"
          justify="center"
          spacing={1}
        >
          <IconButton
            aria-label="Save"
            icon={<CheckIcon />}
            w="22px"
            h="22px"
            minH="0"
            color="white"
            size="xs"
            bg="green.400"
            isLoading={isSubmitting}
            onClick={handleSubmit}
          />
          <IconButton
            aria-label="Cancel"
            color="white"
            w="22px"
            h="22px"
            minH="0"
            icon={<CloseIcon />}
            size="xs"
            bg="gray.400"
            onClick={onClose}
          />
        </Stack>
      </HStack>
    )
  },
)

export const EditablePopover = ({
  id,
  field,
  handleSubmit,
}: PropsWithChildren<{
  id: string
  field: IFormElement
  handleSubmit: FormRenderProps['handleSubmit']
}>) => {
  const {
    openedOn: popoverId,
    registerPopover,
    onClose,
  } = useContext(EditableStateContext)
  const [openedOn, setOpenedOn] = useState<number | null>(null)
  const form = useForm()
  const onSubmit = useCallback(async () => {
    const res = await handleSubmit()
    if (!res) onClose()
    return res
  }, [handleSubmit, onClose])
  return (
    <Popover
      strategy="fixed"
      onOpen={() => {
        const now = Date.now()
        setOpenedOn(now)
        registerPopover(now)
      }}
      closeOnBlur={false}
      isOpen={!!popoverId && popoverId === openedOn}
      onClose={() => {
        onClose()
        form.restart()
      }}
    >
      {/* @ts-ignore */}
      <PopoverTrigger>
        <IconButton
          aria-label="edit"
          icon={<EditIcon />}
          size="xs"
          color="gray.600"
          variant="ghost"
        />
      </PopoverTrigger>
      <PopoverContent w="auto">
        <PopoverArrow />
        <PopoverBody p={1}>
          <EditableBody
            onClose={onClose}
            id={id}
            field={field}
            onSubmit={onSubmit}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

type StandaloneInputProps<T extends IField = IField> = {
  field: T
  value?: any
  onChange: (v?: any) => void
  onBlur?: () => void
  onFocus?: () => void
  theme?: 'basic' | 'detailed'
  style?: CSSProperties
  error?: string
}
const BaseStandaloneInput = <F extends IField>(
  {
    onChange,
    value,
    field,
    onBlur,
    onFocus,
    error,
    style,
    theme,
  }: StandaloneInputProps<F>,
  ref: ForwardedRef<InputRef>,
) => {
  const [isActive, setActive] = useState(false)
  const formattedValue = useFormattedValue(field, value)
  const parse = field.parse || fieldParse[field.type]
  const val = useMemo(() => {
    if (!value) return value
    return field.type === FieldTypes.CHECKBOX ? value : formattedValue
  }, [field, formattedValue, value])
  return (
    <ThemeContext.Provider
      value={{ theme: theme || 'basic', placeholderAbove: false }}
    >
      <Input
        ref={ref}
        field={field}
        style={style}
        input={{
          name: '',
          onChange: (e) => {
            if (e?.target) onChange(parse ? parse(e.target?.value) : e.target?.value)
            else onChange(parse ? parse(e) : e)
          },
          value: val,
          onBlur: () => {
            setActive(false)
            if (onBlur) onBlur()
          },
          onFocus: () => {
            setActive(true)
            if (onFocus) onFocus()
          },
        }}
        meta={{ error, active: isActive }}
      />
    </ThemeContext.Provider>
  )
}

export const StandaloneInput = forwardRef<InputRef, StandaloneInputProps>(
  BaseStandaloneInput,
)

const CorrectionPopover = ({ formattedValue }: { formattedValue?: string }) => (
  <Popover strategy="fixed" trigger="hover" placement="top">
    {/* @ts-ignore */}
    <PopoverTrigger>
      <QuestionOutlineIcon
        aria-label="Original answer"
        cursor="pointer"
        width={3}
        ml={2}
      />
    </PopoverTrigger>
    <Portal>
      <PopoverContent bg="gray.50" w="auto">
        <PopoverArrow />
        <PopoverBody>
          <Flex direction="column">
            <Text
              fontSize="sm"
              color="gray.600"
              lineHeight={1}
              fontWeight={500}
            >
              Original answer by patient
            </Text>
            <Text>
              {formattedValue !== undefined ? formattedValue : 'None'}
            </Text>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Portal>
  </Popover>
)

export const Editable: React.FC<EditableProps> = ({
  onSubmit,
  onDelete,
  field,
  value,
  inGrid,
  correction,
  small,
  dataCellProps,
  theme,
  id = 'value',
  previewIsButton,
  parentValue,
  onEditHovered,
  index,
  fieldPathSegments,
  baseStoragePath,
  style,
  initEditing,
  editableStackProps,
  openCallback,
  closeCallback,
}) => {
  const { hideRedFlags } = useContext(DataViewContext)
  const displayed = useMemo(
    () => (correction === undefined || correction === null ? value : correction),
    [correction, value],
  )

  const [openedOn, setOpenedOn] = useState<number | null>(null)
  const {
    openedOn: popoverId,
    registerPopover,
    onClose,
  } = useContext(EditableStateContext)
  const handleClose = useCallback(() => {
    onClose()
    if (closeCallback) closeCallback()
  }, [closeCallback, onClose])

  const inputRef = useRef<InputRef>(null)

  const onOpen = useCallback(() => {
    const now = Date.now()
    setOpenedOn(now)
    registerPopover(now)
    if (inputRef.current) inputRef.current.focus()
    if (onEditHovered) onEditHovered(false)
    if (openCallback) openCallback()
  }, [registerPopover, openCallback, onEditHovered])

  const initEditingRef = useRef(initEditing)
  const onOpenRef = useRef(onOpen)
  useEffect(() => {
    if (initEditingRef.current && onOpenRef.current) onOpenRef.current()
  }, [])

  const collection = useMemo(() => {
    switch (field.type) {
      case FieldTypes.ID:
      case FieldTypes.DYNAMIC_DROPDOWN:
        return field.collection
      default:
        return null
    }
  }, [field])

  const itemId = useMemo(() => {
    if (typeof displayed !== 'string') return null
    return isAlphaNumeric(displayed) ? displayed : null
  }, [displayed])
  const { item } = useCollectionItem(collection, itemId)

  const isEditing = useMemo(
    () => !!(popoverId && popoverId === openedOn),
    [popoverId, openedOn],
  )

  const submit = useCallback(
    async (data: FieldMapValue): Promise<ValidationErrors> => {
      const v = data[id] || null
      if (onSubmit) {
        try {
          const { success, error } = await onSubmit(v)
          if (success) {
            handleClose()
            return undefined
          }
          if (error) {
            return { [id]: error }
          }
          return undefined
        } catch (err) {
          return { [id]: 'An error occurred' }
        }
      } else {
        return {
          [id]: 'An error occurred',
        }
      }
    },
    [handleClose, id, onSubmit],
  )

  const formattedValue = useFormattedValue(field, value)
  const formattedCorrection = useFormattedValue(field, correction)
  const redFlagReason = useMemo(() => {
    if (hideRedFlags) return undefined
    if (field.type === FieldTypes.ID) {
      return item?.redFlaggedReason
        ? item?.redFlaggedReason || 'Red flagged'
        : undefined
    }
    return getFieldIsRedFlagged(field, displayed)
  }, [field, hideRedFlags, item, displayed])

  const validate = useCallback(
    (v: FieldMapValue) => {
      const parsed = v[id]
      if (!parsed && field.optional) return undefined
      const validateFunc = field.validate || defaultValidation[field.type]
      const err = validateFunc(parsed, field)
      if (err) {
        return { [id]: err }
      }
      return undefined
    },
    [field, id],
  )

  const isCorrection = useMemo(
    () => correction !== undefined && correction !== value,
    [correction, value],
  )

  const displayedText = useMemo(
    () => (isCorrection ? formattedCorrection : formattedValue),
    [isCorrection, formattedCorrection, formattedValue],
  )

  if (field.type === FieldTypes.FILE) {
    if (!baseStoragePath) throw new Error('baseStoragePath is required for file fields')

    return (
      <EditableFileView
        baseStoragePath={baseStoragePath}
        value={isCorrection ? correction : value}
        field={field}
        fieldPathSegments={fieldPathSegments || []}
        onSubmit={
          onSubmit
            ? async (data) => {
              const res = await onSubmit(data)
              if (res) handleClose()
              return res
            }
            : undefined
        }
      />
    )
  }
  return field.type === FieldTypes.CHECKBOX ? (
    <EditableCheckbox
      value={displayed}
      index={index}
      inGrid={inGrid}
      field={field}
      style={style}
      id={id}
      onSubmit={onSubmit}
    />
  ) : (
    <ThemeContext.Provider
      value={{
        hideHints: true,
        hideLabels: true,
        placeholderAbove: false,
        tooltipError: true,
        theme: theme || 'basic',
      }}
    >
      <Flex
        // flexGrow={1}
        align="flex-start"
        style={style}
        width={field.type === FieldTypes.TEXTAREA ? '100%' : 'auto'}
        sx={
          index !== undefined
            ? {
              [`:nth-of-type(${inGrid ? '4n - 2' : '2n - 1'})`]: {
                background: 'rgb(245,245,245)',
              },
            }
            : undefined
        }
        gap={0}
        py={field.type === FieldTypes.TEXTAREA ? 1 : 0}
      >
        {isEditing ? (
          <Form
            validate={validate}
            mutators={{ ...arrayMutators }}
            initialValues={{
              ...parentValue,
              [id]: isCorrection ? correction : value,
            }}
            onSubmit={submit}
          >
            {({ handleSubmit }) => (
              <>
                <EditableResetter
                  field={field}
                  isEditing={isEditing}
                  value={value}
                />
                <EditableBody
                  stackProps={editableStackProps}
                  onSubmit={handleSubmit}
                  field={field}
                  ref={inputRef}
                  id={id}
                  onClose={handleClose}
                />
              </>
            )}
          </Form>
        ) : (
          <DataCell
            opacity={!displayedText || displayedText === 'None' ? 0.7 : 1}
            py={1}
            px={1}
            as={previewIsButton ? 'button' : 'div'}
            onClick={previewIsButton && onSubmit ? onOpen : undefined}
            flex={1}
            // fontWeight={500}
            whiteSpace={
              field.type === FieldTypes.TEXTAREA ? 'pre-wrap' : 'nowrap'
            }
            overflow="hidden"
            color={
              !field.optional && correction === undefined && value === undefined
                ? 'red'
                : undefined
            }
            {...dataCellProps}
          >
            {!onSubmit || (displayedText && displayedText !== 'None')
              ? displayedText
              : field.placeholder}
            {isCorrection ? (
              <CorrectionPopover formattedValue={formattedValue} />
            ) : null}
            {redFlagReason ? (
              <ProfileRedFlagPopover
                boxProps={{ bg: 'transparent' }}
                redFlags={{
                  flag: {
                    header: field.placeholder,
                    reason: redFlagReason,
                  },
                }}
                ml={2}
              />
            ) : null}
          </DataCell>
        )}
        {previewIsButton || isEditing || !onSubmit ? null : (
          <HStack pt={small ? 0 : 1} px={1} spacing={0}>
            <IconButton
              aria-label="Edit"
              onClick={(e) => {
                e.stopPropagation()
                onOpen()
              }}
              onPointerEnter={() => onEditHovered?.(true)}
              onPointerLeave={() => onEditHovered?.(false)}
              variant="ghost"
              size="xs"
              icon={<EditIcon position="relative" />}
            />
            {value && onDelete ? (
              <DeleteButton itemName={field.placeholder} onDelete={onDelete} />
            ) : null}
          </HStack>
        )}
      </Flex>
    </ThemeContext.Provider>
  )
}

export const EditableRow = ({
  label,
  stackProps,
  index,
  small,
  labelProps,
  ...props
}: EditableProps & {
  label: string
  small?: boolean
  stackProps?: StackProps
  labelProps?: TextProps
}) => {
  const [editHovered, setEditHovered] = useState(false)
  return (
    <HStack
      sx={
        index !== undefined
          ? {
            ':nth-of-type(2n)': {
              background: 'rgb(245,245,245)',
            },
          }
          : undefined
      }
      w="100%"
      alignItems="flex-start"
      spacing={1}
      py={small ? 0 : 1}
      px={small ? 2 : 3}
      transition="all 300ms"
      borderRadius={6}
      border={`1px dashed ${editHovered ? `${colors.green.hex}ff` : `${colors.green.hex}00`} `}
      {...stackProps}
    >
      <DataLabel pl={1} pt="1px" {...labelProps}>
        {label}
      </DataLabel>
      <Editable
        small
        onEditHovered={setEditHovered}
        style={{ flex: 1, minWidth: 0 }}
        dataCellProps={{
          pt: 1,
          lineHeight: props.field.type === FieldTypes.TEXTAREA ? 1.3 : 1,
          whiteSpace:
            props.field.type === FieldTypes.TEXTAREA ? 'pre-wrap' : 'nowrap',
          fontSize: '0.9rem',
        }}
        {...props}
      />
    </HStack>
  )
}

export default Input
