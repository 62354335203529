import {
  AssessmentInvoiceSnippet,
  FieldMapValue,
  PaymentReceived,
  UpdateCallback,
} from '@hb/shared/types'
import { addReceivedPayment } from '../../../../backend/functions'
import { validatePaymentReceived } from './fields'

export const submitReceivedPayment = async (
  assessmentId: string,
  id: string,
  data: FieldMapValue,
  sendMessageToHb: boolean,
): Promise<UpdateCallback> => {
  if (!id) return { error: 'No ID' }
  const validationErrors = validatePaymentReceived(data)
  if (validationErrors) return validationErrors
  const {
    amount, paidBy, date, source, eobFile, checkFile, notes, type,
  } = data
  const payment: PaymentReceived = {
    paidBy,
    amount,
    date,
    source,
    type,
  }
  if (eobFile) payment.eobFile = eobFile
  if (checkFile) payment.checkFile = checkFile
  if (notes) payment.notes = notes

  const createdOn = Number.parseInt(id, 10)
  if (Number.isNaN(createdOn)) {
    return { error: 'Invalid ID' }
  }
  return addReceivedPayment({
    assessmentId,
    payment,
    createdOn,
    sendMessageToHb: !!sendMessageToHb,
  })
    .then(() => ({ success: 'Payment added' }))
    .catch((e: any) => ({ error: e?.message || 'Error adding payment' }))
}
export const getPaymentInvoiceSnippets = (
  assessmentInvoiceSnippets: Record<string, AssessmentInvoiceSnippet>,
  paymentId: string,
) => Object.entries(assessmentInvoiceSnippets).reduce(
  (acc, [invoiceId, invoiceSnippet]) => {
    if (invoiceSnippet?.chargedPayments?.[paymentId]) {
      return {
        ...acc,
        [invoiceId]: invoiceSnippet,
      }
    }
    return acc
  },
    {} as Record<string, AssessmentInvoiceSnippet>,
)
