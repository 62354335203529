import {
  Box,
  Button,
  Center,
  CircularProgress,
  Collapse,
  Flex,
  Image,
  VStack,
} from '@chakra-ui/react'
import { UploadProgress } from '@hb/shared'
import { colors } from '@hb/shared/constants'
import React, { useEffect, useRef, useState } from 'react'
import personIcon from '../../icons/person.svg'
import uploadIcon from '../../icons/upload.svg'
import { ActionButton } from '../Buttons'
import { UploadProgressView } from '../forms/FinalForm/UploadProgressView'
import { CollapseHorizontal } from './CollapseHorizontal'

export const SimpleImageUpload = ({
  onUpload,
  downloadPath,
  onCancel,
  label,
  src,
  loading: _loading,
  recentlyUploaded,
  vertical,
}: {
  onUpload: (uploaded: File, onUploadProgress: (v: number) => void) => Promise<void>
  onCancel?: () => void
  downloadPath?: string | null
  label: string
  src?: string | null
  loading?: boolean
  recentlyUploaded?: boolean
  vertical?: boolean
}) => {
  const [file, setFile] = useState<File | null>(null)
  const [dataUrl, setDataUrl] = useState<string | null>(null)
  const reader = useRef(new FileReader())
  const [uploading, setUploading] = useState(false)
  useEffect(() => {
    if (file) {
      reader.current.readAsDataURL(file)
      reader.current.onload = () => {
        setDataUrl(reader.current.result as string)
      }
    } else {
      setDataUrl(null)
    }
  }, [file, downloadPath])
  const inputRef = useRef<HTMLInputElement>(null)
  const loading = uploading || recentlyUploaded || !!_loading
  const [uploads, setUploads] = useState<Record<string, UploadProgress>>({})

  const uploadBody = (
    <VStack>
      <Button
        w="100%"
        bg="whiteAlpha.800"
        _hover={{ bg: 'gray.50' }}
        border={`1px solid ${colors.green.hex}`}
        color={colors.green.hex}
        onClick={() => inputRef.current?.click()}
        gap={1}
      >
        <Image src={uploadIcon} width="20px" />
        Select File
      </Button>
      <Collapse style={{ width: '100%' }} in={!!file}>
        <Button
          w="100%"
          onClick={async () => {
            if (file) {
              setUploading(true)
              try {
                await onUpload(file, (v) => setUploads({ value: { complete: false, label, progress: v } }))
                setUploads({})
                setFile(null)
              } catch (err) {
                console.error(err)
              }
              setUploading(false)
            }
          }}
          colorScheme="green"
          pointerEvents={file ? 'auto' : 'none'}
          opacity={file ? 1 : 0}
          transition="all 250ms"
        >
          Confirm
        </Button>
      </Collapse>
      <UploadProgressView uploads={uploads}/>
    </VStack>
  )

  return (
    <Flex gap={3} align="center" flexFlow={vertical ? 'column' : 'row'} py={1}>
      {onCancel ? (
        <Box w="100%">
          <ActionButton
            w="100%"
            onClick={(e) => {
              e.stopPropagation()
              onCancel()
            }}
            colorScheme="gray"
            color="gray.500"
            size="sm"
            bg="gray.50"
            transition="all 250ms"
          >
            Cancel
          </ActionButton>
        </Box>
      ) : null}
      <Center
        borderRadius={6}
        bg="gray.50"
        position="relative"
        flexFlow={vertical ? 'column' : 'row'}
        overflow="hidden"
        boxShadow="1px 1px 3px #00000077"
      >
        <input
          type="file"
          ref={inputRef}
          accept="image/*"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
          }}
          onChange={(e) => {
            const { files } = e.target
            if (files && files.length) {
              setFile(files[0])
            }
          }}
        />
        <Image
          src={dataUrl || src || personIcon}
          w="120px"
          objectFit="cover"
          h="120px"
        />
        <Center
          bg="blackAlpha.400"
          position="absolute"
          pointerEvents="none"
          opacity={loading ? 1 : 0}
          transition="opacity 300ms ease"
          top={0}
          left={0}
          w="100%"
          h="100%"
        >
          <CircularProgress
            color={colors.green.hex}
            isIndeterminate={loading}
            size={7}
            position="absolute"
          />
        </Center>
      </Center>
      {vertical ? (
        <Collapse style={{ width: '100%' }} in={!loading}>
          {uploadBody}
        </Collapse>
      ) : (
        <CollapseHorizontal width={120} in={!loading}>
          {uploadBody}
        </CollapseHorizontal>
      )}
    </Flex>
  )
}
