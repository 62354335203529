import {
  Popover,
  PopoverContent,
  PopoverProps,
  Portal,
} from '@chakra-ui/react'
import {
  FieldMap,
  FieldMapValue,
  OnUploadProgress,
  UpdateCallback,
} from '@hb/shared'

import { FormApi, ValidationErrors } from 'final-form'
import React, {
  PropsWithChildren, useCallback, useContext, useMemo,
} from 'react'
import { FormProps } from 'react-final-form'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { SimpleForm } from '../forms/FinalForm/SimpleForm'
import { DefaultModal, DefaultModalProps } from '../Modals/DefaultModal'
import { PopoverTrigger } from '../PopoverTrigger'

export type FieldInterface<T, Data extends FieldMapValue = FieldMapValue> = {
  header?: string
  loading?: boolean
  submitText?: string
  cancelText?: string
  data?: Data
  readOnly?: boolean
  formProps?: Partial<FormProps<Data>>
  updateField?: (path: string, data?: Data) => Promise<UpdateCallback>
  baseStoragePath?: string
  closeOnSubmit?: boolean
  canSubmitClean?: boolean
  onSubmit: (
    data: Data,
    onUploadProgress: OnUploadProgress,
    form: FormApi<Data>
  ) => Promise<UpdateCallback>
  validate?: (data: Data) => Promise<ValidationErrors> | ValidationErrors
} & T

export type EditModalProps<Data extends FieldMapValue = FieldMapValue> =
  PropsWithChildren<FieldInterface<Omit<DefaultModalProps, 'render'>, Data>>

export type FormPopoverProps<Data extends FieldMapValue = FieldMapValue> =
  FieldInterface<PopoverProps, Data> & {
    focusLock?: boolean
    field: FieldMap
  }

export const FormPopover = <Data extends FieldMapValue>({
  data,
  onSubmit,
  children,
  field,
  ...props
}: FormPopoverProps<Data>) => (
  <Popover strategy='fixed' isLazy closeOnBlur={false} returnFocusOnClose {...props}>
    {({ onClose }) => (
      <>
        {/* @ts-ignore */}
        <PopoverTrigger>{children}</PopoverTrigger>
        <Portal>
          <PopoverContent width={['100%', 350, 500]}>
            <SimpleForm<Data>
              value={data}
              onSubmit={onSubmit}
              field={field}
              onCancel={onClose}
              submitText={props.submitText}
              theme="detailed"
            />
          </PopoverContent>
        </Portal>
      </>
    )}
  </Popover>
  )

export type GenericEditModalProps<T extends FieldMapValue = FieldMapValue> =
  Omit<EditModalProps<T>, 'children'> & { field?: FieldMap }

export const GenericEditModal = <T extends FieldMapValue = FieldMapValue>({
  field,
  data,
  readOnly,
  canSubmitClean,
  submitText,
  onSubmit,
  closeOnSubmit = true,
  ...props
}: GenericEditModalProps<T>) => {
  const value = useMemo<T>(
    () => (field && data ? data : ({} as T)),
    [field, data],
  )
  const { onClose } = props
  const { processResponse } = useContext(PopUpMessageContext)
  // close on submit success
  const handleSubmit = useCallback(async (
    submitted: T,
    onUploadProgress: OnUploadProgress,
    form: FormApi<T>,
  ) => {
    const res = await onSubmit(submitted, onUploadProgress, form)
    if (res.success && closeOnSubmit) {
      onClose()
    }
    if (res.error) {
      processResponse(res)
    }
    return res
  }, [onSubmit, onClose, closeOnSubmit, processResponse])

  return (
    <DefaultModal
      size="2xl"
      overlayHeader
      bodyProps={{
        bg: 'gray.50',
        style: { padding: '0.5rem 1rem' },
        borderRadius: 4,
      }}
      {...props}
      render={() => (field && props.isOpen ? (
          <SimpleForm<T>
            readOnly={readOnly}
            onSubmit={handleSubmit}
            canSubmitClean={canSubmitClean}
            submitText={submitText}
            value={value}
            field={field}
          />
      ) : (
          <></>
      ))
      }
    />
  )
}
