/* eslint-disable no-param-reassign */
import { CheckIcon } from '@chakra-ui/icons'
import {
  Center, CircularProgress, Flex, HStack, Text,
} from '@chakra-ui/react'
import {
  colors, Descendant,
  FieldTypes,
  getRandomId,
  IdField,
  midwivesCollection,
  Template,
  TemplateKey,
  Toolbar,
  UpdateCallback,
} from '@hb/shared'

import React, {
  CSSProperties, useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useApp } from '../../contexts'
import saveIcon from '../../icons/save_old_green.svg'
import { SvgIcon } from '../../icons/SvgIcon'
import { ActionButton } from '../Buttons/ActionButton'
import {
  StandaloneInput,
} from '../forms/Input'
import { TextInputElement } from '../forms/Input/Text/styles'
import { TemplateEditorContext } from '../RichText/context'
import { TemplateToolbar } from '../RichText/TemplateToolbar'
import { TextEditor } from '../RichText/TextEditor'
import { TextEditorProps } from '../RichText/types'
import { getWithVariables } from './withVariables'

const sleep = (ms: number) => new Promise((resolve) => {
  setTimeout(resolve, ms)
})

const convertValueToV2 = (value: Descendant[]): Descendant[] => value.map((node) => {
  if (node.type === 'variable') {
    return {
      type: 'span',
      children: [{ text: '' }, node, { text: '' }],
    }
  }
  return node
})

const previewPracticeSelectField: IdField = {
  type: FieldTypes.ID,
  placeholder: 'Preview Practice',
  collection: midwivesCollection,
  optional: true,
}
const PreviewPracticeSelect = ({ selectPractice }: {
  selectPractice: (id: string) => void
}) => {
  const {
    shortcutArgs: { practice },
  } = useContext(TemplateEditorContext)
  // const practiceOptions = practices?.map(({name, id}) => ({text: name, id}))
  return (
    <Center pt={1} w="280px">
      <StandaloneInput
        theme="detailed"
        field={previewPracticeSelectField}
        onChange={selectPractice}
        value={practice?.id}
      />
    </Center>
  )
}

export const TemplateEditor = ({
  value,
  style,
  templateType,
  onSubmit,
  width,
  height,
  baseZoom,
}: {
  value?: Template
  style?: CSSProperties
  templateType: TemplateKey
  onSubmit: (template: Template) => Promise<UpdateCallback>
  width: TextEditorProps['width']
  height: TextEditorProps['height']
  baseZoom: TextEditorProps['baseZoom']
}) => {
  const [templateName, setTemplateName] = useState(value?.name || '')
  useEffect(() => setTemplateName(value?.name || ''), [value])
  const [isLoading, setIsLoading] = useState(false)
  const { appName } = useApp()

  const [remountingForConvert, setRemountingForConvert] = useState(false)
  const [convertingToV2, setConvertingToV2] = useState(false)
  const [editedValue, setValue] = useState<Descendant[]>(
    value?.templateText || [],
  )
  const dirty = useMemo(() => value?.templateText !== editedValue, [
    value,
    editedValue,
  ])

  // if value, assume it's a v1 template if not specified
  // if no value, start with v2
  const usedVersion = useMemo(() => {
    if (convertingToV2) return 'v2'
    return value?.templateText ? value.editorVersion || 'v1' : 'v2'
  }, [value, convertingToV2])

  const convertToV2 = useCallback(async () => {
    setRemountingForConvert(true)
    await sleep(20)
    setValue(convertValueToV2(editedValue))
    setConvertingToV2(true)
    await sleep(200)
    setRemountingForConvert(false)
  }, [editedValue])

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)
    const submitted: Template = {
      id: value?.id || getRandomId(),
      name: templateName,
      templateText: editedValue,
      editorVersion: usedVersion,
    }

    const res = await onSubmit(submitted)
    setIsLoading(false)
    return res
  }, [value, templateName, onSubmit, editedValue, usedVersion])

  const canUpgradeVersion = useMemo(
    () => value?.templateText && (!usedVersion || usedVersion === 'v1'),
    [usedVersion, value],
  )

  const decorators = useMemo(
    () => [getWithVariables(usedVersion)],
    [usedVersion],
  )
  const toolbars = useMemo<Toolbar[]>(() => [TemplateToolbar], [])

  const { selectPractice } = useContext(TemplateEditorContext)

  if (remountingForConvert) {
    return (
      <HStack p={2} border={6}>
        <CircularProgress color={colors.green.hex} isIndeterminate size={5} />
        <Text fontFamily="Open Sans">Converting to v2...</Text>
      </HStack>
    )
  }
  return (
    <TextEditor
      version={usedVersion}
      value={editedValue || []}
      onChange={(v) => {
        setValue(v)
      }}
      width={width}
      height={height}
      baseZoom={baseZoom}
      toolbars={toolbars}
      templateType={templateType}
      style={style}
      decorators={decorators}
    >
      <Flex pl={3} gap={2} width="100%" minH={12} align="center" pr={12}>
        {appName === 'app' && selectPractice ? <PreviewPracticeSelect selectPractice={selectPractice} /> : null}
        <Flex align="center" flex={1}>
          <TextInputElement
            placeholder={
              templateType === 'consentForm'
                ? 'Consent Form Name'
                : 'Template Name'
            }
            value={templateName}
            style={{
              fontFamily: 'Hero-New',
              fontSize: '1rem',
              border: '1px solid #cdcdcd',
              background: 'white',
              borderRadius: '5px',
              padding: '0.5rem 0.6rem',
            }}
            onChange={({ target: { value: v } }) => {
              setTemplateName(v)
            }}
          />
        </Flex>
        {canUpgradeVersion && (
          <ActionButton
            isLoading={remountingForConvert}
            onClick={convertToV2}
            bg="white"
            size="xs"
          >
            Convert to v2
          </ActionButton>
        )}
        <ActionButton
          gap={1}
          bg="gray.50"
          isLoading={isLoading}
          onClick={handleSubmit}
          size="xs"
        >
          {dirty ? (
            <>
              <SvgIcon width="16px" src={saveIcon} />
              <Text>Save Template</Text>
            </>
          ) : (
            <>
              <CheckIcon />
              <Text>Saved</Text>
            </>
          )}
        </ActionButton>
      </Flex>

      {/*
          <EditHeader placeholder="Template Name" width={contentWidth} /> */}
    </TextEditor>
  )
}
