/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Collapse,
  HStack,
  IconButton,
  Image,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { FieldTypes } from '@hb/shared/constants'
import {
  FieldMap,
  FileField,
  UnuploadedFileDBValue,
  UploadProgress,
  WithId,
} from '@hb/shared/types'
import { PopulatedThreadMessage, ThreadType } from '@hb/shared/types/messaging'
import { arrayToObject, getThreadPath } from '@hb/shared/utils'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { processFieldMapData } from '../../../backend'
import { PopUpMessageContext, useApp } from '../../../contexts'
import { CollapseHorizontal } from '../../shared'

import sendIcon from '../../../icons/send.svg'
import {
  ChangedFile,
  FileUploadingState,
  useUploadDbFileValue,
} from '../../forms/Input/File/hooks'
import { editThreadMessage, sendThreadMessage } from '../utils'
import { UploadingAttachedFiles } from './AttachedFiles'
import { ThreadViewContext } from './contexts'
import './styles.css'
import { ThreadMessageView } from './ThreadMessageView'

const ReplyingToView = ({
  replyingTo,
  onCancelReply,
}: {
  replyingTo: PopulatedThreadMessage | null
  onCancelReply?: () => void
}) => (
  <Collapse in={!!replyingTo} style={{ width: '100%' }}>
    <VStack
      alignItems="flex-start"
      bg="#f6f6f6"
      border="1px solid #cdcdcd"
      px={1}
      py={1}
      spacing={1}
      w="100%"
      align="center"
    >
      <HStack w="100%">
        <Text px={2} fontSize="sm" color="gray.500">
          Replying to:
        </Text>
        <Tooltip
          bg="gray.50"
          color="gray.600"
          hasArrow
          placement="top"
          label="Cancel Reply"
        >
          <IconButton
            ml="auto"
            variant="outline"
            bg="gray.100"
            color="gray.500"
            borderRadius="full"
            onClick={onCancelReply}
            aria-label="Cancel Reply"
            size="xs"
            icon={<CloseIcon w={2} />}
          />
        </Tooltip>
      </HStack>
      {replyingTo ? (
        <ThreadMessageView isPreview horizontal message={replyingTo} />
      ) : null}
    </VStack>
  </Collapse>
)

const EditingMessageView = ({
  message,
  onCancelEdit,
}: {
  message: PopulatedThreadMessage | null
  onCancelEdit: () => void
}) => (
  <Collapse in={!!message} style={{ width: '100%' }}>
    <VStack
      alignItems="flex-start"
      bg="#f6f6f6"
      border="1px solid #cdcdcd"
      px={1}
      py={1}
      spacing={1}
      w="100%"
      align="center"
    >
      <HStack w="100%">
        <Text px={2} fontSize="sm" color="gray.500">
          Editing:
        </Text>
        <Tooltip
          bg="gray.50"
          color="gray.600"
          hasArrow
          placement="top"
          label="Cancel Edit"
        >
          <IconButton
            ml="auto"
            variant="outline"
            bg="gray.100"
            color="gray.500"
            borderRadius="full"
            onClick={onCancelEdit}
            aria-label="Cancel Edit"
            size="xs"
            icon={<CloseIcon w={2} />}
          />
        </Tooltip>
      </HStack>
      {message ? (
        <ThreadMessageView isPreview horizontal message={message} />
      ) : null}
    </VStack>
  </Collapse>
)

const AttachFiles = ({
  onAttach,
}: {
  onAttach: (files: ChangedFile[]) => void
  attachedFiles: UnuploadedFileDBValue[]
}) => {
  const [attachState, setAttachState] = useState<FileUploadingState>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const onChange = useUploadDbFileValue(onAttach, setAttachState)
  return (
    <Box cursor="pointer">
      <Tooltip
        placement="top"
        bg="gray.50"
        color="gray.600"
        hasArrow
        label="Attach Files"
      >
        <Box cursor="pointer" position="relative">
          <IconButton
            icon={<AttachmentIcon />}
            _hover={{ bg: 'blackAlpha.200' }}
            onClick={() => inputRef.current?.click()}
            isLoading={attachState === 'uploading'}
            aria-label="Attach File"
            color="gray.500"
            size="sm"
            variant="ghost"
            borderRadius="full"
          />
          <input
            type="file"
            multiple
            value=""
            onChange={onChange}
            ref={inputRef}
            style={{
              opacity: 0,
              width: '1px',
              height: '1px',
              position: 'absolute',
              cursor: 'pointer',
              top: 0,
              left: 0,
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  )
}

export const SendMessage = ({
  threadId,
  threadType,
}: {
  threadId?: string
  threadType?: ThreadType
}) => {
  const [message, setMessage] = useState('')
  const [attachedFiles, setAttachedFiles] = useState<UnuploadedFileDBValue[]>(
    [],
  )
  // const [sendHovered, setSendHovered] = useState(false)
  const [focused, setFocused] = useState(false)
  const [sending, setSending] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const { appName } = useApp()
  const {
    replyingTo, setReplyingTo, editingMessage, setEditingMessage,
  } = useContext(ThreadViewContext)
  const shiftHeldRef = useRef(false)

  const [uploads, setUploads] = useState<Record<string, UploadProgress>>({})
  const send = useCallback(async () => {
    if (!message) return
    if (!appName) return
    if (!threadId) return
    if (!threadType) return
    if (sending) return
    setSending(true)
    const now = Date.now()
    const attachedFileFields: Array<{
      field: FileField
      value: WithId<UnuploadedFileDBValue>
    }> = attachedFiles.map((file, idx) => ({
      field: {
        type: FieldTypes.FILE,
        placeholder: file.name || `Attached File ${idx + 1}`,
        name: `attached-file-${idx}`,
      },
      value: { ...file, id: `${now + idx}` },
    }))

    const fieldMap: FieldMap = {
      name: 'Attached Files',
      children: attachedFileFields.reduce(
        (acc, { field, value }) => {
          acc[`${value.id}`] = field
          return acc
        },
        {} as Record<string, FileField>,
      ),
    }
    const baseStoragePath = `${getThreadPath(threadType, threadId)}/files`
    const processedAttachments = await processFieldMapData(
      baseStoragePath,
      fieldMap,
      arrayToObject(attachedFileFields.map((p) => p.value)),
      undefined,
      (v) => setUploads((s) => ({ ...s, ...v })),
    )
    setUploads({})

    try {
      if (editingMessage) {
        await editThreadMessage(editingMessage, message)
      } else {
        await sendThreadMessage(
          appName,
          threadType,
          threadId,
          message,
          replyingTo
            ? {
              messageGroupId: replyingTo.messageGroupId,
              messageId: `${replyingTo.createdOn}`,
            }
            : null,
          processedAttachments,
        )
      }
      setMessage('')
      setAttachedFiles([])
      setEditingMessage(null)
      setReplyingTo(null)
    } catch (e: any) {
      processResponse({ error: e.message || 'Error sending message' })
    }
    setSending(false)
  }, [
    appName,
    message,
    processResponse,
    threadId,
    editingMessage,
    attachedFiles,
    sending,
    threadType,
    setEditingMessage,
    replyingTo,
    setReplyingTo,
  ])
  useEffect(() => {
    if (focused) {
      const listener = (e: KeyboardEvent) => {
        if (e.key === 'Enter' && !shiftHeldRef.current) {
          send()
        } else if (e.key === 'Shift') {
          shiftHeldRef.current = true
        }
      }
      const upListener = (e: KeyboardEvent) => {
        if (e.key === 'Shift') {
          shiftHeldRef.current = false
        }
      }
      document.addEventListener('keydown', listener)
      document.addEventListener('keyup', upListener)
      return () => {
        document.removeEventListener('keydown', listener)
        document.removeEventListener('keyup', upListener)
      }
    }
    return () => {}
  }, [send, focused])

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const prevEditingMessage = useRef(editingMessage)
  useEffect(() => {
    if (editingMessage && editingMessage !== prevEditingMessage.current) {
      setAttachedFiles([])
      setMessage(editingMessage.text)
      inputRef.current?.focus()
    } else if (!editingMessage && prevEditingMessage.current) {
      setMessage('')
    }
    prevEditingMessage.current = editingMessage
  }, [editingMessage])

  const prevReplyingTo = useRef(replyingTo)
  useEffect(() => {
    if (replyingTo && replyingTo !== prevReplyingTo.current) {
      inputRef.current?.focus()
    }
    prevReplyingTo.current = replyingTo
  }, [replyingTo])
  return (
    <VStack pos="relative" spacing={0} w="100%">
      <ReplyingToView
        onCancelReply={() => setReplyingTo(null)}
        replyingTo={replyingTo}
      />
      <EditingMessageView
        onCancelEdit={() => setEditingMessage(null)}
        message={editingMessage}
      />
      <Box w="100%" position="relative">
        <Text
          px={1}
          fontSize="sm"
          borderTopRadius={6}
          pos="absolute"
          top="-24px"
          height="24px"
          opacity={attachedFiles.length > 0 ? 1 : 0}
          transition="opacity 300ms"
          pointerEvents="none"
          left="3px"
          border="1px solid #cdcdcd"
          borderBottom="none"
          bg="gray.50"
          fontWeight={600}
          color="gray.500"
        >
          <AttachmentIcon mr={1} />
          Attached Files
        </Text>
        <UploadingAttachedFiles
          uploads={uploads}
          attachedFiles={attachedFiles}
          onRemove={(idx) => setAttachedFiles((f) => f.filter((_, i) => i !== idx))
          }
        />
      </Box>
      <HStack
        border="1px solid #cdcdcd"
        bg="#f6f6f6"
        position="relative"
        px={1}
        w="100%"
        spacing={0}
        py={1}
        zIndex={2}
        align="center"
      >
        <CollapseHorizontal width={40} in={!editingMessage}>
          <AttachFiles
            attachedFiles={attachedFiles}
            onAttach={(attached) => setAttachedFiles((f) => [...f, ...attached.map((a) => a.file)])
            }
          />
        </CollapseHorizontal>
        <ReactTextareaAutosize
          // size="sm"
          autoFocus
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          className="send-message-input"
          // bg="white"
          ref={inputRef}
          disabled={sending}
          placeholder="Enter a message..."
          value={message}
          onChange={(e) => {
            if (sending) return
            if (shiftHeldRef.current) {
              setMessage(e.target.value)
            } else if (e.target.value[e.target.value.length - 1] !== '\n') {
              setMessage(e.target.value)
            }
          }}
        />
        <IconButton
          bg="transparent"
          position="absolute"
          size="xs"
          top="0.35rem"
          zIndex={2}
          opacity={message ? 0.85 : 0.5}
          // onMouseEnter={() => setSendHovered(true)}
          onClick={() => send()}
          isLoading={sending}
          // onMouseLeave={() => setSendHovered(false)}
          width="28px"
          right={3}
          _hover={{ bg: 'transparent' }}
          minW="0"
          minH="0"
          height="28px"
          aria-label="Send"
          icon={
            <Image
              width="25px"
              transition="filter 300ms"
              filter={`grayscale(${message ? 0 : 100})`}
              src={sendIcon}
              alt="Send"
            />
          }
        />
      </HStack>
    </VStack>
  )
}
