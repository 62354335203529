import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Center,
  Collapse,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  GridProps,
  HStack,
  IconButton,
  IconButtonProps,
  Stack,
  StackProps,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  assessmentTemplatesCollection,
  colors,
  DocData,
  Field,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  FileDBValue,
  FileField,
  IdField,
  InfoStage,
  isField,
  isFieldMap,
  isInfoStage,
  isListField,
  ListField,
  OnUploadProgress,
  templateKeyToCollection,
  UpdateCallback,
  UploadProgress,
} from '@hb/shared'
import { ValidationErrors } from 'final-form'
import { deleteField } from 'firebase/firestore'
import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import { set as nestedSet } from 'nested-property'
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { processFieldMapData } from '../../backend'
import { useCollection } from '../../collections/hooks/useCollection'
import { useSelectedItem } from '../../hooks/backend/useSelectedItem'

import { DeleteAlert, DeleteAlertButton } from '../Alerts/DeleteAlert'
import { ViewButton } from '../Buttons/ViewButton'
import { Expandable } from '../Expandable'
import usePopulatedHints from '../forms/FinalForm/hooks/usePopulatedHints'
import { SimpleForm } from '../forms/FinalForm/SimpleForm'
import { Editable, EditableFileView } from '../forms/Input'
import LoadingIcon from '../LoadingIcon'
import { DefaultModal } from '../Modals/DefaultModal'
import { EditTemplateModal } from '../Templates/TemplateEditModal'
import { AddItemButton } from './AddItemButton'
import { DataViewContext } from './context'
import { DataCell } from './DataCell'
import { DataHeader } from './DataHeader'
import { GenericEditModal } from './GenericEditModal'
import { getRowBackground } from './utils'

interface MapViewProps {
  data?: FieldMapValue
  corrections?: FieldMapValue
  merged?: FieldMapValue
  hideRedFlags?: boolean
  childrenExpanded?: boolean
  baseStoragePath?: string
  fieldPathSegments?: string[]
  path?: string
  depth?: number
  field: FieldMap
  index?: number
}

interface DataViewProps extends MapViewProps {
  initExpanded?: boolean
  alwaysExpanded?: boolean
  initEditing?: boolean
  boxProps?: BoxProps
  noArrow?: boolean
  onEditingChange?: (editing: boolean) => void
  withEditModal?: boolean
  parentValue?: FieldMapValue
  isCorrecting?: boolean
  noBody?: boolean
  noHeader?: boolean
  size?: 'sm' | 'md' | 'lg'
  toName?: (data: FieldMapValue) => string
  onSubmit?: (data: FieldMapValue, onProgress: OnUploadProgress) => Promise<any>
  onDelete?: () => Promise<UpdateCallback>
  validate?: (data?: FieldMapValue) => ValidationErrors
  updateField?: (path: string, value: any) => Promise<UpdateCallback>
}

interface MapChildViewProps {
  parentData?: FieldMapValue
  depth: number
  id: string
  data?: any
  merged?: FieldMapValue
  corrections?: FieldMapValue
  field: Field | FieldMap | ListField | InfoStage
  baseStoragePath?: string
  fieldPathSegments?: string[]
  index: number
  path: string
}

interface FieldViewProps {
  path: string
  id: string
  data?: any
  correction?: any
  parentData?: FieldMapValue
  baseStoragePath?: string
  fieldPathSegments?: string[]
  field: Field | ListField
  index: number
}

interface DefaultFieldViewProps extends FieldViewProps {
  field: Field
}

export const LabelCell: React.FC<{ label?: string } & FlexProps> = ({
  children,
  label,
  ...props
}) => {
  const b = (
    <Flex
      color="gray.500"
      alignItems="flex-start"
      p={1}
      pr={2}
      pl={2}
      whiteSpace="pre-wrap"
      fontSize="sm"
      maxW={['100px', '250px']}
      fontWeight="600"
      {...props}
    >
      {children}
    </Flex>
  )
  return label ? (
    <Tooltip placement="top-start" bg="#808080" label={label}>
      {b}
    </Tooltip>
  ) : (
    b
  )
}

export const DataGrid = (props: GridProps) => (
  <Grid templateColumns="fit-content(25%) minmax(0,1fr)" {...props} />
)
const LoadingFieldView: React.FC<{ text: string }> = ({ text }) => (
  <GridItem colSpan={2} h={30}>
    <LoadingIcon loading={true} text={text} />
  </GridItem>
)

const ErrorFieldView: React.FC<DefaultFieldViewProps> = ({
  index,
  field,
  data,
}) => {
  const { placeholder, collection } = field as IdField
  return (
    <>
      <LabelCell>{placeholder}</LabelCell>
      <DataCell index={index}>
        Error: item {data} not found in {collection}
      </DataCell>
    </>
  )
}

const GridItemWrapper = ({ children }: PropsWithChildren) => (
  <Box
    sx={{ ':nth-of-type(2n)': { background: 'rgb(245,245,245)' } }}
    display="contents"
  >
    {children}
  </Box>
)

const TemplateFieldView: React.FC<DefaultFieldViewProps> = ({
  data,
  field,
  index,
  path,
  id,
}) => {
  const { placeholder } = field
  const { items: templates, loading } = useCollection(
    assessmentTemplatesCollection,
    data || null,
  )
  const item = useSelectedItem(templates, data)
  const [isOpen, setIsOpen] = useState(false)
  if (templates === undefined) {
    if (loading) return <LoadingFieldView text={'Loading templates...'} />
    return (
      <ErrorFieldView
        id={id}
        path={path}
        index={index}
        data={data}
        field={field}
      />
    )
  }
  return (
    <GridItem
      as={Flex}
      alignItems="center"
      colSpan={2}
      p={2}
      m={1}
      borderRadius={4}
      border="1px solid #cdcdcd"
      fontSize="sm"
    >
      <Text fontWeight="600" color="blackAlpha.700" fontFamily="Comfortaa">
        {placeholder}
      </Text>
      {data === undefined ? (
        <Text ml={4}>
          <i>Not Selected</i>
        </Text>
      ) : null}
      <EditTemplateModal
        template={item}
        collection={assessmentTemplatesCollection}
        templateType="assessments"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <ViewButton
        viewText={data ? 'VIEW / EDIT' : 'CREATE'}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
    </GridItem>
  )
}

const DefaultFieldView: React.FC<DefaultFieldViewProps> = ({
  data,
  parentData,
  correction,
  field,
  index,
  id,
  path,
}) => {
  const { updateField } = useContext(DataViewContext)
  const { hints } = field

  const body = (
    <Editable
      index={index}
      field={field}
      value={data}
      // inGrid
      style={{ width: '100%', background: 'inherit' }}
      parentValue={parentData}
      theme="basic"
      dataCellProps={{ fontSize: '0.9rem' }}
      correction={correction}
      id={id}
      onSubmit={updateField ? (v) => updateField(path, v) : undefined}
    />
  )

  const populatedHints = usePopulatedHints(hints)

  return (
    <GridItemWrapper>
      {field.type === FieldTypes.CHECKBOX ? (
        <GridItem background="inherit" px={2} colSpan={2}>
          {body}
        </GridItem>
      ) : (
        <>
          <LabelCell
            // sx={{ ':nth-of-type(4n - 3)': { background: 'rgb(245,245,245)' } }}
            background="inherit"
            label={populatedHints?.[0]?.text}
          >
            {field.placeholder}
          </LabelCell>
          {body}
        </>
      )}
    </GridItemWrapper>
  )
}

const FileFieldView: React.FC<FieldViewProps> = ({
  field,
  index,
  data,
  correction,
  baseStoragePath,
  fieldPathSegments,
  path,
}) => {
  const { updateField } = useContext(DataViewContext)
  const displayed = useMemo(() => {
    if (data && correction) {
      if (data.updatedOn > correction.updatedOn) return data
      return correction
    }
    return correction || data
  }, [data, correction])

  const onChange = useCallback(
    async (value: FileDBValue | null) => {
      if (updateField) {
        return updateField(path, value || deleteField())
      }
      return { error: 'Internal error' }
    },
    [updateField, path],
  )

  if (!baseStoragePath) {
    throw new Error('baseStoragePath is required for FileFieldView')
  }

  return (
    <GridItem colSpan={2} bg={getRowBackground(index)}>
      <EditableFileView
        field={field as FileField}
        value={displayed}
        onSubmit={onChange}
        baseStoragePath={baseStoragePath}
        fieldPathSegments={fieldPathSegments || []}
      />
    </GridItem>
  )
}

const FieldView: React.FC<FieldViewProps> = ({ field, ...props }) => {
  const { updateField } = useContext(DataViewContext)

  if (isListField(field)) {
    return (
      <GridItemWrapper>
        <GridItem background="inherit" colSpan={2}>
          <DataViewList
            value={props.data}
            onSubmit={
              updateField ? (v) => updateField(props.path, v) : undefined
            }
            baseStoragePath={props.baseStoragePath}
            fieldPathSegments={props.fieldPathSegments}
            itemField={field.itemFields}
            itemName={field.itemName}
          />
        </GridItem>
      </GridItemWrapper>
    )
  }

  switch (field.type) {
    case FieldTypes.ID:
      if (
        // eslint-disable-next-line no-underscore-dangle
        field.collection._type === 'firestoreCollection'
        && field.collection.refPath === templateKeyToCollection.assessments
      ) {
        return <TemplateFieldView field={field} {...props} />
      }
      return <DefaultFieldView field={field} {...props} />
    // case FieldTypes.DROPDOWN:
    //   return <DropdownOptionFieldView field={field} {...props} />
    case FieldTypes.FILE:
      return <FileFieldView field={field} {...props} />
    default:
      return <DefaultFieldView field={field} {...props} />
  }
}

const MapChildView: React.FC<MapChildViewProps> = ({
  data,
  parentData,
  field,
  id,
  index,
  depth,
  corrections,
  baseStoragePath,
  fieldPathSegments,
  merged,
  path,
}) => {
  if (isInfoStage(field) || (field.condition && !field.condition(parentData))) {
    return null
  }
  if (isFieldMap(field)) {
    return (
      <MapView
        merged={merged}
        depth={depth}
        path={path}
        corrections={corrections}
        baseStoragePath={baseStoragePath}
        fieldPathSegments={fieldPathSegments}
        data={data}
        index={index}
        field={field}
      />
    )
  }
  return (
    <FieldView
      correction={corrections}
      id={id}
      parentData={parentData}
      baseStoragePath={baseStoragePath}
      fieldPathSegments={fieldPathSegments}
      path={path}
      data={data}
      field={field}
      index={index}
    />
  )
}

const MapView: React.FC<MapViewProps> = ({
  data,
  field,
  merged,
  depth = 0,
  baseStoragePath,
  fieldPathSegments,
  path = '',
  corrections,
}) => {
  const { childrenExpanded } = useContext(DataViewContext)
  const { name, initExpanded } = field
  const [isOpen, setIsOpen] = useState(
    !!(depth === 0 || childrenExpanded || initExpanded),
  )
  const displayedName = useMemo(() => {
    if (field.toName && data) return field.toName(data)
    return undefined
  }, [data, field])
  return (
    <GridItem
      // border={root ? undefined : '1px solid #cdcdcd'}
      borderLeft={depth ? '2px solid #cdcdcd' : undefined}
      borderBottom={depth === 1 ? '1px solid #cdcdcd' : undefined}
      borderRight={depth === 1 ? '1px solid #cdcdcd' : undefined}
      // ml={depth ? 1 : undefined}
      // mb={depth === 1 ? 2 : 0}
      colSpan={2}
      // mt={2}
      // m={1}
    >
      {depth > 0 ? (
        <Flex
          pl={1}
          pr={2}
          align="center"
          borderTop="1px solid #cdcdcd"
          // borderBottom={isOpen ? '1px solid #cdcdcd' : undefined}
          // borderBottom='1px solid #cdcdcd'
          // borderRadius='4px'
          // bg={getRowBackground(index)}
          py={1}
        >
          <Text
            fontSize="md"
            fontWeight={600}
            fontFamily="Open Sans"
            color="gray.500"
            ml={1}
          >
            {data?.name || name}
          </Text>
          {displayedName ? (
            <Text lineHeight={1} fontSize="sm" ml={2} color="gray.700">
              {displayedName}
            </Text>
          ) : null}
          <ViewButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
        </Flex>
      ) : null}
      <Collapse in={isOpen}>
        <Box>
          <DataGrid bg="white">
            {Object.keys(field.children).map((fId, i) => (
              <MapChildView
                depth={depth + 1}
                corrections={corrections?.[fId]}
                path={`${path ? `${path}.` : ''}${fId}`}
                key={fId}
                id={fId}
                baseStoragePath={baseStoragePath}
                fieldPathSegments={[...(fieldPathSegments || []), fId]}
                index={i}
                field={field.children[fId]}
                data={data?.[fId]}
                merged={merged?.[fId]}
                parentData={merged}
              />
            ))}
          </DataGrid>
        </Box>
      </Collapse>
    </GridItem>
  )
}

export const SmallEditButton: React.FC<
  Partial<IconButtonProps> & {
    onClick: () => void
    visible: boolean
  }
> = ({ onClick, visible, ...props }) => (
  <IconButton
    size="xs"
    ml="auto"
    variant="ghost"
    onClick={onClick}
    opacity={visible ? 1 : 0}
    transition="opacity 400ms"
    pointerEvents={visible ? 'auto' : 'none'}
    aria-label="edit"
    icon={<EditIcon />}
    {...props}
  />
)
export const DataView: React.FC<DataViewProps> = ({
  data,
  corrections,
  field,
  onSubmit,
  onDelete,
  initExpanded,
  updateField,
  childrenExpanded,
  size = 'md',
  index,
  noArrow,
  baseStoragePath,
  fieldPathSegments,
  initEditing,
  validate,
  hideRedFlags,
  withEditModal,
  noBody,
  noHeader,
  alwaysExpanded,
  boxProps,
}) => {
  const [expanded, setExpanded] = useState<boolean>(
    alwaysExpanded === true || initExpanded !== false || !!noHeader,
  )
  const { name, toName } = field
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)

  useEffect(() => {
    setExpanded(initExpanded !== false)
  }, [initExpanded])

  const merged = useMemo(
    () => merge(cloneDeep(data), cloneDeep(corrections)),
    [data, corrections],
  )

  const handleSubmit = useCallback(
    async (formData: FieldMapValue, onUploadProgress: OnUploadProgress) => {
      if (onSubmit) {
        const submitted = formData
        const id = formData?.id || merged?.id
        if (id) submitted.id = id
        const processed = await processFieldMapData(
          baseStoragePath || '',
          field,
          submitted,
          data,
          onUploadProgress,
        )
        return onSubmit(processed, onUploadProgress)
          .then((res: UpdateCallback) => res)
          .catch((err) => {
            console.error(err)
            return { error: 'Error submitting' }
          })
      }
      return { error: 'No submit function' }
    },
    [onSubmit, merged, data, baseStoragePath, field],
  )

  const displayedTitle = useMemo(() => {
    if (index !== undefined && toName && merged) return toName(merged)
    return name
  }, [toName, name, merged, index])

  const [editModalOpen, setEditModalOpen] = useState(initEditing || false)

  const titleFontSize = useMemo(() => {
    switch (size) {
      case 'sm':
        return '0.9rem'
      case 'md':
        return '1rem'
      case 'lg':
        return '1.1rem'
      default:
        return '1rem'
    }
  }, [size])
  const titlePx = useMemo(() => {
    switch (size) {
      case 'sm':
        return 1
      case 'md':
        return 3
      case 'lg':
        return 4
      default:
        return 3
    }
  }, [size])

  const contextData = useMemo(
    () => ({
      updateField,
      rootValue: merged,
      childrenExpanded,
      hideRedFlags,
    }),
    [updateField, merged, childrenExpanded, hideRedFlags],
  )

  const body = (
    <>
      {noBody ? null : (
        <Collapse in={expanded}>
          <Box>
            <MapView
              index={0}
              data={data}
              fieldPathSegments={fieldPathSegments}
              baseStoragePath={baseStoragePath}
              merged={merged}
              field={field}
              corrections={corrections}
            />
          </Box>
        </Collapse>
      )}
      {onSubmit && withEditModal ? (
        <GenericEditModal
          validate={validate}
          onClose={() => setEditModalOpen(false)}
          onSubmit={handleSubmit}
          baseStoragePath={baseStoragePath}
          isOpen={editModalOpen}
          data={merged}
          field={field}
        />
      ) : null}
      {onDelete ? (
        <DeleteAlert
          onConfirm={onDelete}
          itemName={name}
          isOpen={deleteAlertOpen}
          onClose={() => setDeleteAlertOpen(false)}
        />
      ) : null}
    </>
  )

  return (
    <DataViewContext.Provider value={contextData}>
      <Box
        border={index !== undefined ? '1px solid #cdcdcd' : undefined}
        borderRadius={4}
        w="100%"
        {...boxProps}
      >
        {noHeader ? null : (
          <Flex
            align="center"
            // borderTop={size !== 'sm' ? '1px solid #cdcdcd' : undefined}
            borderBottom={expanded ? '1px solid #cdcdcd' : undefined}
            py={1}
            // px={titlePx}
          >
            <HStack
              cursor={alwaysExpanded ? 'default' : 'pointer'}
              onClick={
                noBody || alwaysExpanded
                  ? undefined
                  : () => setExpanded(!expanded)
              }
              aria-label="expand/hide"
              flex={1}
              px={titlePx}
              minW="0"
              spacing={0}
            >
              {index !== undefined ? (
                <Center
                  mr={2}
                  w="18px"
                  h="18px"
                  bg={colors.green.hex}
                  borderRadius="full"
                >
                  <Text
                    fontWeight={800}
                    textShadow="1px 1px 4px black"
                    height="18px"
                    color="white"
                    fontSize="xs"
                  >
                    {index + 1}
                  </Text>
                </Center>
              ) : null}
              <Flex
                fontSize={titleFontSize}
                color="#565656"
                position="relative"
                fontWeight={600}
                fontFamily="Open Sans"
              >
                {displayedTitle}
              </Flex>
            </HStack>
            <Stack mr={1} spacing={1} direction="row" ml="auto">
              {onDelete ? (
                <DeleteAlertButton
                  opacity={expanded || noBody ? 1 : 0}
                  pointerEvents={expanded || noBody ? 'auto' : 'none'}
                  onClick={(e) => {
                    e.stopPropagation()
                    setDeleteAlertOpen(true)
                  }}
                />
              ) : null}
              {withEditModal ? (
                <SmallEditButton
                  size="xs"
                  color={colors.green.hex}
                  bg="white"
                  border={`1px solid ${colors.green.hex}`}
                  visible={expanded}
                  onClick={() => {
                    setEditModalOpen(true)
                  }}
                />
              ) : null}
              {noBody || alwaysExpanded === true || noArrow ? null : (
                <ViewButton
                  isOpen={expanded}
                  onClick={() => setExpanded(!expanded)}
                />
              )}
            </Stack>
          </Flex>
        )}
        {body}
      </Box>
    </DataViewContext.Provider>
  )
}

export const DataViewModal: React.FC<
  DataViewProps & { isOpen: boolean; onClose: () => void }
> = ({ isOpen, onClose, ...dataViewProps }) => (
  <DefaultModal
    overlayHeader
    size="4xl"
    bodyProps={{
      padding: 0,
      paddingLeft: 6,
      paddingRight: 6,
      style: {
        paddingTop: '48px',
        paddingBottom: '24px',
      },
    }}
    isOpen={isOpen}
    onClose={onClose}
    render={() => <DataView {...dataViewProps} />}
  />
)

export const DataViewListNewItemModal = <T extends DocData>({
  isOpen,
  onClose,
  field,
  itemName,
  onSubmit,
}: {
  isOpen: boolean
  onClose: () => void
  field: FieldMap | Field
  itemName: string
  onSubmit?: (
    data: T,
    onUploadProgress: OnUploadProgress
  ) => Promise<UpdateCallback>
}) => (
  <DefaultModal
    overlayHeader
    size="xl"
    isOpen={isOpen}
    onClose={onClose}
    render={() => (
      <VStack p={2} spacing={0} align="flex-start" w="100%">
        <Text color="green.500" p={2} fontSize="lg" fontFamily="Comfortaa">
          New {itemName}
        </Text>
        <SimpleForm
          theme="detailed"
          boxProps={{ p: 0, shadow: 'none' }}
          field={field}
          onSubmit={async (data, onProgress) => {
            if (!onSubmit) return { error: 'no onsubmit function' }
            const res = await onSubmit(data as T, onProgress)
            if (!res.error) onClose()
            return res
          }}
        />
      </VStack>
    )}
  />
  )

export const DataViewList = <T extends DocData>({
  value,
  onSubmit,
  itemName,
  itemField,
  parentValue,
  baseStoragePath,
  fieldPathSegments,
  noArrow,
  initExpanded,
  stackProps,
  alwaysExpanded,
}: {
  value?: Array<T>
  itemField: FieldMap | Field
  parentValue?: FieldMapValue
  initExpanded?: boolean
  alwaysExpanded?: boolean
  baseStoragePath?: string
  fieldPathSegments?: string[]
  itemName: string
  noArrow?: boolean
  stackProps?: StackProps
  onSubmit?: (
    data: Array<T>,
    onUploadProgress: (progress: Record<string, UploadProgress>) => void
  ) => Promise<UpdateCallback>
}) => {
  const updateItem = useCallback(
    async (
      idx: number,
      data: FieldMapValue,
      onUploadProgress: OnUploadProgress,
    ) => {
      if (!onSubmit) return { error: 'No submit function' }
      const newValue = value ? [...value] : []
      newValue[idx] = data as T
      return onSubmit(newValue, onUploadProgress)
    },
    [onSubmit, value],
  )

  const deleteItem = useCallback(
    async (idx: number) => {
      if (!onSubmit) return { error: 'No submit function' }
      const newValue = value ? [...value] : []
      newValue.splice(idx, 1)
      return onSubmit(newValue, () => {})
    },
    [onSubmit, value],
  )

  const [newItemModalOpen, setNewItemModalOpen] = useState(false)
  const itemNamePlural = useMemo(
    () => (itemName.endsWith('s') ? `${itemName}es` : `${itemName}s`),
    [itemName],
  )

  return (
    <GridItemWrapper>
      <Expandable
        alwaysExpanded={alwaysExpanded}
        background="inherit"
        {...stackProps}
        initExpanded={initExpanded}
        header={({ isOpen }) => (
          <HStack py={1} w="100%">
            <DataHeader>
              <HStack>
                <Text>{itemNamePlural}</Text>
                <Center
                  w="19px"
                  h="19px"
                  bg={colors.green.hex}
                  borderRadius="full"
                >
                  <Text
                    position="relative"
                    height="11px"
                    lineHeight={1}
                    color="white"
                    textShadow="1px 1px 3px #00000099"
                    fontSize="xs"
                    fontWeight={600}
                  >
                    {value?.length || 0}
                  </Text>
                </Center>
              </HStack>
              {onSubmit ? (
                <AddItemButton
                  ml="auto"
                  aria-label={`Add ${itemName}`}
                  opacity={isOpen ? 1 : 0}
                  pointerEvents={isOpen ? 'auto' : 'none'}
                  onClick={(e) => {
                    e.stopPropagation()
                    setNewItemModalOpen(true)
                  }}
                >
                  + ADD {itemName.toUpperCase()}
                </AddItemButton>
              ) : null}
            </DataHeader>
          </HStack>
        )}
      >
        <VStack
          borderLeft="1px solid #cdcdcd"
          borderRight="1px solid #cdcdcd"
          borderBottom="1px solid #cdcdcd"
          bg="gray.50"
          borderBottomRadius={4}
          px={2}
          py={1}
          w="100%"
          align="flex-start"
          spacing={1}
        >
          {value?.length ? (
            value.map((item, idx) => (
              <Box
                key={idx}
                overflow="hidden"
                w="100%"
                bg="white"
                borderRadius={4}
              >
                {isField(itemField) ? (
                  <Editable
                    onSubmit={
                      onSubmit
                        ? (data, onProgress) => updateItem(idx, data, onProgress || (() => {}))
                        : undefined
                    }
                    onDelete={onSubmit ? () => deleteItem(idx) : undefined}
                    baseStoragePath={baseStoragePath}
                    fieldPathSegments={[
                      ...(fieldPathSegments || []),
                      item?.id || `${idx}`,
                    ]}
                    parentValue={parentValue}
                    value={item}
                    index={idx}
                    field={itemField}
                  />
                ) : (
                  <DataView
                    key={`${idx}`}
                    size="sm"
                    field={{
                      ...itemField,
                      name: `${itemName} ${idx + 1}`,
                    }}
                    initExpanded={itemField.initExpanded}
                    index={idx}
                    baseStoragePath={baseStoragePath}
                    fieldPathSegments={[
                      ...(fieldPathSegments || []),
                      item?.id || `${idx}`,
                    ]}
                    noArrow={noArrow}
                    data={item}
                    onSubmit={
                      onSubmit
                        ? (data, onProgress) => (data
                          ? updateItem(idx, data, onProgress)
                          : deleteItem(idx))
                        : undefined
                    }
                    updateField={(path, updated) => {
                      const newValue = { ...item }
                      nestedSet(newValue, path, updated)
                      return updateItem(idx, newValue, () => {})
                    }}
                    onDelete={onSubmit ? () => deleteItem(idx) : undefined}
                  />
                )}
              </Box>
            ))
          ) : (
            <Text fontStyle="italic" p={1} color="gray.600">
              No {itemNamePlural}
            </Text>
          )}
        </VStack>
      </Expandable>
      {newItemModalOpen ? (
        <DataViewListNewItemModal<T>
          itemName={itemName}
          isOpen={newItemModalOpen}
          field={itemField}
          onClose={() => setNewItemModalOpen(false)}
          onSubmit={
            onSubmit
              ? (data, onUploadProgress) => onSubmit([...(value || []), data], onUploadProgress)
              : undefined
          }
        />
      ) : null}
    </GridItemWrapper>
  )
}
