import { CheckIcon } from '@chakra-ui/icons'
import {
  Collapse, Divider, Flex, Text,
} from '@chakra-ui/react'
import {
  ASSESSMENTS,
  colors,
  ConfirmCoverageStage,
  ConfirmCoverageStatus,
  getConfirmCoverageStatus,
  getCoverageComplete,
  getCurrentlyOnMedicaidPlan,
  getHasMedicaidCoverage,
  getInsuranceCoverageField,
  getOnlyOnMedicaidPlan,
  InsuranceCoverage,
  InsuranceCoverageRequest,
  isInsuranceCoverageRequest,
  loqRequiredOptions,
  WithId,
} from '@hb/shared'
import { collection, doc, writeBatch } from 'firebase/firestore'

import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { db } from '../../../backend/db'
import { PopUpMessageContext } from '../../../contexts'
import { FormsViewContext } from '../../../contexts/FormsViewContext'
import { useAuth } from '../../../store'
import { SolidActionButton } from '../../Buttons/ActionButton'
import { DefaultModal } from '../../Modals/DefaultModal'
import { CoverageRequestView } from './CoverageRequestView'
import { CoverageStage } from './CoverageStage'
import { FormsViewAdditionalPlans } from './FormsViewAdditionalPlans'
import { CoverageStageProps } from './types'

const getInitialStage = (
  status: ConfirmCoverageStatus,
): ConfirmCoverageStage | null => {
  if (status.primaryCoverage?.incomplete?.length) return 'primaryCoverage'
  if (status.medicaidCoverage?.incomplete?.length) return 'medicaidCoverage'
  const additionalPlan = Object.keys(status.additionalPlans || {}).find(
    (p) => !!status.additionalPlans[p]?.incomplete.length,
  )
  if (additionalPlan) return `additionalPlans.${additionalPlan}`
  return 'confirm'
}

export const ConfirmCoverageModal = ({ onClose }: { onClose: () => void }) => {
  const { assessment, assessmentId } = useContext(FormsViewContext)
  const { data, additionalPlans } = assessment || {}

  const option = useMemo(
    () => assessment?.mergedData?.['insurance-info']?.option,
    [assessment],
  )
  const primaryCoverage = useMemo(() => {
    const d = data?.['insurance-info']?.primaryCoverage as
      | InsuranceCoverage
      | undefined

    return d ? { ...d, id: 'primaryCoverage' } : d
  }, [data])

  const medicaidCoverage = useMemo(() => {
    const d = data?.['insurance-info']?.medicaidCoverage as
      | InsuranceCoverage
      | undefined

    return d ? { ...d, id: 'medicaidCoverage' } : d
  }, [data])

  const hasPrimaryCoverage = useMemo(() => !getOnlyOnMedicaidPlan(data), [data])

  const hasMedicaidCoverage = useMemo(
    () => getHasMedicaidCoverage(data),
    [data],
  )

  const isCurrentlyOnMedicaid = useMemo(
    () => getCurrentlyOnMedicaidPlan(data),
    [data],
  )

  const primaryCoverageField = useMemo(
    () => (hasPrimaryCoverage
      ? getInsuranceCoverageField(
        false,
        loqRequiredOptions.includes(option),
        !isCurrentlyOnMedicaid,
      )
      : null),
    [option, hasPrimaryCoverage, isCurrentlyOnMedicaid],
  )

  const medicaidCoverageField = useMemo(
    () => (hasMedicaidCoverage ? getInsuranceCoverageField(true, false) : null),
    [hasMedicaidCoverage],
  )

  const status = useMemo<ConfirmCoverageStatus>(
    () => getConfirmCoverageStatus(assessment),
    [assessment],
  )

  const incompletePlans = useMemo(() => {
    const incomplete: {
      id: string
      type: CoverageStageProps['type']
      propPath:
        | 'data.insurance-info.primaryCoverage'
        | 'data.insurance-info.medicaidCoverage'
        | `additionalPlans.${string}`
      label: 'primary' | 'medicaid' | 'additional'
      coverage:
        | WithId<InsuranceCoverage>
        | WithId<InsuranceCoverageRequest>
        | undefined
    }[] = []
    if (status.primaryCoverage?.incomplete?.length) {
      incomplete.push({
        id: 'primaryCoverage',
        type: 'primaryCoverage',
        label: 'primary',
        propPath: 'data.insurance-info.primaryCoverage',
        coverage: primaryCoverage
          ? ({ ...primaryCoverage, id: 'primaryCoverage' } as
              | WithId<InsuranceCoverage>
              | WithId<InsuranceCoverageRequest>)
          : undefined,
      })
    }
    if (status.medicaidCoverage?.incomplete?.length) {
      incomplete.push({
        id: 'medicaidCoverage',
        type: 'medicaidCoverage',
        label: 'medicaid',
        propPath: 'data.insurance-info.medicaidCoverage',
        coverage: medicaidCoverage
          ? ({ ...medicaidCoverage, id: 'medicaidCoverage' } as
              | WithId<InsuranceCoverage>
              | WithId<InsuranceCoverageRequest>)
          : undefined,
      })
    }
    Object.keys(status.additionalPlans || {})
      .filter((p) => status.additionalPlans[p]?.incomplete.length)
      .forEach((p) => {
        const coverage = additionalPlans?.[p]
        if (coverage) {
          incomplete.push({
            id: p,
            propPath: `additionalPlans.${p}`,
            type: 'additionalPlans',
            label: 'additional',
            coverage: coverage
              ? ({ ...coverage, id: p } as
                  | WithId<InsuranceCoverage>
                  | WithId<InsuranceCoverageRequest>)
              : undefined,
          })
        }
      })
    return incomplete
  }, [status, additionalPlans, medicaidCoverage, primaryCoverage])

  const [stage, setStage] = useState<ConfirmCoverageStage | null>(
    getInitialStage(status),
  )
  const allComplete = useMemo(
    () => (assessment ? getCoverageComplete(status) : false),
    [status, assessment],
  )

  const onOpenToggle = useCallback((s: ConfirmCoverageStage | null) => {
    setStage((prev) => (prev === s ? null : s))
  }, [])

  const selectedAdditionalPlanId = useMemo(() => {
    if (stage?.startsWith('additionalPlans')) return stage.split('.')[1]
    return null
  }, [stage])

  const { showMessage } = useContext(PopUpMessageContext)

  const [confirmLoading, setConfirmLoading] = useState(false)
  const onConfirm = useCallback(async () => {
    const { authUser } = useAuth.getState()
    if (!authUser) {
      showMessage({
        type: 'error',
        text: 'User not found',
        subText: 'You may have been signed out. Please sign in again.',
      })
      return
    }
    setConfirmLoading(true)
    try {
      const assessmentRef = doc(collection(db, ASSESSMENTS), assessmentId)
      const batch = writeBatch(db)
      // update confirmation status
      batch.update(assessmentRef, 'additionalPlansConfirmedOn', Date.now())
      batch.update(assessmentRef, 'additionalPlansConfirmedBy', authUser.uid)
      await batch.commit()
      onClose()
    } catch (e: any) {
      showMessage({
        type: 'error',
        text: 'Error updating assessment',
        subText: e.message,
      })
      setConfirmLoading(false)
    }
  }, [assessmentId, onClose, showMessage])

  const incompletePlan = incompletePlans[0]

  let incompletePlanBody: PropsWithChildren['children'] = null
  if (incompletePlan && assessment && assessmentId) {
    incompletePlanBody = incompletePlan.coverage
      && isInsuranceCoverageRequest(incompletePlan.coverage) ? (
        <CoverageRequestView
          assessment={assessment}
          assessmentId={assessmentId}
          request={incompletePlan.coverage}
        />
      ) : (
        <CoverageStage
          key={incompletePlan.id}
          type={incompletePlan.type}
          alwaysOpen
          assessmentId={assessmentId}
          coverage={incompletePlan.coverage}
          isOpen
          propPath={incompletePlan.propPath}
        />
      )
  }

  return (
    <DefaultModal
      overlayHeader
      isOpen
      size="2xl"
      onClose={onClose}
      contentProps={{ bg: 'gray.100' }}
      render={() => (assessment && assessmentId ? (
          <Flex gap={2} p={3} flexFlow="column" w="100%">
            <Text
              p={1}
              fontSize="lg"
              lineHeight={1}
              fontWeight={600}
              color="gray.600"
            >
              Confirm Coverage
            </Text>
            {incompletePlan ? (
              <Flex w='100%' flexFlow='column'>
              <Text p={2} fontSize='md' color='gray.600'>
                We need some information about your {incompletePlan.label} coverage.
              </Text>
              {incompletePlanBody}
              </Flex>
            ) : (
              <Flex
                flexFlow="column"
                p={2}
                gap={2}
                border="1px solid #cdcdcd"
                bg="white"
                w="100%"
              >
                {primaryCoverageField ? (
                  <>
                    <CoverageStage
                      type="primaryCoverage"
                      assessmentId={assessmentId}
                      propPath="data.insurance-info.primaryCoverage"
                      coverage={primaryCoverage}
                      optional={isCurrentlyOnMedicaid}
                      isOpen={stage === 'primaryCoverage'}
                      onOpenToggle={() => onOpenToggle('primaryCoverage')}
                    />
                    <Divider />
                  </>
                ) : null}
                {medicaidCoverageField ? (
                  <>
                    <CoverageStage
                      assessmentId={assessmentId}
                      type="medicaidCoverage"
                      propPath="data.insurance-info.medicaidCoverage"
                      coverage={medicaidCoverage}
                      isOpen={stage === 'medicaidCoverage'}
                      onOpenToggle={() => onOpenToggle('medicaidCoverage')}
                    />
                    <Divider />
                  </>
                ) : null}
                <FormsViewAdditionalPlans
                  assessment={assessment}
                  assessmentId={assessmentId}
                  openPlanId={selectedAdditionalPlanId}
                  onOpenPlan={(planId) => onOpenToggle(`additionalPlans.${planId}`)
                  }
                />
              </Flex>
            )}
            <Collapse in={allComplete} style={{ width: '100%' }}>
              <Flex w="100%">
                <SolidActionButton
                  borderRadius="full"
                  ml="auto"
                  onClick={onConfirm}
                  isLoading={confirmLoading}
                  bg={colors.green.hex}
                  color="white"
                >
                  <CheckIcon
                    filter="drop-shadow(1px 1px 3px #00000066)"
                    w={4}
                    h={4}
                    mr={2}
                  />
                  <Text>Done!</Text>
                </SolidActionButton>
              </Flex>
            </Collapse>
          </Flex>
      ) : (
          <Flex p={3}>
            <Text>No assessment found</Text>
          </Flex>
      ))
      }
    />
  )
}
