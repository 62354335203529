import { Flex, Text } from '@chakra-ui/react'
import { ASSESSMENTS } from '@hb/shared/collections'
import { getSecondaryCoverageField } from '@hb/shared/fields'
import {
  FieldMapValue,
  InsuranceCoverage,
  isInsuranceCoverageRequest,
  OnUploadProgress,
  PopulatedAssessmentInterface,
} from '@hb/shared/types'
import { getUniqueRandomId } from '@hb/shared/utils'
import { FORM_ERROR } from 'final-form'
import { collection, doc, writeBatch } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo } from 'react'
import { db, processFieldMapData } from '../../../backend'
import { AppContext } from '../../../contexts'
import { useAuth } from '../../../store'
import { addMetadata } from '../../../utils'
import { SimpleForm } from '../../forms'

export const SecondaryCoverageForm = ({
  assessment,
  assessmentId,
  isMedicaid,
  onBack,
}: {
  onBack: () => void
  assessmentId: string
  isMedicaid: boolean
  assessment: PopulatedAssessmentInterface
}) => {
  const { additionalPlans } = assessment || {}
  const coverageIds = useMemo(
    () => Object.keys(additionalPlans || {}),
    [additionalPlans],
  )
  const { secondaryCoverage, secondaryCoverageId } = useMemo(() => {
    const coverage = Object.entries(additionalPlans || {}).find(([, plan]) => (isInsuranceCoverageRequest(plan) ? false : plan.label === 'secondary'))
    if (!coverage) {
      return {
        secondaryCoverage: null,
        secondaryCoverageId: getUniqueRandomId(coverageIds),
      }
    }
    return {
      secondaryCoverage: { id: coverage[0], ...coverage[1] },
      secondaryCoverageId: coverage[0],
    }
  }, [additionalPlans, coverageIds])

  const field = useMemo(
    () => getSecondaryCoverageField(isMedicaid),
    [isMedicaid],
  )

  const { appName } = useContext(AppContext)

  const authUser = useAuth((s) => s.authUser)
  const handleSubmit = useCallback(
    async (submitted: FieldMapValue, onUploadProgress: OnUploadProgress) => {
      if (!authUser) return { [FORM_ERROR]: 'User not authenticated' }
      const docRef = doc(collection(db, ASSESSMENTS), assessmentId)
      const propPath = `additionalPlans.${secondaryCoverageId}`
      try {
        const processed = await processFieldMapData(
          `${ASSESSMENTS}/${assessmentId}/additionalPlans/${secondaryCoverageId}`,
          field,
          submitted,
          null,
          onUploadProgress,
        )
        const batch = writeBatch(db)
        const plan = {
          ...processed,
          isMedicaid,
          label: 'secondary',
        } as InsuranceCoverage
        batch.update(docRef, propPath, addMetadata(plan, appName, true))
        batch.update(docRef, 'additionalPlansConfirmedOn', Date.now())
        batch.update(docRef, 'additionalPlansConfirmedBy', authUser.uid)
        await batch.commit()
        onBack()
        return undefined
      } catch (err: any) {
        console.error(err)
        return { [FORM_ERROR]: err.message || 'An error occurred' }
      }
    },
    [secondaryCoverageId, authUser, assessmentId, onBack, isMedicaid, field, appName],
  )
  return (
    <Flex flexFlow="column" w="100%">
      <Flex px={3} w="100%" align="center">
        <Text fontSize='lg' color="gray.600">
          Secondary Coverage
        </Text>
      </Flex>
      <SimpleForm
        boxProps={{ boxShadow: 'none', borderRadius: '1px solid #cdcdcd', p: 0 }}
        field={field}
        value={secondaryCoverage}
        onSubmit={handleSubmit}
      />
    </Flex>
  )
}
