import { ArrowForwardIcon, ChevronDownIcon, EmailIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  StackDivider,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  adminRoles, colors, midwivesCollection, View,
} from '@hb/shared'
import React, { ReactNode, useContext, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Link, Link as RouterLink } from 'react-router-dom'
import { ScreenContext } from '../../contexts'
import { useApp } from '../../contexts/AppContext'
import { usePracticeAccess } from '../../contexts/PracticeAccess/PracticeAccessProvider'
import { useUnsignedConsentForms } from '../../hooks/backend/consentForms'
import { useUnreadDocuments } from '../../hooks/backend/documents/useUnreadDocuments'
import { useCollectionItem } from '../../hooks/backend/useCollectionItem'
import { useMe } from '../../hooks/backend/useMe'
import { useAppRole } from '../../hooks/backend/user/useAppRole'
import assignmentIcon from '../../icons/assignment.svg'
import { SvgIcon } from '../../icons/SvgIcon'
import { useAuth } from '../../store'
import { UserPanel } from '../Auth/UserPanel'
import { BugReportsPopover } from '../DevOps/BugReports/BugReportsPopover'
import { AddPatientPopover } from '../Practices/AddPatientPopover'
import { AppVersionView } from './AppVersionView'
import { CallInRequestsNotification } from './CallInRequestsNotification'
import { useNestedViews } from './hooks'

const MenuDivider = () => (
  <Text ml={3} mr={3} color="#cdcdcd">
    |
  </Text>
)
interface MenuLinkItem {
  path: string | string[]
  name: string | ReactNode
}
interface MenuFolderProps {
  title: string
  items: MenuLinkItem[]
}

export const MenuLink = ({ path, name }: MenuLinkItem) => (
  <Box
    w="100%"
    borderBottom="1px solid #dedede"
    sx={{
      ':first-of-type': {
        borderTop: '1px solid #dedede',
      },
    }}
  >
    <RouterLink
      style={{ opacity: 1, width: '100%' }}
      to={typeof path === 'string' ? path : path[0]}
    >
      <MenuItem
        as="div"
        fontWeight="normal"
        fontFamily="Open Sans"
        py={1}
        color="gray.800"
        w="100%"
        _hover={{ bg: 'rgba(0,0,0,0.05)' }}
      >
        {name}
      </MenuItem>
    </RouterLink>
  </Box>
)

export const MenuFolder = ({ title, items }: MenuFolderProps) => (
  <Menu>
    <MenuButton
      fontWeight={300}
      fontFamily="hero-new"
      variant="link"
      size="md"
      as={Button}
    >
      {title} <ChevronDownIcon height={3} width={3} />
    </MenuButton>
    <MenuList>
      {items.map((item) => (
        <MenuLink {...item} />
      ))}
    </MenuList>
  </Menu>
)

const UnreadIndicator = ({ count }: { count: number }) => (
  <Center
    position="absolute"
    top="-5px"
    right="-8px"
    color="white"
    bg={colors.green.hex}
    boxShadow={`0 0 5px ${colors.green.hex}`}
    w="16px"
    h="16px"
    borderRadius="full"
    lineHeight="16px"
    fontSize="0.7rem"
    fontWeight={600}
    p={1}
  >
    {count}
  </Center>
)

const UnreadItems = () => {
  const unreadDocs = useUnreadDocuments()
  const unsignedConsentForms = useUnsignedConsentForms()
  const { appName } = useApp()
  const hasUnreadItems = !!(unreadDocs.length || unsignedConsentForms.length)

  return appName === 'app' && hasUnreadItems ? (
    <Box px={2}>
      <Stack
        bg="gray.100"
        direction='row'
        borderRadius="full"
        boxShadow="md"
        spacing={1}
        py={1}
        px={2}
        divider={<StackDivider w='1px' bg='gray.400' />}
      >
        {unreadDocs.length ? (
          <RouterLink to="/documents">
            <Box>
              <Tooltip placement="bottom" hasArrow label={`${unreadDocs.length} unread document${unreadDocs.length !== 1 ? 's' : ''}`}>
                <IconButton
                  minH="0"
                  minW="0"
                  h='22px'
                  w='22px'
                  variant="unstyled"
                  color='gray.500'
                  size="md"
                  icon={
                    <Center w='100%' h='100%' position="relative">
                    <EmailIcon/>
                    <UnreadIndicator count={unreadDocs.length} />
                  </Center>
                  }
                  aria-label="Unread documents"
                />
              </Tooltip>
            </Box>
          </RouterLink>
        ) : null}
        {unsignedConsentForms.length ? (
          <RouterLink to="/consent-forms">
            <Box>
              <Tooltip placement="bottom" hasArrow label={`${unsignedConsentForms.length} unsigned consent form${unsignedConsentForms.length !== 1 ? 's' : ''}`}>
                <IconButton
                  minH="0"
                  minW="0"
                  h='22px'
                  w='22px'
                  variant="unstyled"
                  color={
                    unsignedConsentForms.length ? colors.green.hex : 'gray.500'
                  }
                  size="md"
                  icon={
                    <Center w='100%' h='100%' position="relative">
                      <SvgIcon src={assignmentIcon} />
                      <UnreadIndicator count={unsignedConsentForms.length} />
                    </Center>
                  }
                  aria-label="Unsigned consent forms"
                />
              </Tooltip>
            </Box>
          </RouterLink>
        ) : null}
      </Stack>
    </Box>
  ) : null
}

const AuthLinks: React.FC<{ views: View[] }> = ({ views }) => {
  const { userViews, adminViews, superAdminViews } = useNestedViews(views)
  const me = useMe()
  const { height: screenHeight } = useContext(ScreenContext)
  const maxHeight = useMemo(() => screenHeight - 80, [screenHeight])
  return (
    <HStack spacing={0}>
      <Menu>
        <MenuButton
          fontWeight={300}
          fontFamily="hero-new"
          variant="link"
          size="md"
          as={Button}
        >
          My Account <ChevronDownIcon height={3} width={3} />
        </MenuButton>
        <Portal>
          <MenuList
            p={0}
            filter="drop-shadow(0 0 6px rgba(0,0,0,0.3))"
            borderRadius={4}
            overflow="hidden"
            border={me ? undefined : 'none'}
            background={me ? 'gray.100' : 'transparent'}
          >
            <Box
              w="100%"
              maxH={`${maxHeight}px`}
              overflowY="auto"
              overflowX="hidden"
            >
              <Box w="100%">
                {userViews.map((item, i) => (
                  <MenuLink key={i} {...item} />
                ))}
                {adminViews.length ? (
                  <MenuGroup
                    color="gray.500"
                    style={{ width: '100%' }}
                    title="ADMIN ACCESS"
                  >
                    {adminViews.map((item, i) => (
                      <MenuLink key={i} {...item} />
                    ))}
                  </MenuGroup>
                ) : null}
                {superAdminViews.length ? (
                  <MenuGroup
                    color="gray.500"
                    style={{ width: '100%' }}
                    px={0}
                    title="SUPER ADMIN ACCESS"
                  >
                    {superAdminViews.map((item, i) => (
                      <MenuLink key={i} {...item} />
                    ))}
                  </MenuGroup>
                ) : null}
              </Box>
              <MenuItem
                borderTop={userViews.length ? '1px solid #cdcdcd' : 'none'}
                as={Flex}
                bg={me ? 'white' : 'transparent'}
                p={0}
              >
                <UserPanel />
              </MenuItem>
            </Box>
          </MenuList>
        </Portal>
      </Menu>
      <UnreadItems />
    </HStack>
  )
}

const HeaderLink = ({ to, children }: { to: string; children: ReactNode }) => (
  <RouterLink to={to}>
    <Button
      variant="link"
      fontWeight={300}
      fontFamily="hero-new"
      size="md"
      color="gray.800"
      _hover={{ textDecoration: 'none' }}
    >
      {children}
    </Button>
  </RouterLink>
)

export const AppHeader: React.FC<{ views: View[] }> = ({ views }) => {
  const history = useHistory()
  const { appName } = useApp()
  const authUser = useAuth((s) => s.authUser)
  const role = useAuth((s) => s.role)

  const { selectedPracticeId } = usePracticeAccess()
  const { isMobile } = useContext(ScreenContext)

  const appRole = useAppRole()
  const { item: selectedPractice } = useCollectionItem(
    midwivesCollection,
    selectedPracticeId || undefined,
  )
  const title = useMemo(() => {
    if (appName === 'providers-app') return selectedPractice?.name || 'Hamilton Billing Providers'
    return null
  }, [appName, selectedPractice])

  return (
    <Flex
      w="100%"
      align="center"
      position={isMobile ? 'sticky' : 'relative'}
      bg="white"
      style={{
        top: 0,
        zIndex: 1,
        borderBottom: '1px solid #bcbcbc',
        flexFlow: 'row',
        height: '55px',
        justifyContent: 'space-between',
      }}
    >
      <Flex pl={[1, 2, 3]} gap={3} flex={1} align="center">
        {title ? (
          <Text fontSize={isMobile ? 'sm' : 'md'} fontWeight={600} color="gray.600" pl={3} fontFamily="Comfortaa">
            {title}
          </Text>
        ) : null}
        {appName === 'app' ? (
          <Image
            onClick={() => history.push('/')}
            // position='absolute'
            cursor="pointer"
            top="5px"
            height="48px"
            src="/images/logo-real.svg"
          />
        ) : null}
        {appRole && adminRoles.includes(appRole) ? <AppVersionView /> : null}
        {role && adminRoles.includes(role) ? <BugReportsPopover /> : null}
      </Flex>
      {/* <Image
        onClick={() => history.push('/')}
        position='absolute'
        cursor='pointer'
        left='20px'
        top='5px'
        src='/images/header-logo-cropped.png'
      /> */}
      {isMobile && !authUser ? (
        <Box pr="62px">
          <Link to="/sign-in">
            <Button
              id="sign-in-button"
              fontWeight={500}
              fontFamily="Comfortaa"
              color="white"
              size="sm"
              gap={2}
              bg={colors.green.hex}
            >
              <Text fontSize="md" lineHeight={1}>
                Sign In
              </Text>
              <ArrowForwardIcon width={5} height={5} />
            </Button>
          </Link>
        </Box>
      ) : null}
      <CallInRequestsNotification/>
      {isMobile ? null : (
        <Stack
          display={isMobile ? 'none' : 'flex'}
          fontFamily="hero-new"
          fontWeight={300}
          align="center"
          direction="row"
          divider={<MenuDivider />}
          mr="35px"
        >
          {appName === 'app' ? (
            <HStack divider={<MenuDivider />}>
              <HeaderLink to="/patients">Patients</HeaderLink>
              <HeaderLink to="/providers">Providers</HeaderLink>
              <HeaderLink to="/faq">FAQ</HeaderLink>
            </HStack>
          ) : null}
          {appName === 'providers-app' ? (
            <HStack spacing={3} divider={<MenuDivider />}>
              {selectedPracticeId ? <AddPatientPopover /> : null}
              {selectedPracticeId ? (
                <HeaderLink to="/assessments">Pregnancies</HeaderLink>
              ) : (
                <HeaderLink to="/">Home</HeaderLink>
              )}
            </HStack>
          ) : null}
          <HeaderLink to="/contact">Contact</HeaderLink>
          <AuthLinks views={views} />
        </Stack>
      )}
    </Flex>
  )
}
