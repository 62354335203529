import { CloseIcon } from '@chakra-ui/icons'
import { Flex, HStack, IconButton } from '@chakra-ui/react'
import { DateField, InputElement, InputProps } from '@hb/shared'
import React, {
  forwardRef,
  useContext, useEffect, useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { ThemeContext } from '../../../../contexts'

export const NativeDateInput: InputElement<DateField> = forwardRef<
  { focus:() => void },
  InputProps<DateField>
    >((props, ref) => {
      const {
        input,
        field: {
          placeholder,
          // maxDate,
          defaultToNow,
          optional,
          isoFormat,
        },
        style,
        meta: { active },
      } = props
      const {
        onChange, value, onFocus, onBlur,
      } = input

      const date = useMemo(() => {
        if (!value) return undefined
        const d = new Date(value)
        d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000)
        return d
      }, [value])
      const dateAsString = useMemo(
        () => (date ? date.toISOString().split('T')[0] : ''),
        [date],
      )

      const mounted = useRef(false)
      useEffect(() => {
        if (!mounted.current && !value && (!optional || defaultToNow)) {
          onChange(isoFormat ? new Date().toISOString().split('T')[0] : new Date().getTime())
          mounted.current = true
        }
      }, [defaultToNow, isoFormat, onChange, optional, value])

      const inputRef = useRef<HTMLInputElement>(null)
      useImperativeHandle(ref, () => ({
        focus: () => inputRef.current?.focus(),
        blur: () => inputRef.current?.blur(),
      }))

      const { theme } = useContext(ThemeContext)

      const placeholderWithOptional = useMemo(() => {
        if (optional) return `${placeholder} (optional)`
        return placeholder
      }, [optional, placeholder])

      return (
    <Flex>
      <HStack
        spacing={2}
        px={theme === 'basic' ? 2 : 3}
        pl={theme === 'basic' ? 2 : 3}
        cursor="pointer"
        py={theme === 'basic' ? '0.2rem' : '0.35rem'}
        minW={theme === 'basic' ? '0' : '270px'}
        minH='36px'
        background="white"
        boxShadow={`1px 1px 3px ${active ? 'rgba(0, 0, 100, 0.1)' : 'rgba(0, 0, 0, 0.05)'}`}
        border={theme === 'basic' ? undefined : '1px solid #cdcdcd'}
        _hover={{ background: 'gray.50' }}
        borderRadius={6}
        color={value ? 'gray.700' : 'gray.500'}
        style={style}
      >
        <input
          ref={inputRef}
          value={dateAsString}
          placeholder={placeholderWithOptional}
          onChange={(e) => {
            const { value: updatedValue } = e.target
            if (!updatedValue) return
            const updated = new Date(updatedValue)
            if (Number.isNaN(updatedValue)) {
              console.error('Invalid date')
              onChange(undefined)
            } else {
              onChange(
                isoFormat
                  ? updated?.toISOString().split('T')[0]
                  : updated?.getTime(),
              )
            }
          }}
          style={{
            flex: 1,
            background: 'transparent',
          }}
          type="date"
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {value ? (
          <IconButton
            ml="auto"
            size="xs"
            width="20px"
            minW="0"
            height="20px"
            bg="gray.400"
            borderRadius="full"
            color="white"
            aria-label="Clear"
            icon={<CloseIcon color="gray.100" w={2} h={2} />}
            onClick={(e) => {
              e.stopPropagation()
              onChange(undefined)
            }}
          />
        ) : null}
      </HStack>
    </Flex>
      )
    })
