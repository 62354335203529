import { Box } from '@chakra-ui/react'
import { FieldMapValue, Form } from '@hb/shared/types'
import React from 'react'
import { StagePreview } from './StagePreview'

export const FormPreview = ({
  form,
  data,
  readOnly,
  onStageEdit,
}: {
  form: Form
  data: FieldMapValue | undefined
  readOnly?: boolean
  onStageEdit: (stageId: string) => void
}) => (
  <Box w='100%' >
    {Object.entries(form.stages).map(([stageId, stage], index) => (
      <StagePreview
        index={index}
        form={form}
        id={stageId}
        readOnly={readOnly}
        onEditClick={() => onStageEdit(stageId)}
        key={stageId}
        stage={stage}
        data={data}
      />
    ))}
  </Box>
)
