import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Flex,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  basePriorCSectionDateField,
  CheckboxField,
  colors,
  dobField,
  Field,
  FieldTypes,
  getCorrectedValue,
  getDateString,
  getMostRecentAssessment,
  hasPriorCSectionField,
  optIntoTextField,
  patientLmpField,
  phoneField,
  seenAnotherProviderField, UserInvite,
  voicemailOkField,
} from '@hb/shared'

import React, {
  useCallback, useContext, useMemo, useState,
} from 'react'

import { deleteField } from 'firebase/firestore'
import { AppContext } from '../../../contexts/AppContext'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { ScreenContext } from '../../../contexts/ScreenContext'
import { UserContext } from '../../../contexts/UserContext'
import { useCreateAssessment } from '../../../hooks/backend/assessments'
import { manuallySetEmailVerified } from '../../../hooks/backend/user/userUtils'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { ActionLog } from '../../ActionLog'
import { SolidActionButton } from '../../Buttons'
import { Editable, EditableRow } from '../../forms'
import { NoteForm } from '../../forms/NoteForm'
import { Subheader } from '../Text'
import { UserBadge } from '../UserBadge'
import { AddAssessmentAlert } from './Assessments/AddAssessmentAlert'
import { PatientConsentForms } from './ConsentForms/PatientConsentForms'
import { DraftView } from './DraftView'

const emailField: Field = {
  type: FieldTypes.EMAIL,
  placeholder: 'Email',
  optional: true,
}

const InviteSummary = ({ invite }: { invite: UserInvite }) => {
  const invitedOnText = useMemo(
    () => `INVITED ${
      invite.createdOn ? ` ${getDateString(invite.createdOn, 'short')}` : ''
    }`,
    [invite],
  )
  return (
    <HStack>
      <Text fontSize="sm" fontWeight="bold" opacity={0.75} color="color">
        {invitedOnText}
      </Text>
      {invite.createdBy ? <UserBadge userId={invite.createdBy} /> : null}
    </HStack>
  )
}

// const JoinedSummary = ({ user }: { user: User | null }) => {
//   const joinedOnText = useMemo(
//     () => `Joined${user?.createdOn ? ` on ${getDateString(user.createdOn)}` : ''}`,
//     [user],
//   )
//   return (
//     <Text fontSize="sm" fontFamily="Open Sans">
//       {joinedOnText}
//     </Text>
//   )
// }

const checkboxVoicemailOkField: CheckboxField = {
  ...voicemailOkField,
  type: FieldTypes.CHECKBOX,
}
const checkboxOptIntoTextField: CheckboxField = {
  ...optIntoTextField,
  type: FieldTypes.CHECKBOX,
}

const PatientEmailVerificationView = () => {
  const { user, emailVerification, patientRef } = useContext(UserContext)
  const { emailVerified } = user || {}
  const { refetch } = emailVerification || {}
  const [manuallyVerifying, setManuallyVerifying] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const handleManuallyVerifyEmail = useCallback(async () => {
    if (!patientRef) return processResponse({ error: 'No patient ref' })
    setManuallyVerifying(true)
    try {
      await manuallySetEmailVerified(patientRef.id)
      await refetch()
      setManuallyVerifying(false)

      return { success: 'Email set as verified' }
    } catch (err: any) {
      setManuallyVerifying(false)
      return processResponse({
        error: err?.message || 'Error manually verifying email',
      })
    }
  }, [patientRef, processResponse, refetch])
  return (
    <HStack align="center">
      {emailVerified ? (
        <ActionLog
          style={{ width: 'auto', padding: 0 }}
          action="Manually verified"
          on={emailVerified.on}
          by={emailVerified.by}
        />
      ) : null}
      {emailVerification?.loading ? (
        <CircularProgress isIndeterminate size={4} />
      ) : (
        <Checkbox colorScheme="green" isChecked={emailVerification?.verified}>
          {emailVerification?.verified ? 'Verified' : 'Not verified'}
        </Checkbox>
      )}
      {emailVerification?.loading || emailVerification?.verified ? null : (
        <Button
          size="xs"
          isLoading={manuallyVerifying}
          onClick={handleManuallyVerifyEmail}
          bg={colors.green.hex}
          color="white"
        >
          Manually Verify Email
        </Button>
      )}
    </HStack>
  )
}

const PatientPregnancySummary = () => {
  const { selectedAssessment } = useContext(UserContext)

  const { data, corrections, ref } = selectedAssessment || {}

  const hasPriorCSection = useMemo(
    () => getCorrectedValue(selectedAssessment, 'delivery.hasPriorCSection'),
    [selectedAssessment],
  )

  const update = useUpdateDoc('pregnancy')

  return (
    <VStack
      spacing={0}
      px={2}
      py={3}
      boxShadow="1px 1px 3px #00000077"
      w="100%"
      bg="white"
      align="flex-start"
      borderRadius={6}
    >
      <Subheader px={3}>Pregnancy</Subheader>
      <EditableRow
        label="LMP"
        small
        field={patientLmpField}
        value={data?.delivery?.lmp}
        correction={corrections?.delivery?.lmp}
        onSubmit={async (v) => update(ref, 'corrections.delivery.lmp', v)}
      />
      <EditableRow
        label="Has seen another provider?"
        small
        field={seenAnotherProviderField}
        value={data?.delivery?.seenAnotherProvider}
        correction={corrections?.delivery?.seenAnotherProvider}
        onSubmit={async (v) => update(ref, 'corrections.delivery.seenAnotherProvider', v)
        }
      />
      <EditableRow
        label="Has prior C-section?"
        small
        field={hasPriorCSectionField}
        value={data?.delivery?.hasPriorCSection}
        correction={corrections?.delivery?.hasPriorCSection}
        onSubmit={async (v) => update(ref, 'corrections.delivery.hasPriorCSection', v)
        }
      />
      {hasPriorCSection ? (
        <EditableRow
          label="Prior C-section date"
          small
          field={basePriorCSectionDateField}
          value={data?.delivery?.priorCSectionDate}
          correction={corrections?.delivery?.priorCSectionDate}
          onSubmit={async (v) => update(ref, 'corrections.delivery.priorCSectionDate', v)
          }
        />
      ) : null}
    </VStack>
  )
}

export const PatientSummary: React.FC = () => {
  const {
    user,
    adminPatientRef,
    patientRef,
    practicePatientRef,
    selectedAssessment,
    assessments,
  } = useContext(UserContext)
  const {
    email,
    patientNote,
    isInvite,
    phone,
    dob,
    voicemailOk,
    textOk,
    drafts,
  } = user || {}

  const { appName } = useContext(AppContext)
  const displayedAssessment = useMemo(
    () => selectedAssessment || getMostRecentAssessment(assessments || {}),
    [selectedAssessment, assessments],
  )
  const { signOnData, signOnCorrections } = displayedAssessment || {}
  const patientAddress = useMemo(() => {
    if (!signOnData?.address && !signOnCorrections?.address) return 'None'
    const {
      streetAddress, streetAddress2, city, state, zip,
    } = {
      ...signOnData?.address,
      ...signOnCorrections?.address,
    } as Record<string, string>
    let res = `${streetAddress || ''}\n`
    if (streetAddress2) res += `${streetAddress2}\n`
    res += `${city || 'No city'}, ${state || 'No state'}\n`
    res += zip || 'No ZIP'
    return res
  }, [signOnCorrections, signOnData])
  const update = useUpdateDoc('patient')

  const {
    addLoading,
    confirmingNewAssessment,
    createAssessment,
    setConfirmingNewAssessment,
  } = useCreateAssessment()

  const { isMobile } = useContext(ScreenContext)

  return (
    <VStack spacing={2} px={4} width="100%">
      <Flex
        px={3}
        flexFlow="column"
        borderRadius={6}
        pt={1}
        w="100%"
        boxShadow="1px 1px 3px #00000077"
        bg="white"
      >
        <HStack>
          <Subheader py={1}>Patient Info</Subheader>
          <SolidActionButton
            onClick={() => setConfirmingNewAssessment(true)}
            isLoading={addLoading}
            bg={colors.green.hex}
            color="white"
            size="xs"
            ml="auto"
          >
            + NEW PREGNANCY
          </SolidActionButton>
        </HStack>
        <VStack
          divider={<StackDivider borderColor="blackAlpha.300" />}
          spacing={1}
          align="flex-start"
          w="100%"
          py={2}
          px={4}
          bg={`${colors.pink.hex}66`}
          boxShadow="inset 0 0 4px #333"
        >
          {isInvite ? (
            <InviteSummary invite={user as UserInvite} />
          ) // <JoinedSummary user={user} />
            : null}
          <VStack pl={1} spacing={0} w="100%" align="flex-start">
            <Stack spacing={isMobile ? 0 : 2} direction={isMobile ? 'column' : 'row'} w="100%">
              <HStack flex={1}>
                <Text fontWeight={600} color="gray.600" fontSize="sm">
                  Email:
                </Text>
                {isInvite ? (
                  <Editable
                    field={emailField}
                    value={email}
                    onSubmit={async (v) => update(patientRef, '', { email: v || deleteField() })
                    }
                  />
                ) : (
                  <Text ml={1} fontSize="15px">
                    {email}
                  </Text>
                )}
              </HStack>
              {isInvite ? null : <PatientEmailVerificationView />}
            </Stack>
          </VStack>
          <VStack spacing={0} w="100%" align="flex-start">
            <Box w="220px">
              <EditableRow
                label="Phone"
                stackProps={{ p: 0 }}
                field={phoneField}
                value={phone}
                onSubmit={async (v) => update(patientRef, '', { phone: v })}
              />
            </Box>
            <Editable
              field={checkboxVoicemailOkField}
              value={voicemailOk}
              onSubmit={async (v) => update(patientRef, '', { voicemailOk: v })}
            />
            <Editable
              field={checkboxOptIntoTextField}
              value={textOk}
              onSubmit={async (v) => update(patientRef, '', { textOk: v })}
            />
          </VStack>
          <HStack pl={1} spacing={2} w="100%" align="center">
            <Text
              lineHeight={1}
              fontWeight={600}
              color="gray.600"
              fontSize="sm"
            >
              Date of Birth:
            </Text>
            <Editable
              style={{ background: 'transparent', padding: 0 }}
              dataCellProps={{ style: { padding: '0.2rem 0' }, fontSize: 'sm' }}
              field={dobField}
              value={dob}
              onSubmit={async (v) => update(patientRef, '', { dob: v })}
            />
          </HStack>
          {appName === 'app' ? (
            <VStack pl={1} spacing={0} w="100%" align="flex-start">
              <Text fontWeight={600} color="gray.600" fontSize="sm">
                Address (from sign-on info):
              </Text>
              <Text ml={1} fontSize="15px" whiteSpace="pre-wrap">
                {patientAddress}
              </Text>
            </VStack>
          ) : null}
        </VStack>
        <NoteForm
          note={patientNote}
          boxProps={{ background: 'transparent', boxShadow: 'none' }}
          placeholder="Patient notes"
          onSubmit={(data) => update(
            appName === 'app' ? adminPatientRef : practicePatientRef,
            'patientNote',
            data,
          )
          }
        />
      </Flex>
      {selectedAssessment ? <PatientPregnancySummary /> : null}
      {user?.id ? (
        <DraftView
          templateKey="consentForm"
          draft={drafts?.consentFormTemplates}
          name="Consent forms"
        />
      ) : null}
      {user?.id ? <PatientConsentForms adminView patientId={user.id} /> : null}
      <AddAssessmentAlert
        onConfirm={createAssessment}
        isOpen={confirmingNewAssessment}
        onCancel={() => setConfirmingNewAssessment(false)}
      />
    </VStack>
  )
}
