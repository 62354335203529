import {
  ASSESSMENTS,
  FieldMapValue,
  OnUploadProgress,
  PaymentReceived,
  UpdateCallback,
  UploadProgress,
} from '@hb/shared'
import React, { useCallback, useContext, useMemo } from 'react'
import { processFieldMapData } from '../../../../../backend'
import { useApp } from '../../../../../contexts'
import { UserContext } from '../../../../../contexts/UserContext'
import { GenericEditModal } from '../../../../DataView/GenericEditModal'
import { getReceivedPatientPaymentFields, validatePaymentReceived } from '../fields'
import { usePaymentsContext } from '../PaymentsProvider'

interface PaymentReceivedModalProps {
  id?: string
  onSubmit: (
    createdOn: number,
    data: PaymentReceived
  ) => Promise<UpdateCallback>
  onClose: () => void
}

export const ReceivedPatientPaymentModal: React.FC<
  PaymentReceivedModalProps
> = ({ onSubmit, id, ...props }) => {
  const { assessmentId } = useContext(UserContext)
  const { payments } = usePaymentsContext()
  const received = payments?.received
  const initData = useMemo(
    () => (id && received?.[id] ? received[id] : { date: Date.now() }),
    [received, id],
  )
  const { appName } = useApp()
  const field = useMemo(
    () => (id
      ? getReceivedPatientPaymentFields(appName, id)
      : { name: 'Select a Payment', children: {} }),
    [id, appName],
  )
  const handleSubmit = useCallback(
    async (data: FieldMapValue, onUploadProgress: OnUploadProgress): Promise<UpdateCallback> => {
      if (!id) return { error: 'No ID' }
      const validationErrors = validatePaymentReceived(data)
      if (validationErrors) return validationErrors
      const {
        amount, paidBy, date, source, eobFile, checkFile, notes, type,
      } = data
      const payment: PaymentReceived = {
        paidBy,
        amount,
        date,
        source,
        type,
      }
      if (eobFile) payment.eobFile = eobFile
      if (checkFile) payment.checkFile = checkFile
      if (notes) payment.notes = notes
      const createdOn = parseInt(id, 10)
      if (Number.isNaN(createdOn)) {
        return { error: 'Invalid ID' }
      }
      const handleUploadProgress = (progress: Record<string, UploadProgress>) => {
        onUploadProgress(progress)
      }
      const processed = await processFieldMapData(`${ASSESSMENTS}/${assessmentId}/payments/${id}`, field, data, initData, handleUploadProgress)
      return onSubmit(createdOn, processed as PaymentReceived)
    },
    [id, onSubmit, assessmentId, field, initData],
  )

  return (
    <GenericEditModal
      {...props}
      isOpen={!!id}
      data={initData}
      onSubmit={handleSubmit}
      field={field}
    />
  )
}
