import {
  Button, Flex,
  FlexProps,
  Text,
} from '@chakra-ui/react'
import {
  Assessment, InsuranceCoverage,
  InsuranceCoverageRequest,
  isInsuranceCoverageRequest, PopulatedAssessmentInterface, WithId,
} from '@hb/shared/types'
import {
  objectToArray,
} from '@hb/shared/utils'
import React, {
  useCallback, useMemo, useState,
} from 'react'
import { CoverageRequestView } from './Coverage/CoverageRequestView'
import { CoverageStage } from './Coverage/CoverageStage'
import { NewPlanModal } from './Coverage/NewPlanModal'

const NewPlanButton = ({ onClick }: { onClick: () => void }) => (
  <Flex w="100%" position="relative">
    <Button onClick={onClick} py={1} variant="link" w="100%" size="sm">
      + New Plan
    </Button>
  </Flex>
)

export const AdditionalPlansView = ({
  assessment,
  assessmentId,
  noHeader,
  openPlanId,
  onOpenPlan,
  adminView,
  ...flexProps
}: FlexProps & {
  assessment: Assessment | PopulatedAssessmentInterface
  assessmentId: string
  noHeader?: boolean
  onOpenPlan?: (id: string | null) => void
  openPlanId?: string | null
  adminView?: boolean
}) => {
  const { additionalPlans = {} } = assessment

  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)
  const planId = useMemo(
    () => (openPlanId === undefined ? selectedPlanId : openPlanId),
    [openPlanId, selectedPlanId],
  )
  const onOpen = useCallback(
    (id: string | null) => {
      const openFunc = onOpenPlan === undefined ? setSelectedPlanId : onOpenPlan
      openFunc(openPlanId === id ? null : id)
    },
    [openPlanId, onOpenPlan],
  )
  const [newPlanOpen, setNewPlanOpen] = useState(false)

  const sortedAdditionalPlans = useMemo<
    WithId<InsuranceCoverage | InsuranceCoverageRequest>[]
  >(() => objectToArray(additionalPlans || {}), [additionalPlans])

  return (
    <>
      <Flex gap={2} flexFlow="column" px={3} py={1} {...flexProps}>
        {!adminView && noHeader ? null : (
          <Flex w="100%" align="center">
            {noHeader ? null : (
              <Text
                fontSize="lg"
                lineHeight={1}
                fontWeight={600}
                color="gray.600"
              >
                Additional Plans
              </Text>
            )}
          </Flex>
        )}
        {sortedAdditionalPlans.length ? (
          sortedAdditionalPlans.map((plan) => (isInsuranceCoverageRequest(plan) ? (
              <CoverageRequestView
                assessment={assessment}
                assessmentId={assessmentId}
                adminView={adminView}
                key={plan.id}
                request={plan}
              />
          ) : (
              <CoverageStage
                key={plan.id}
                type="additionalPlans"
                assessmentId={assessmentId}
                coverage={plan}
                isOpen={planId === plan.id}
                onOpenToggle={() => onOpen(planId === plan.id ? null : plan.id)}
                propPath={`additionalPlans.${plan.id}`}
                adminView={adminView}
              />
          )))
        ) : (
          <Text
            px={3}
            py={1}
            bg="gray.50"
            fontStyle="italic"
            border="1px solid #cdcdcd"
            borderRadius={6}
          >
            No additional plans
          </Text>
        )}
        {!adminView ? null : (
          <NewPlanButton onClick={() => setNewPlanOpen(true)} />
        )}
      </Flex>
      {adminView && newPlanOpen ? (
        <NewPlanModal onClose={() => setNewPlanOpen(false)} />
      ) : null}
    </>
  )
}
