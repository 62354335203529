import { FieldTypes } from '@hb/shared/constants'
import {
  AppName,
  DropdownOptionItem, FieldMap, FieldMapValue, PaymentReceived,
} from '@hb/shared/types'
import { createFileField, createNumberField } from '@hb/shared/utils'
import { ValidationErrors } from 'final-form'

export const paidByOptions: DropdownOptionItem[] = [
  {
    text: 'Patient',
    id: 'patient',
  },
  {
    text: 'Insurance',
    id: 'insurance',
  },
]

export const paymentScheduleOptions: DropdownOptionItem[] = [
  {
    text: 'Retainer',
    id: 'retainer',
  },
  {
    text: 'Deposit',
    id: 'deposit',
  },
  {
    text: 'Other (Specify in notes)',
    id: 'other',
  },
]

export const paymentSourceOptions: DropdownOptionItem[] = [
  {
    text: 'ACH',
    id: 'ach',
  },
  {
    text: 'Check',
    id: 'check',
  },
  {
    text: 'Other (Specify in notes)',
    id: 'other',
  },
]

export const validatePaymentReceived = (v?: FieldMapValue): ValidationErrors => {
  if (!(v && v.paidBy)) return { paidBy: 'Required' }
  if (v.paidBy === 'patient') {
    if (!v.type) return { schedule: 'Required' }
    if (v.schedule === 'deposit' && v.depositNumber === undefined) {
      return { depositNumber: 'Required' }
    }
    if (!v.amount) return { amount: 'Required' }
    if (!v.date) return { date: 'Required' }
    if (!v.source) return { source: 'Required' }
    return undefined
  }
  if (!v.amount) return { amount: 'Required' }
  if (!v.date) return { date: 'Required' }
  // if (!v.checkFile && !v.eobFile) {
  //   return {
  //     checkFile: 'Check or EOB required',
  //     eobFile: 'Check or EOB required',
  //   }
  // }
  if (!v.source) return { source: 'Required' }
  return undefined
}

export const getCheckFileField = (paymentId: string) => createFileField({
  condition: (v) => v?.paidBy === 'insurance',
  placeholder: 'Check File (Image/PDF)',
  name: `check_${paymentId}`,
})

export const getEobFileField = (paymentId: string) => createFileField({
  condition: (v) => v?.paidBy === 'insurance',
  placeholder: 'Explanation of Benefits',
  name: `eob_${paymentId}`,
})

export const getReceivedPatientPaymentFields = (
  appName: AppName,
  paymentId: string,
): FieldMap => {
  const children: FieldMap['children'] = {
    paidBy: {
      placeholder: 'Paid By',
      type: FieldTypes.DROPDOWN,
      selectInput: true,
      options: paidByOptions,
    },
    type: {
      condition: (v) => v?.paidBy && v.paidBy === 'patient',
      placeholder: 'Payment Type',
      type: FieldTypes.DROPDOWN,
      selectInput: true,
      options: [
        { id: 'deposit', text: 'Deposit' },
        { id: 'home-birth-retainer', text: 'Birth assistant retainer' },
        { id: 'retainer', text: 'Retainer' },
        { id: 'other', text: 'Other' },
      ] as Array<{ id: PaymentReceived['type']; text: string }>,
    },
    depositNumber: createNumberField({
      condition: (v) => v?.paidBy && v.paidBy === 'patient' && v?.schedule === 'deposit',
      defaultValue: 1,
      min: 1,
      placeholder: 'Deposit #',
    }),
    amount: {
      condition: (v) => v?.paidBy,
      placeholder: 'Amount Received',
      min: 0,
      type: FieldTypes.DOLLAR_AMOUNT,
    },
    date: {
      condition: (v) => v?.paidBy,
      nativeInput: true,
      placeholder: 'Payment Date',
      type: FieldTypes.DATE,
    },
    checkFile: getCheckFileField(paymentId),
    eobFile: getEobFileField(paymentId),
    source: {
      condition: (v) => !!v?.paidBy,
      placeholder: 'Payment Source',
      selectInput: true,
      type: FieldTypes.DROPDOWN,
      options: paymentSourceOptions,
    },
    notes: {
      condition: (v) => v?.paidBy,
      placeholder: 'Notes...',
      type: FieldTypes.TEXTAREA,
    },
  }
  return ({
    name: 'Received Payment',
    children: appName === 'providers-app' ? {
      ...children,
      sendMessageToHb: {
        type: FieldTypes.CHECKBOX,
        placeholder: 'Send message to HB admins',
        condition: (v) => !!v?.paidBy,
      },
      messageToHb: {
        placeholder: 'Message to HB admins',
        type: FieldTypes.TEXTAREA,
        optional: true,
        condition: (v) => v?.sendMessageToHb,
      },
    } : children,
    validate: validatePaymentReceived,
  })
}
